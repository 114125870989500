<lefty-form
  [required]="required"
  [errorMessage]="errorMessage"
  [label]="label"
>
  <button
    type="button"
    [ngClass]="buttonClass"
    [disabled]="disabled"
    class="selector-btn"
    (focus)="handleFocus($event)"
    (blur)="handleBlur($event)"
    #popupTrigger
  >
    <ng-container *ngIf="hasRange === true">
      {{ readableRange }}
    </ng-container>

    <ng-container *ngIf="hasRange === false">
      {{ placeholder }}
    </ng-container>

    <lefty-icon
      icon="arrow_drop_down"
      (click)="onArrowIconClick($event)"
    ></lefty-icon>
  </button>

  <lefty-popup
    [matchMinSourceWidth]="false"
    [autoDismissNodeValidator]="autoDismissNodeValidator"
    [placement]="popupPlacement"
    [popupTrigger]="popupTrigger"
    [visible]="popupVisible()"
    (visibleChange)="popupVisible.set($event)"
  >
    <div class="calendar-container">
      <div class="calendar-body">
        <div
          class="predefined-dates"
          *ngIf="presets.length !== 0"
        >
          <lefty-list>
            <lefty-list-item
              *ngFor="let preset of presets"
              class="selectable-item"
              [class.selected]="isPresetSelected(preset)"
              (click)="selectPreset(preset)"
              [disabled]="isPresetDisabled(preset)"
            >
              {{ preset.label }}
            </lefty-list-item>
          </lefty-list>
        </div>
        <div
          class="lefty"
          #calendar
        ></div>
      </div>

      <div
        class="clear-footer"
        *ngIf="clearable && hasRange"
      >
        <button
          type="button"
          class="plain dark large"
          (click)="clear()"
        >
          {{ Messages.clear }}
        </button>
      </div>
    </div>
  </lefty-popup>
</lefty-form>
