import { Injectable, inject } from '@angular/core';
import {
  CWGetSetResponse,
  CWReportDetails,
} from '@frontend2/proto/librarian/proto/admin_cw_pb';
import { TeamDetails } from '@frontend2/proto/librarian/proto/admin_pb';
import { ProfileMergeRequestPb } from '@frontend2/proto/librarian/proto/profile_merge_requests_pb';
import { LeftyEvents, LeftyEventsBus } from '@frontend2/ui';

type FlandersEvents = LeftyEvents & {
  cw_set_created: CWGetSetResponse;
  cw_set_updated: CWGetSetResponse;
  set_archived: CWGetSetResponse;
  report_created_or_updated: CWReportDetails;
  impersonators_updated: TeamDetails;
  updated_profile_merge_request_status: ProfileMergeRequestPb;
};

@Injectable({ providedIn: 'root' })
export class FlandersEventsBus extends LeftyEventsBus<FlandersEvents> {}

export function injectFlandersEventsBus(): FlandersEventsBus {
  return inject(FlandersEventsBus);
}
