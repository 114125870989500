import { NgIf } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ComponentRef,
  computed,
  HostListener,
  input,
  Input,
  output,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { isNil, isNotEmptyString, SortBy } from '@frontend2/core';
import { SortDirection } from '@frontend2/proto/common/proto/common_pb';
import { HelpIconComponent } from '../icon/help.component';
import { LeftyIconComponent } from '../icon/icon.component';
import { LeftyTooltipDirective } from '../lefty-tooltip/lefty-tooltip.directive';

@Component({
  selector: 'sort-item',
  templateUrl: './sort-item.component.html',
  styleUrls: ['./sort-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, HelpIconComponent, LeftyTooltipDirective, LeftyIconComponent],
})
export class SortItemComponent implements AfterViewInit {
  @Input()
  label = '';

  @Input()
  active = false;

  @Input()
  disabled = false;

  @Input()
  ascDir = false;

  @Input()
  help = '';

  @Input() labelFactory?: () => ComponentRef<unknown>;

  @ViewChild('dynamicContent', { read: ViewContainerRef, static: false })
  dynamicContent: ViewContainerRef | undefined;

  ngAfterViewInit(): void {
    if (this.labelFactory) {
      const componentRef = this.labelFactory();
      this.dynamicContent?.clear();
      this.dynamicContent?.insert(componentRef.hostView);
    }
  }

  get hasHelp(): boolean {
    return isNotEmptyString(this.help);
  }
}

@Component({
  selector: 'lefty-sort-item',
  templateUrl: './lefty-sort-item.component.html',
  styleUrls: ['./sort-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, HelpIconComponent, LeftyTooltipDirective, LeftyIconComponent],
})
export class LeftySortItemComponent<T> {
  readonly active = input<SortBy<T> | undefined>();
  readonly sort = input.required<T | undefined>();
  readonly disabled = input(false);
  readonly help = input('');

  readonly sort$ = output<SortBy<T>>();

  readonly canSort = computed(() => !this.disabled() && !isNil(this.sort()));
  readonly hasHelp = computed(() => isNotEmptyString(this.help()));

  readonly activeSortValue = computed(() => this.active()?.value);
  readonly isActive = computed(() => {
    return this.sort() === this.activeSortValue();
  });

  readonly direction = computed(() => this.active()?.direction);
  readonly isAscDir = computed(() => this.direction() === SortDirection.ASC);

  @HostListener('click')
  toggleSort(): void {
    const sort = this.sort();
    if (this.disabled() || isNil(sort)) {
      return;
    }

    let newSort = SortBy.create<T>(sort);
    if (this.isActive()) {
      if (this.isAscDir()) {
        newSort = { ...newSort, direction: SortDirection.DESC };
      } else {
        newSort = { ...newSort, direction: SortDirection.ASC };
      }
    }

    this.sort$.emit(newSort);
  }
}
