import { Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Empty, JsonObject } from '@bufbuild/protobuf';
import { injectLibrarianCampaignsClient } from '@frontend2/api';
import { CampaignStatus } from '@frontend2/proto/common/proto/campaign_pb';
import { Campaign } from '@frontend2/proto/librarian/proto/campaigns_pb';
import { IframeSyncedCacheBloc } from '../bloc';
import { injectLeftyEventsBus } from '../events/events.service';

@Injectable({
  providedIn: 'root',
})
export class CampaignsCache extends IframeSyncedCacheBloc<
  Map<bigint, Campaign>
> {
  readonly librarianCampaigns = injectLibrarianCampaignsClient();
  readonly leftyEvents = injectLeftyEventsBus();

  constructor() {
    super(new Map());

    this.leftyEvents
      .on('remove_campaign')
      .pipe(takeUntilDestroyed())
      .subscribe((campaign) => {
        return this._removeCampaign(campaign);
      });

    this.leftyEvents
      .on('archive_campaign')
      .pipe(takeUntilDestroyed())
      .subscribe((campaign) => {
        return this._handleArchiveCampaign(campaign);
      });

    this.leftyEvents
      .on('unarchive_campaign')
      .pipe(takeUntilDestroyed())
      .subscribe((campaign) => {
        return this._handleUnarchiveCampaign(campaign);
      });

    this.leftyEvents
      .on('pin_campaign')
      .pipe(takeUntilDestroyed())
      .subscribe((campaign) => {
        return this._handlePinCampaign(campaign);
      });

    this.leftyEvents
      .on('unpin_campaign')
      .pipe(takeUntilDestroyed())
      .subscribe((campaign) => {
        return this._handleUnPinCampaign(campaign);
      });

    this.leftyEvents
      .on('create_campaign')
      .pipe(takeUntilDestroyed())
      .subscribe((campaign) => {
        this._addNewCampaign(campaign);
      });

    this.leftyEvents
      .on('update_campaign')
      .pipe(takeUntilDestroyed())
      .subscribe((camp) => this._updateCampaign(camp));
  }

  override async fetch(): Promise<Map<bigint, Campaign>> {
    const res = await this.librarianCampaigns.getCampaignsAPI(new Empty());

    const map = new Map<bigint, Campaign>();
    for (const obj of res.campaigns) {
      map.set(obj.id, obj);
    }

    return map;
  }

  private _updateCampaign(campaign: Campaign): void {
    if (this.isLoaded() === false) {
      return;
    }

    const newMap = new Map(this.cachedData());
    newMap.set(campaign.id, campaign);

    this.updateCache(newMap);
  }

  private _addNewCampaign(campaign: Campaign): void {
    const newMap = new Map(this.cachedData());
    newMap.set(campaign.id, campaign);

    this.updateCache(newMap);
  }

  private _removeCampaign(campaign: Campaign): void {
    // don't want to update cache if not loaded yet
    if (this.isLoaded() === false) {
      return;
    }

    const newMap = new Map(this.cachedData());
    newMap.delete(campaign.id);

    this.updateCache(newMap);
  }

  private _handleArchiveCampaign(campaign: Campaign): void {
    const newCampaign = new Campaign({
      ...campaign,
      status: CampaignStatus.CAMPAIGN_ARCHIVED,
    });
    this._updateCampaign(newCampaign);
  }

  private _handleUnarchiveCampaign(campaign: Campaign): void {
    const newCampaign = new Campaign({
      ...campaign,
      status: CampaignStatus.CAMPAIGN_ACTIVE,
    });
    this._updateCampaign(newCampaign);
  }

  private _handlePinCampaign(campaign: Campaign): void {
    const newCampaign = new Campaign({
      ...campaign,
      pinned: true,
    });
    this._updateCampaign(newCampaign);
  }

  private _handleUnPinCampaign(campaign: Campaign): void {
    const newCampaign = new Campaign({
      ...campaign,
      pinned: false,
    });
    this._updateCampaign(newCampaign);
  }

  override readonly syncName = 'campaigns';

  override convertToJson(obj: Map<bigint, Campaign>): string {
    const jsonMap: { [key: string]: Campaign } = {};

    // manually convert big int to string, before convert to json
    obj.forEach((val, key) => {
      jsonMap[key.toString()] = val;
    });

    return JSON.stringify(jsonMap);
  }

  override convertFromJson(jsonString: string): Map<bigint, Campaign> {
    const jsonMap = JSON.parse(jsonString) as JsonObject;

    const objsMap = new Map<bigint, Campaign>();
    for (const jsonVal of Object.values(jsonMap)) {
      const campaign = Campaign.fromJson(jsonVal);
      objsMap.set(campaign.id, campaign);
    }

    return objsMap;
  }
}
