/* eslint-disable @typescript-eslint/no-explicit-any */
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { LeftyComponent } from '../utils';
import {
  AfterContentInit,
  ChangeDetectionStrategy,
  Component,
  ContentChildren,
  HostBinding,
  Input,
  NgZone,
  Optional,
  Output,
  Self,
} from '@angular/core';
import { LeftyRadioComponent } from './lefty-radio.component';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { SelectionModel } from '../lefty-form-select/utils';
import { isEqual, isNil, isNotNil } from '@frontend2/core';
import { LeftyRadioGroupService } from './lefty-radio-group.service';

@Component({
  selector: 'lefty-radio-group',
  template: '<ng-content></ng-content>',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: LeftyRadioGroupService,
      useExisting: LeftyRadioGroupComponent,
    },
  ],
  standalone: true,
})
export class LeftyRadioGroupComponent<T>
  extends LeftyComponent
  implements ControlValueAccessor, AfterContentInit, LeftyRadioGroupService<T>
{
  @HostBinding('class')
  readonly hostClass = 'lefty-radio-group';

  @HostBinding('attr.role')
  readonly role = 'radiogroup';

  @HostBinding('attr.tabindex')
  readonly tabIndex = -1;

  constructor(
    private _zone: NgZone,
    @Self() @Optional() private cd?: NgControl,
  ) {
    super();

    // When NgControl is present on the host element, the component
    // participates in the Forms API.
    if (cd) {
      cd.valueAccessor = this;
    }

    this.disposer.add(this.selectedChange);
    this.disposer.add(this.componentSelection$);
    this.disposer.add(this.focusSelection$);
    this.disposer.add(this._valueSelection$);

    this.watch(this.componentSelection$, {
      next: (selectionModel) => {
        for (const radioComponent of this.radioComponents) {
          radioComponent.checked = selectionModel.isSelected(radioComponent);
        }

        // In case this was programmatically selected, thus won't get updated
        // by focus calls.
        this._selected = this._selectedRadioComponent?.value;
        if (isNotNil(this._valueSelection$.value) && isNotNil(this._selected)) {
          this._valueSelection$.next(
            this._valueSelection$.value.select(this._selected),
          );
        }

        this.selectedChange.next(this._selected);
      },
    });
  }

  @ContentChildren(LeftyRadioComponent)
  radioComponents: LeftyRadioComponent<T>[] = [];

  /// Published when selection changes. Prefer `(ngModelChange)`.
  @Output()
  readonly selectedChange = new Subject<T | undefined>();

  readonly _valueSelection$ = new BehaviorSubject<
    SelectionModel<T> | undefined
  >(undefined);

  /// Internal selection model containing the radio component.
  readonly componentSelection$ = new BehaviorSubject(
    SelectionModel.single<LeftyRadioComponent<T>>(),
  );

  /// Internal selection model to keep track of radio currently in focus.
  readonly focusSelection$ = new BehaviorSubject(
    SelectionModel.single<LeftyRadioComponent<T>>(),
  );

  private get _selectedRadioComponent(): LeftyRadioComponent<T> | null {
    if (this.componentSelection$.value.selection.length === 0) {
      return null;
    }
    return this.componentSelection$.value.selection[0];
  }

  // Keep the preselected value until children are loaded.
  private _preselectedValue?: T;
  private _isContentInit = false;

  private _selected?: T;

  get selected(): T | undefined {
    return this._selected;
  }

  /// Value of currently selected radio. Prefer `[ngModel]`.
  @Input()
  set selected(selectedValue: T | undefined) {
    if (this._isContentInit) {
      for (const radioComponent of this.radioComponents) {
        radioComponent.checked = radioComponent.value === selectedValue;
      }
      // Ensure we don't overwrite the value in the initial callback.
      this._preselectedValue = undefined;
    } else {
      this._preselectedValue = selectedValue;
    }

    this.changeDetection.markForCheck();
  }

  private _selectionSubscription?: Subscription;

  /// Selection model containing value object.
  @Input()
  set selectionModel(value: SelectionModel<T>) {
    if (isEqual(this._valueSelection$.value, value)) {
      return;
    }

    this._selectionSubscription?.unsubscribe();
    this._valueSelection$.next(value);
    this._selectionSubscription = this._valueSelection$.subscribe({
      next: (value) => {
        this.selected = value?.isEmpty ? undefined : value?.selection[0];
      },
    });
  }

  writeValue(obj: any): void {
    if (obj) {
      this.selected = obj;
    }
  }

  registerOnChange(callback: any): void {
    this.disposer.add(this.selectedChange.subscribe({ next: callback }));
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  registerOnTouched(fn: any): void {
    // onTouched API is not supported for now.
  }

  private _getFocusableChildren(): LeftyRadioComponent<T>[] {
    // Make sure current focus is in the list even if it's disabled.
    return this.radioComponents.filter(
      (radioComponent) => !radioComponent.disabled,
    );
  }

  ngAfterContentInit(): void {
    this._isContentInit = true;
    if (isNotNil(this._preselectedValue)) {
      this._zone.run(() => {
        if (isNil(this._preselectedValue)) {
          return;
        } // Overridden before callback.
        // Initialize preselect now, this will trigger tabIndex reset.
        this.selected = this._preselectedValue;
        // The preselected value should be used only once.
        this._preselectedValue = undefined;
      });
    }
  }

  select(radio: LeftyRadioComponent<T>): void {
    this.componentSelection$.next(this.componentSelection$.value.select(radio));
  }

  deselect(radio: LeftyRadioComponent<T>): void {
    this.componentSelection$.next(
      this.componentSelection$.value.deselect(radio),
    );
  }
}
