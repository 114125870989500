<lefty-dialog
  [autoDismissable]="true"
  [hideFooter]="true"
  size="small"
  [(visible)]="visible"
  (close$)="close()"
>
  <h3
    title
    i18n
  >
    Add influencers
  </h3>

  <lefty-card
    [withBorder]="true"
    class="methods"
  >
    @for (method of addMethods(); track $index) {
      <div
        class="add-method"
        (click)="selectMethod(method)"
      >
        <button
          leftyButton="primary"
          [icon]="addInfluencerMethodsIcon(method)"
        ></button>
        <p class="fill-space">{{ addInfluencerMethodsToReadable(method) }}</p>
        <button
          leftyButton="ghost"
          icon="arrow_right"
          class="right-arrow"
        ></button>
      </div>
    }
  </lefty-card>
</lefty-dialog>
