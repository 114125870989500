<div
  class="influencer-item"
  [class.disabled]="disabled()"
  [class.ghost]="isGhost()"
  (click)="toggleChecked()"
>
  <lefty-checkbox
    [disabled]="disabled()"
    [(checked)]="checked"
    (click)="checkboxClicked($event)"
  />

  <div class="body-1 username-cell fill-space">
    <profile-picture
      [creator]="influencer().baseSnippet"
      class="thumbnail"
    ></profile-picture>
    <div class="text">{{ username() }}</div>
  </div>

  <div
    class="body-1 networks-cell"
    i18n
  >
    <networks-sliced-icons
      [networks]="networks()"
      [disabled]="disabled()"
      [minimalGap]="true"
      size="small"
    ></networks-sliced-icons>
  </div>
  <div
    class="body-1 followers-cell"
    i18n
  >
    {{ followers() }}
  </div>
</div>
