import {
  ChangeDetectionStrategy,
  Component,
  computed,
  inject,
  signal,
} from '@angular/core';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import {
  LeftyFormat,
  capitalize,
  customerSortToReadable,
  getEnumValues,
  plural,
} from '@frontend2/core';
import {
  CustomerSort,
  GetCustomersRequest,
} from '@frontend2/proto/librarian/proto/admin_pb';
import { SubscriptionType } from '@frontend2/proto/librarian/proto/payments_pb';
import {
  CompaniesCache,
  IntersectionObserverDirective,
  LeftyFormInputComponent,
  LeftyPageComponent,
  LeftySectionComponent,
  LeftySectionHeaderComponent,
  LeftySortSelectComponent,
  RouteBlocComponent,
  SearchAndSelectDropdownComponent,
} from '@frontend2/ui';
import { debounceTime, skip } from 'rxjs';
import { CreateOrEditCustomerDialogComponent } from '../common/components/create-or-edit-customer-dialog/create-or-edit-customer-dialog.component';
import { CustomersListComponent } from './customers-list/customers-list.component';
import { CustomersListBloc } from './customers.bloc';
import { CustomersListState } from './customers.models';

@Component({
  selector: 'flander-customers-route',
  templateUrl: 'customers.route.html',
  styleUrls: ['customers.route.scss'],
  providers: [CustomersListBloc],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    LeftyPageComponent,
    LeftySectionComponent,
    LeftySectionHeaderComponent,
    LeftyFormInputComponent,
    LeftySortSelectComponent,
    SearchAndSelectDropdownComponent,
    CustomersListComponent,
    IntersectionObserverDirective,
    CreateOrEditCustomerDialogComponent,
  ],
})
export class FlandersCustomersRouteComponent extends RouteBlocComponent<
  GetCustomersRequest,
  CustomersListState
> {
  constructor(routeViewBloc: CustomersListBloc) {
    super(routeViewBloc);
    toObservable(this.searchValue)
      .pipe(skip(1), debounceTime(300), takeUntilDestroyed())
      .subscribe((val: string) => this.emailChange(val));

    this.companiesCache.load();
  }

  private readonly companiesCache = inject(CompaniesCache);

  readonly searchValue = signal('');

  readonly subscriptions = getEnumValues<SubscriptionType>(SubscriptionType);
  readonly sortOptions = getEnumValues<CustomerSort>(CustomerSort).filter(
    (s) => s !== CustomerSort.UNDEFINED,
  );
  readonly companyOptions = this.companiesCache.companiesList;

  readonly totalHits = computed(() => this.viewState().totalHits);
  readonly email = computed(() => this.request().email);
  readonly sort = computed(() => this.request().sort);
  readonly companies = computed(() => this.request().companies);
  readonly customers = computed(() => this.viewState().customers);

  emailChange(val: string): void {
    val = val.trim();
    if (val === this.email()) {
      return;
    }
    const newReq = this.request().clone();
    newReq.email = val;
    this.changeRequest(newReq);
  }

  sortChange(val: CustomerSort): void {
    if (val === this.sort()) {
      return;
    }
    const newReq = this.request().clone();
    newReq.sort = val;
    this.changeRequest(newReq);
  }

  companiesChange(val: string[]): void {
    if (val.length === this.companyOptions.length) {
      val = [];
    }
    const newReq = this.request().clone();
    newReq.companies = val;
    this.changeRequest(newReq);
  }

  async refreshView(): Promise<void> {
    await this.routeViewBloc.refresh();
  }

  private changeRequest(request: GetCustomersRequest): void {
    const { queryParameters } = CustomersListBloc.requestToRouteParams(
      request,
      this.state().routeParams,
    );

    this.router.navigate(['.'], {
      relativeTo: this.route,
      queryParams: queryParameters,
    });
  }

  sortRenderer(method: CustomerSort): string {
    return capitalize(customerSortToReadable(method));
  }

  get customersCount(): string {
    return (
      LeftyFormat.profiles(this.totalHits(), { showZero: true }) +
      ' ' +
      plural(this.totalHits(), {
        one: $localize`User`,
        other: $localize`Users`,
      })
    );
  }

  get bloc(): CustomersListBloc {
    return this.routeViewBloc as CustomersListBloc;
  }

  nextPage(): void {
    this.bloc.nextPage();
  }
}
