import { Injectable } from '@angular/core';
import { analytics } from '@frontend2/core';

@Injectable({ providedIn: 'root' })
export class DownloaderService {
  /// programatically trigger a file download using an [AnchorElement]
  downloadLink(link: string, name?: string): void {
    const a = document.createElement('a');
    a.href = link;
    a.download = name ?? '';
    a.target = '_blank';

    document.documentElement.append(a);
    a.click();
    a.remove();

    analytics('download', {
      link,
      name,
    });
  }
}
