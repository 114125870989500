import {
  BasicPagination,
  Network,
} from '@frontend2/proto/common/proto/common_pb';
import {
  TeamDetails,
  TeamUser,
  TeamsResponse_TeamLight,
} from '@frontend2/proto/librarian/proto/admin_pb';
import { SubscriptionType } from '@frontend2/proto/librarian/proto/payments_pb';

export interface WorkspacesListRequest {
  readonly name: string;
  readonly sortBy: WorkspacesSort;
  readonly network: Network[];
  readonly subscription: SubscriptionType[];
  readonly pagination?: BasicPagination;
}

export interface WorskpacesListState {
  readonly teams: TeamsResponse_TeamLight[];
  readonly totalHits: bigint;
}

export interface MemberToRemove {
  workspace: TeamDetails;
  member: TeamUser;
}

export enum WorkspacesSort {
  BY_MEMBERS = 0,
  BY_DATE = 1,
  BY_EMAIL = 2,
}
