import {
  ChangeDetectionStrategy,
  Component,
  Input,
  Optional,
  Self,
} from '@angular/core';
import { NgControl } from '@angular/forms';
import { LeftyFormValueBase } from '../form';
import { ToggleSize, LeftyToggleComponent } from './lefty-toggle.component';
import { LeftyFormComponent } from '../lefty-form/lefty-form.component';

@Component({
  selector: 'lefty-form-toggle',
  templateUrl: 'lefty-form-toggle.component.html',
  styleUrls: ['lefty-form-toggle.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [LeftyFormComponent, LeftyToggleComponent],
})
export class LeftyFormToggleComponent extends LeftyFormValueBase<boolean> {
  constructor(@Self() @Optional() ngControl?: NgControl) {
    super(false, ngControl);
  }

  @Input()
  size: ToggleSize = 'medium';

  override writeValue(obj: unknown): void {
    if (typeof obj === 'boolean') {
      this.value = obj;
    } else {
      this.value = false;
    }
  }
}
