<lefty-dialog
  (dismiss$)="close()"
  [(visible)]="visible"
  size="small"
>
  <h3 title>
    {{ title }}
  </h3>
  <form
    [formGroup]="formModel"
    class="grid"
  >
    <div class="col-12">
      <lefty-form-input
        formControlName="name"
        label="Name"
      >
      </lefty-form-input>
    </div>
    <div class="col-12">
      <lefty-form-textarea
        formControlName="description"
        label="Description"
      >
      </lefty-form-textarea>
    </div>
  </form>
  <div footer>
    <button
      type="button"
      (click)="close()"
    >
      {{ Messages.cancel }}
    </button>
    <button
      type="button"
      class="primary"
      (click)="submit()"
      [disabled]="loading || formModel.invalid"
    >
      <lefty-spinner
        class="full-width center"
        *ngIf="loading"
      ></lefty-spinner>
      {{ Messages.save }}
    </button>
  </div>
</lefty-dialog>
