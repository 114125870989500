<profile-picture
  *ngIf="!isGhostCreator(creator)"
  [creator]="creator"
>
</profile-picture>
<div class="text-container">
  <span
    class="search"
    *ngIf="isGhostCreator(creator)"
    >{{ Messages.search.toLowerCase() }} :</span
  >
  <span class="username">{{ creator.userName }}</span>
</div>
