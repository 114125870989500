import { NgIf } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
} from '@angular/core';
import {
  endOfDay,
  isAfterDate,
  isBeforeDate,
  isNil,
  isNotEmptyString,
  isNotNil,
  isSameDay,
  startOfDay,
} from '@frontend2/core';
import { LeftyControlValueAccessor } from '../../form';
import { LeftyDatePickerComponent } from '../lefty-date-picker.component';
import { LeftyTimePickerComponent } from '../lefty-time-picker/lefty-time-picker.component';
import {
  createTimeValueSelection,
  TimeValueSelection,
} from '../lefty-time-picker/time-picker.helpers';

@Component({
  selector: 'lefty-date-time-picker',
  templateUrl: 'lefty-date-time-picker.component.html',
  styleUrls: ['lefty-date-time-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, LeftyDatePickerComponent, LeftyTimePickerComponent],
})
export class LeftyDateTimePickerComponent extends LeftyControlValueAccessor<
  Date | undefined
> {
  readonly maxDate = input<Date>();
  readonly minDate = input<Date>();

  readonly minTime = computed(() => {
    const minDate = this.minDate();
    const currentVal = this.value();

    if (
      isNil(currentVal) ||
      isNil(minDate) ||
      isAfterDate(currentVal, endOfDay(minDate))
    ) {
      return;
    }
    return createTimeValueSelection({
      hour: minDate?.getHours(),
      minute: minDate?.getHours(),
    });
  });

  readonly maxTime = computed(() => {
    const maxDate = this.maxDate();
    const currentVal = this.value();

    if (
      isNil(currentVal) ||
      isNil(maxDate) ||
      isBeforeDate(currentVal, startOfDay(maxDate))
    ) {
      return;
    }
    return createTimeValueSelection({
      hour: maxDate?.getHours(),
      minute: maxDate?.getHours(),
    });
  });

  readonly compactGap = input(false);

  readonly timeStep = input(60);

  readonly showLabelContainer = computed(() => isNotEmptyString(this.label()));

  readonly timeValue = computed(() => {
    const val = this.value();
    if (isNil(val)) {
      return undefined;
    }
    const currentHour = val.getHours();
    const currentMinute = val.getMinutes();

    return createTimeValueSelection({
      hour: currentHour,
      minute: currentMinute,
    });
  });

  dateChange(date: Date | undefined): void {
    if (isNil(date)) {
      this.setValueAndNotify(undefined);
      return;
    }
    const defaultTime = this.calculateDefaultTimeWhenSetDate(date);
    const newVal = new Date(date);
    newVal.setHours(defaultTime.hour, defaultTime.minute, 0);
    this.setValueAndNotify(newVal);
  }

  calculateDefaultTimeWhenSetDate(date: Date): TimeValueSelection {
    const minDate = this.minDate();
    const currentTime = this.timeValue();

    if (isNotNil(minDate) && isSameDay(date, minDate)) {
      const hour = Math.max(minDate.getHours(), currentTime?.hour ?? 0);
      const minute =
        hour === minDate.getHours()
          ? Math.max(minDate.getMinutes(), currentTime?.minute ?? 0)
          : (currentTime?.minute ?? 0);
      return createTimeValueSelection({
        hour: hour,
        minute: minute,
      });
    }

    return createTimeValueSelection({
      hour: currentTime?.hour ?? 0,
      minute: currentTime?.minute ?? 0,
    });
  }

  timeChange(time: TimeValueSelection | undefined): void {
    const currentDate = this.value() ?? new Date();
    const newDate = new Date(currentDate);
    if (isNil(time)) {
      newDate.setHours(0, 0, 0);
      return;
    } else {
      newDate.setHours(time.hour, time.minute, 0);
    }

    this.setValueAndNotify(newDate);
  }

  override isValidType(obj: unknown): obj is Date | undefined {
    return isNil(obj) || obj instanceof Date;
  }
}
