<div
  class="table-container"
  #tableContainer
>
  <table #table>
    <thead>
      <div
        class="header-background"
        [style.height.px]="trEl.clientHeight"
        [style.top.px]="topPosition"
        [class.visible]="headerFixed"
        *ngIf="!horizontalScroll"
      ></div>
      <tr
        [class.selected]="massSelectState.allSelected"
        #trEl
      >
        <th
          *ngIf="withCounter"
          class="column-counter"
          [style.top.px]="topPosition"
        >
          <div class="header-cell">
            <div
              class="checkbox-container"
              *ngIf="withSelection"
            >
              <lefty-checkbox
                class="no-label no-margin"
                [checked]="hasAllSelected"
                [indeterminate]="allSelectedIndeterminated"
                (click)="toggleAllSelection()"
              ></lefty-checkbox>
            </div>
            #
          </div>
        </th>
        <th
          *ngFor="let col of visibleColumns; trackBy: trackByCol; let i = index"
          [ngClass]="colClass(col)"
          [class.align-right]="col.alignRight"
          [class.has-sort]="isSortable(col)"
          [style.top.px]="topPosition"
          #th
        >
          <div class="header-cell">
            <div
              class="checkbox-container"
              [class.with-counter]="withCounter && horizontalScroll"
              *ngIf="withSelection && i === 0 && withCounter === false"
            >
              <lefty-checkbox
                class="no-label no-margin"
                [checked]="hasAllSelected"
                [indeterminate]="allSelectedIndeterminated"
                (click)="toggleAllSelection()"
              ></lefty-checkbox>
            </div>
            <sort-item
              [label]="col.label"
              [active]="isSortActive(col.sortValue)"
              [ascDir]="isAscDir"
              [disabled]="!isSortable(col)"
              (click)="sortBy(col)"
              [help]="col.helper"
              [labelFactory]="col.labelFactory"
              [attr.data-cy]="testSortSelector(col.sortValue)"
            ></sort-item>
          </div>

          <!--
            Not(hadrien) Little hack here
            if horizontalScroll is true, .header-cell class will have position absolute

            We need to keep copy of header-cell which does not have
            position absolute to no break table col width.

            The following element is invisible
          -->
          <div
            class="header-cell-width"
            [class.has-sort]="isSortable(col)"
          >
            <sort-item
              [label]="col.label"
              [active]="isSortActive(col.sortValue)"
              [ascDir]="isAscDir"
              [disabled]="!isSortable(col)"
              [help]="col.helper"
              (click)="sortBy(col)"
            ></sort-item>
          </div>
        </th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngIf="!rowLinkBuilder">
        <tr
          *ngFor="let row of rows; trackBy: trackByRow; let rowIndex = index"
          [ngClass]="rowClass(row)"
          (click)="clickOnRow($event, row)"
          [class.active]="isRowActive(row)"
          [class.selected]="isSelected(row.data)"
          [class.ghost]="isRowGhost(row)"
        >
          <ng-template
            [ngTemplateOutlet]="rowTemplate"
            [ngTemplateOutletContext]="getTemplateRowContext(row, rowIndex)"
          >
          </ng-template>
        </tr>
      </ng-container>

      <ng-container *ngIf="rowLinkBuilder">
        <a
          class="tr"
          [routerLink]="buildLink(row)"
          [attr.target]="rowLinkTarget"
          *ngFor="let row of rows; trackBy: trackByRow; let rowIndex = index"
          [ngClass]="rowClass(row)"
          (click)="clickOnRow($event, row)"
          [class.active]="isRowActive(row)"
          [class.selected]="isSelected(row.data)"
          [class.ghost]="isRowGhost(row)"
        >
          <ng-template
            [ngTemplateOutlet]="rowTemplate"
            [ngTemplateOutletContext]="getTemplateRowContext(row, rowIndex)"
          >
          </ng-template>
        </a>
      </ng-container>
    </tbody>
  </table>

  <!--
      Empty row just to handle intersection and trigger event when reach the end of the list

      We don't want to trigger scroll event if table is loading
    -->
  <div
    intersectionObserver
    *ngIf="!loading"
    (trigger$)="scrollEnd$.next($event)"
  ></div>

  <!-- Use a template to not break html table hierarchy by adding a component element in the DOM tree -->
  <ng-template
    #rowTemplate
    let-row="row"
    let-rowIndex="rowIndex"
  >
    <td
      class="cell-counter"
      [class.first-bold]="firstCellBold"
      *ngIf="withCounter"
    >
      <div
        class="checkbox-container"
        *ngIf="withSelection"
      >
        <lefty-checkbox
          class="no-label no-margin"
          [checked]="isSelected(row.data)"
          (click)="toggleSelection($event, row.data)"
        ></lefty-checkbox>
      </div>

      <div class="formatted-value">{{ rowIndex + 1 }}</div>
    </td>
    <td
      *ngFor="let col of visibleColumns; trackBy: trackByCol; let i = index"
      [ngClass]="cellClass(col)"
      [class.align-right]="col.alignRight"
      [class.first-bold]="firstCellBold"
    >
      <div
        class="checkbox-container"
        *ngIf="withSelection && i === 0 && withCounter === false"
      >
        <lefty-checkbox
          class="no-label no-margin"
          [checked]="isSelected(row.data)"
          (click)="toggleSelection($event, row.data)"
        ></lefty-checkbox>
      </div>

      <dynamic-component
        *ngIf="componentFactories[col.id]"
        [value]="getCell(row, i).data"
        [componentFactory]="componentFactories[col.id]"
      >
      </dynamic-component>
      <div
        *ngIf="!componentFactories[col.id]"
        class="formatted-value"
        [class.relative]="getCell(row, i).copyToolTip"
      >
        <lefty-icon
          *ngIf="
            getCell(row, i).icon !== '' &&
            getCell(row, i).formattedValue !== '-'
          "
          [icon]="getCell(row, i).icon"
          size="large"
        ></lefty-icon>
        <a
          class="link black"
          *ngIf="getCell(row, i).link !== ''"
          [href]="getCell(row, i).link"
          target="_blank"
          >{{ getCell(row, i).formattedValue }}</a
        >
        <ng-container *ngIf="getCell(row, i).link === ''">
          {{ getCell(row, i).formattedValue }}
        </ng-container>
        <button
          class="copy-button small outline left-icon"
          *ngIf="getCell(row, i).copyToolTip"
          (click)="copyLinkToClipboard(getCell(row, i))"
        >
          <lefty-icon icon="copy"> </lefty-icon>
          <ng-container i18n>Copy</ng-container>
        </button>
      </div>
    </td>
  </ng-template>
</div>
