import {
  ChangeDetectionStrategy,
  Component,
  inject,
  input,
  signal,
} from '@angular/core';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import {
  escapeRegExp,
  isEmptyString,
  isGhostTeam,
  isNotEmptyString,
  isNotNil,
} from '@frontend2/core';
import { TeamsResponse_TeamLight } from '@frontend2/proto/librarian/proto/admin_pb';
import {
  LeftyControlValueAccessor,
  LeftyFormAutocompleteComponent,
  LeftySpinnerComponent,
} from '@frontend2/ui';
import { debounceTime, filter } from 'rxjs';
import { WorkspacesCache } from '../../workspaces.cache';

@Component({
  selector: 'workspace-autocomplete',
  templateUrl: './workspace-autocomplete.component.html',
  styleUrl: './workspace-autocomplete.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [LeftyFormAutocompleteComponent, LeftySpinnerComponent],
})
export class WorkspaceAutoCompleteComponent extends LeftyControlValueAccessor<TeamsResponse_TeamLight> {
  constructor() {
    super();

    toObservable(this.searchValue)
      .pipe(debounceTime(300), takeUntilDestroyed())
      .subscribe((val) => this.searchTeam(val));

    toObservable(this.selectedTeamOwnerId)
      .pipe(takeUntilDestroyed())
      .pipe(
        filter((id) => id !== BigInt(0) && id !== this.value()?.owner?.userId),
      )
      .subscribe(async (id) => {
        this.workspacesLoading.set(true);
        const res = await this.workspacesCache.load();
        this.workspacesLoading.set(false);

        const selectedTeam = res.teams.find((t) => t.owner?.userId === id);
        if (isNotNil(selectedTeam)) {
          this.writeValue(selectedTeam);
        }
      });
  }

  private readonly workspacesCache = inject(WorkspacesCache);

  readonly searchValue = signal('');

  readonly options = signal<TeamsResponse_TeamLight[]>([]);

  readonly loading = signal(false);
  readonly workspacesLoading = signal(false);

  readonly selectedTeamOwnerId = input(BigInt(0));

  async searchTeam(teamText: string): Promise<void> {
    this.loading.set(true);
    const res = await this.workspacesCache.load();
    const teams = res.teams;

    const query = escapeRegExp(teamText.trim());
    if (isEmptyString(query)) {
      this.options.set([...teams]);
    } else {
      const regexp = new RegExp(query, 'i');

      this.options.set(
        teams.filter(
          (t) =>
            regexp.test(t.name) ||
            regexp.test(t.owner?.email ?? '') ||
            regexp.test(t.owner?.userId?.toString() ?? '') ||
            regexp.test(t.owner?.userAccountId?.toString() ?? ''),
        ),
      );
    }
    this.loading.set(false);
  }

  teamRenderer(team: TeamsResponse_TeamLight): string {
    if (isGhostTeam(team)) {
      return '';
    }

    if (isNotEmptyString(team.name)) {
      return `${team.name} (${team.owner?.email})`;
    }

    return `Team of ${team.owner?.email}`;
  }

  override isValidType(obj: unknown): obj is TeamsResponse_TeamLight {
    return obj instanceof TeamsResponse_TeamLight;
  }

  override writeValue(obj: unknown): void {
    if (this.isValidType(obj)) {
      this.value.set(obj);
      this.searchValue.set(this.teamRenderer(obj));
    } else {
      this.searchValue.set('');
    }
  }
}
