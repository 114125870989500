<form
  class="grid"
  [formGroup]="formModel"
>
  <div class="col-12">
    <lefty-chips-editor
      label="Mentions"
      formControlName="mentions"
      delimiters=",@ "
      prefix="@"
    >
    </lefty-chips-editor>
  </div>
  <div class="col-12">
    <lefty-chips-editor
      label="Hashtags"
      formControlName="hashtags"
      delimiters=",# "
      prefix="#"
    >
    </lefty-chips-editor>
  </div>
  <div class="col-8">
    <search-and-select-dropdown
      class="language-input"
      formControlName="language"
      [loading]="languagesCache.isLoading()"
      [options]="availableLanguages()"
      label="Language"
      [showHeader]="false"
      placeholder="Any language"
      [itemRenderer]="readableLanguage"
    ></search-and-select-dropdown>
  </div>
  <div class="col-4">
    <lefty-form-number-input
      type="percent"
      label="Percentage"
      formControlName="percentage"
    >
    </lefty-form-number-input>
  </div>
  <div class="col-12">
    <search-location-autocomplete
      class="form-container countries-input"
      label="Country"
      formControlName="country"
    >
    </search-location-autocomplete>
  </div>
  <div class="col-12">
    <lefty-form-number-input
      label="Min. followers"
      class="minfollowers-input"
      formControlName="minFollowers"
    >
    </lefty-form-number-input>
  </div>
</form>
