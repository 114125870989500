export enum SelectAllStrategy {
  visible,
  all,
}

export interface MassSelectState<T> {
  readonly selectedItems: T[];
  readonly availableItemsCount: number;
  readonly selectAllStrategy: SelectAllStrategy;
  readonly allSelected: boolean;
}

export function createMassSelectState<T>(
  state?: Partial<MassSelectState<T>>,
): MassSelectState<T> {
  return {
    selectedItems: state?.selectedItems ?? [],
    availableItemsCount: state?.availableItemsCount ?? 0,
    selectAllStrategy: state?.selectAllStrategy ?? SelectAllStrategy.visible,
    allSelected: state?.allSelected ?? false,
  };
}
