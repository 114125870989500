import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UiModule } from '@frontend2/ui';
import { CustomersListComponent } from './customers-list/customers-list.component';
import { FlandersCustomersRouteComponent } from './customers.route';

import { FormsModule } from '@angular/forms';
import { CommonComponentsModule } from '../common/components/common-components.module';
import { CUSTOMER_ID_KEY } from '../constants';

const routes: Routes = [
  {
    path: '',
    component: FlandersCustomersRouteComponent,
    data: {
      cacheComponent: true,
    },
  },
  {
    path: `:${CUSTOMER_ID_KEY}`,
    loadChildren: () =>
      import('./customer-details-route/customer-details.module').then(
        (m) => m.CustomerDetailsModule,
      ),
  },
];

@NgModule({
    imports: [
        UiModule,
        CommonModule,
        FormsModule,
        RouterModule.forChild(routes),
        CommonComponentsModule,
        FlandersCustomersRouteComponent, CustomersListComponent,
    ],
    providers: [],
})
export class CustomersModule {}
