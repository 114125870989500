<div
  class="toggle-container"
  [class.checked]="checked"
  (focus)="handleFocus($event)"
  (blur)="handleBlur($event)"
  (click)="toggle()"
>
  <lefty-icon
    *ngIf="checked"
    icon="check"
  ></lefty-icon>
  <div class="toggle"></div>
</div>
