import { MediaValueCalculatorMethod } from '@frontend2/proto/common/proto/common_pb';

export function mediaValueCalculatorMethodToReadable(
  mediaValueCalculatorMethod: MediaValueCalculatorMethod,
): string {
  switch (mediaValueCalculatorMethod) {
    case MediaValueCalculatorMethod.DEFAULT:
      return 'Default';
    case MediaValueCalculatorMethod.TIME:
      return 'Puig calculator';
    case MediaValueCalculatorMethod.LINKFLUENCE:
      return 'Chanel calculator';
    default:
      return 'unknown';
  }
}

// Note(hadrien): `Code` enum only list error code, we don't have `Code.Ok`
const _SUCCESS_GRPC_CODE = 0;

export function isGrpcStatusSuccess(code?: number): boolean {
  return code === _SUCCESS_GRPC_CODE;
}
