import {
  Component,
  ChangeDetectionStrategy,
  Input,
  HostBinding,
  inject,
} from '@angular/core';
import { LeftyPageComponent } from './lefty-page.component';

@Component({
  selector: 'lefty-section',
  templateUrl: './lefty-section.component.html',
  styleUrls: ['./lefty-section.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class LeftySectionComponent {
  readonly page = inject(LeftyPageComponent);

  // Take full width
  @HostBinding('class.wide')
  @Input()
  wide = false;

  // For the smallest content we could have
  @HostBinding('class.compact')
  @Input()
  compact = false;

  @HostBinding('class.mobile')
  get isMobile(): boolean {
    return this.page.mobile === true;
  }
}
