import { Campaign } from '@frontend2/proto/librarian/proto/campaigns_pb';
import { EntityLabel } from '@frontend2/proto/librarian/proto/entity_labels_pb';

export type DirectoryFilterCategory = 'campaigns' | 'labels';

export function directoryFilterCategoryToReadable(
  category: DirectoryFilterCategory,
): string {
  switch (category) {
    case 'campaigns':
      return $localize`Campaigns`;
    case 'labels':
      return $localize`Labels`;
  }
}

export interface FilterCategoriesListData {
  readonly availableCampaigns: Map<bigint, Campaign>;
  readonly availableInfluencerLabels: Map<bigint, EntityLabel>;
}
