@if (showSuccess()) {
  <div class="result success">
    <div class="message-container">
      <lefty-icon
        class="icon"
        icon="success_feedback"
      ></lefty-icon>
      <p class="message body-1">{{ successListLabel() }}</p>
    </div>
  </div>
}

@if (showFailed()) {
  <div class="result fails">
    <div class="message-container">
      <lefty-icon
        class="icon"
        icon="error_feedback"
      ></lefty-icon>
      <p class="message body-1">{{ failedListLabel() }}</p>
    </div>
    <div class="list-container">
      @for (username of slicedFailedResultsNames(); track $index) {
        <p class="usernames">{{ username }}</p>
      }
      @if (hasMoreFailed()) {
        @if (!showMoreFailed()) {
          <button
            class="show-more"
            leftyButton="plain"
            class="dark"
            (click)="showMoreFailed.set(true)"
            i18n
          >
            Show more
          </button>
        } @else {
          <button
            class="show-more"
            leftyButton="plain"
            class="dark"
            (click)="showMoreFailed.set(false)"
            i18n
          >
            Show less
          </button>
        }
      }
    </div>
  </div>
}
