import {
  ChangeDetectionStrategy,
  Component,
  computed,
  effect,
  inject,
  Input,
  input,
  model,
  signal,
} from '@angular/core';
import { escapeRegExp, isNil, isNotNil } from '@frontend2/core';
import { Campaign } from '@frontend2/proto/librarian/proto/campaigns_pb';
import { EntityLabel } from '@frontend2/proto/librarian/proto/entity_labels_pb';
import { CampaignsCache } from '../campaigns/campaigns.cache';
import { InfluencerLabelsCache } from '../labels/labels.cache';
import {
  ButtonSize,
  LeftyButtonDirective,
} from '../lefty-button-directive/lefty-button.directive';
import {
  DirectoryFilterCategory,
  directoryFilterCategoryToReadable,
  FilterCategoriesListData,
} from './directory-filters.helpers';
import { KeyValuePipe } from '@angular/common';
import { LeftyCheckboxComponent } from '../checkbox/checkbox.component';
import { LeftyFormInputComponent } from '../lefty-form-input/lefty-form-input.component';
import { LeftyIconComponent } from '../icon/icon.component';
import { LeftyListItemComponent } from '../lefty-list/lefty-list-item.component';
import { LeftyListComponent } from '../lefty-list/lefty-list.component';
import { LeftyPopupComponent } from '../lefty-popup/lefty-popup.component';

@Component({
  selector: 'lefty-directory-filters-button',
  templateUrl: 'directory-filters.component.html',
  styleUrls: ['directory-filters.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    LeftyButtonDirective,
    LeftyPopupComponent,
    LeftyListComponent,
    LeftyListItemComponent,
    LeftyIconComponent,
    LeftyFormInputComponent,
    LeftyCheckboxComponent,
    KeyValuePipe,
  ],
})
export class LeftyDirectoryFiltersButtonComponent {
  constructor() {
    effect(
      () => {
        this._loadlistItemsData(this.filtersCategories());
      },
      {
        allowSignalWrites: true,
      },
    );
  }

  readonly campaignsCache = inject(CampaignsCache);
  readonly influencerLabelsCache = inject(InfluencerLabelsCache);

  @Input()
  buttonSize: ButtonSize = 'medium';

  readonly selectedInfluencerLabels = model(new Map<bigint, EntityLabel>());
  readonly selectedCampaigns = model(new Map<bigint, Campaign>());

  readonly filtersCategories = input<DirectoryFilterCategory[]>([
    'campaigns',
    'labels',
  ]);

  readonly searchValue = signal('');

  readonly searchPlaceholder = computed(() => {
    if (this.expandedCategoryIsCampaigns()) {
      return $localize`Search campaigns`;
    } else if (this.expandedCategoryIsLabels()) {
      return $localize`Search labels`;
    }
    return '';
  });

  readonly hideHeader = signal(false);

  readonly allSelected = computed(() => {
    if (this.expandedCategoryIsCampaigns()) {
      return this.isAllFilteredCampaignSelected();
    }

    if (this.expandedCategoryIsLabels()) {
      return this.isAllFilteredInfluencerLabelsSelected();
    }

    return false;
  });

  readonly isAllFilteredCampaignSelected = computed(() => {
    for (const key of this.filteredAvailableCampaigns().keys()) {
      if (!this.selectedCampaigns().has(key)) {
        return false;
      }
    }
    return true;
  });

  readonly isAllFilteredInfluencerLabelsSelected = computed(() => {
    for (const key of this.filteredAvailableInfluencerLabels().keys()) {
      if (!this.selectedInfluencerLabels().has(key)) {
        return false;
      }
    }
    return true;
  });

  readonly selectionIsNotEmpty = computed(() => {
    if (this.expandedCategoryIsCampaigns()) {
      return this.selectedCampaigns().size > 0;
    }
    if (this.expandedCategoryIsLabels()) {
      return this.selectedInfluencerLabels().size > 0;
    }
    return false;
  });

  readonly currentExpandedCategory = signal<
    DirectoryFilterCategory | undefined
  >(undefined);

  readonly hasExpandedCategory = computed(() =>
    isNotNil(this.currentExpandedCategory()),
  );

  readonly filterListData = computed<FilterCategoriesListData>(() => {
    return {
      availableCampaigns: this.campaignsCache.cachedData(),
      availableInfluencerLabels: this.influencerLabelsCache.cachedData(),
    };
  });

  readonly filteredAvailableCampaigns = computed(() => {
    //we perform the filtering only if it is expanded
    if (!this.expandedCategoryIsCampaigns()) {
      return this.filterListData().availableCampaigns;
    }
    const filteredCampaigns = new Map<bigint, Campaign>();
    const query = escapeRegExp(this.searchValue().trim());
    const regex = new RegExp(query, 'i');

    this.filterListData().availableCampaigns.forEach((campaign, key) => {
      if (regex.test(campaign.name)) {
        filteredCampaigns.set(key, campaign);
      }
    });

    return filteredCampaigns;
  });

  readonly filteredAvailableInfluencerLabels = computed(() => {
    if (!this.expandedCategoryIsLabels()) {
      return this.filterListData().availableInfluencerLabels;
    }
    const filteredLabels = new Map<bigint, EntityLabel>();
    const query = escapeRegExp(this.searchValue().trim());
    const regex = new RegExp(query, 'i');

    this.filterListData().availableInfluencerLabels.forEach((label, key) => {
      if (regex.test(label.name)) {
        filteredLabels.set(key, label);
      }
    });

    return filteredLabels;
  });

  readonly expandedCategoryIsCampaigns = computed(
    () => this.currentExpandedCategory() === 'campaigns',
  );
  readonly expandedCategoryIsLabels = computed(
    () => this.currentExpandedCategory() === 'labels',
  );

  readonly filtersCount = computed(
    () => this.selectedInfluencerLabels().size + this.selectedCampaigns().size,
  );

  collapse(): void {
    this.currentExpandedCategory.set(undefined);
    this.searchValue.set('');
  }

  selectAll(): void {
    if (this.expandedCategoryIsCampaigns()) {
      this.selectAllCampaign();
    } else if (this.expandedCategoryIsLabels()) {
      this.selectAllInfluencerLabel();
    }
  }

  selectAllCampaign(): void {
    const currectSelection = new Map(this.selectedCampaigns());
    this.filteredAvailableCampaigns().forEach((campaign, key) => {
      currectSelection.set(key, campaign);
    });
    this.selectedCampaigns.set(currectSelection);
  }

  selectAllInfluencerLabel(): void {
    const currectSelection = new Map(this.selectedInfluencerLabels());
    this.filteredAvailableInfluencerLabels().forEach((label, key) => {
      currectSelection.set(key, label);
    });
    this.selectedInfluencerLabels.set(currectSelection);
  }

  clearAll(): void {
    if (this.expandedCategoryIsCampaigns()) {
      this.clearAllCampaign();
    } else if (this.expandedCategoryIsLabels()) {
      this.clearAlllInfluencerLabel();
    }
  }

  clearAllCampaign(): void {
    this.selectedCampaigns.set(new Map());
  }

  clearAlllInfluencerLabel(): void {
    this.selectedInfluencerLabels.set(new Map());
  }

  getCategoryName(category: DirectoryFilterCategory | undefined): string {
    if (isNil(category)) {
      return '-';
    }
    return directoryFilterCategoryToReadable(category);
  }

  toggleCampaign(campaign: Campaign): void {
    const currectSelection = new Map(this.selectedCampaigns());
    if (currectSelection.has(campaign.id)) {
      currectSelection.delete(campaign.id);
    } else {
      currectSelection.set(campaign.id, campaign);
    }
    this.selectedCampaigns.set(currectSelection);
  }

  toggleLabel(label: EntityLabel): void {
    if (isNil(label.id)) {
      return;
    }
    const currectSelection = new Map(this.selectedInfluencerLabels());

    if (currectSelection.has(label.id)) {
      currectSelection.delete(label.id);
    } else {
      currectSelection.set(label.id, label);
    }
    this.selectedInfluencerLabels.set(currectSelection);
  }

  campaignIsSelected(id: bigint): boolean {
    return this.selectedCampaigns().has(id);
  }

  labelIsSelected(id: bigint | undefined): boolean {
    return isNotNil(id) && this.selectedInfluencerLabels().has(id);
  }

  private async _loadlistItemsData(
    categoroies: DirectoryFilterCategory[],
  ): Promise<void> {
    if (categoroies.includes('campaigns')) {
      await this.campaignsCache.load();
    }
    if (categoroies.includes('labels')) {
      await this.influencerLabelsCache.load();
    }
  }
}
