import {
  ChangeDetectionStrategy,
  Component,
  Input,
  Output,
} from '@angular/core';
import { isGhostTeam } from '@frontend2/core';
import {
  TeamUser,
  TeamsResponse_TeamLight,
} from '@frontend2/proto/librarian/proto/admin_pb';
import { LeftyComponent } from '@frontend2/ui';
import { Subject } from 'rxjs';
import { MEMBERS, TEAMS_PATH } from '../../constants';
import { NgFor } from '@angular/common';
import { RouterLink } from '@angular/router';
import { WorkspaceItemComponent } from '../workspace-item/workspace-item.component';

@Component({
    selector: 'workspaces-list',
    templateUrl: 'workspaces-list.component.html',
    styleUrls: ['workspaces-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        NgFor,
        RouterLink,
        WorkspaceItemComponent,
    ],
})
export class WorkspacesListComponent extends LeftyComponent {
  @Input() teams: TeamsResponse_TeamLight[] = [];

  @Output()
  readonly workspaceToEdit$ = new Subject<TeamUser>();

  trackBy = (index: number, item: TeamsResponse_TeamLight): bigint => item.id;

  teamIsGhost(team: TeamsResponse_TeamLight): boolean {
    return isGhostTeam(team);
  }

  teamLink(team: TeamsResponse_TeamLight): string | undefined {
    return !this.teamIsGhost(team)
      ? `/${TEAMS_PATH}/${team.id}/${MEMBERS}`
      : undefined;
  }

  editWorkspace(user: TeamUser): void {
    this.workspaceToEdit$.next(user);
  }
}
