<form
  class="grid"
  [formGroup]="formModel"
>
  <div class="col-12">
    <lefty-form-autocomplete
      (inputTextChange)="searchValue$.next($event)"
      [loading]="cacheLoading"
      [options]="teamOptions"
      formControlName="client"
      label="Team"
      [noFiltering]="true"
      [itemRenderer]="teamRenderer"
    ></lefty-form-autocomplete>
  </div>
</form>
