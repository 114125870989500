<div
  class="top"
  *ngIf="withTop"
>
  <ng-content select="[top]"></ng-content>
</div>
<div class="content">
  <ng-content></ng-content>
</div>
<div
  class="bottom"
  *ngIf="withBottom"
>
  <ng-content select="[bottom]"></ng-content>
</div>
