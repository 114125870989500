<button
  class="left-icon filters-button action large"
  [class.visible]="filtersVisible"
  (click)="toggleFilters()"
>
  <lefty-icon icon="filters"></lefty-icon>
  {{ label }}
  <span
    class="count"
    *ngIf="filtersCount > 0"
  >
    ({{ filtersCount }})
  </span>
</button>
