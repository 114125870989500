import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  HostListener,
  Input,
  Output,
} from '@angular/core';
import { LeftyComponent } from '../utils';
import { Subject } from 'rxjs';
import { NgIf } from '@angular/common';

@Component({
  selector: 'lefty-expansion-panel',
  templateUrl: 'lefty-expansion-panel.component.html',
  styleUrls: ['lefty-expansion-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf],
})
export class LeftyExpansionPanelComponent extends LeftyComponent {
  @Output()
  readonly expanded$ = new Subject<boolean>();

  private _expanded = false;

  get expanded(): boolean {
    return this._expanded;
  }

  @Input()
  set expanded(val: boolean) {
    if (val === this.expanded) {
      return;
    }
    this.setState(() => (this._expanded = val));
    this.expanded$.next(val);
  }

  @HostBinding('class.expanded')
  get isExpanded(): boolean {
    return this._expanded;
  }

  @HostListener('click')
  onClick(): void {
    if (this.expanded === false) {
      this.setState(() => (this.expanded = true));
    }
  }
}
