import { NgClass, NgFor, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Messages, Networks } from '@frontend2/core';
import { Network } from '@frontend2/proto/common/proto/common_pb';
import { LoggedBootstrapping } from '@frontend2/proto/librarian/proto/frontend_misc_pb';
import { LeftyCheckboxComponent } from '../../checkbox/checkbox.component';
import { SelectorDropdownBase } from '../../dropdown';
import { LeftyIconComponent } from '../../icon/icon.component';
import { NetworkIconComponent } from '../../icon/network.component';
import { LeftyListItemComponent } from '../../lefty-list/lefty-list-item.component';
import { LeftyListComponent } from '../../lefty-list/lefty-list.component';
import { LeftyPopupComponent } from '../../lefty-popup/lefty-popup.component';

// TODO(hadrien): should use lefty-form-select
@Component({
  selector: 'networks-selector-dropdown',
  templateUrl: './networks-selector-dropdown.component.html',
  styleUrls: [
    '../selector.scss',
    './networks-selector-dropdown.component.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgIf,
    NgClass,
    LeftyIconComponent,
    LeftyPopupComponent,
    LeftyListComponent,
    NgFor,
    LeftyListItemComponent,
    LeftyCheckboxComponent,
    NetworkIconComponent,
  ],
})
export class NetworksSelectorDropdownComponent extends SelectorDropdownBase<Network> {
  constructor() {
    super();
    this.label = Messages.networks;
  }

  private _placeholder = Messages.allNetworks;

  @Input()
  set placeholder(val: string) {
    this._placeholder = val;
  }

  get placeholder(): string {
    if (this.selection.length === 0) {
      return this._placeholder;
    }

    return this.selection.map(this.readableNetwork).join(', ');
  }

  @Input()
  buttonClass = '';

  @Input()
  user = new LoggedBootstrapping();

  @Input()
  networks: Network[] = [];

  get options(): Network[] {
    return this.networks;
  }

  readableNetwork(network: Network): string {
    return Networks.readable(network);
  }
}
