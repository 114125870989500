<lefty-dialog
  class="medium"
  [(visible)]="visible"
  [autoDismissable]="true"
  [title]="title"
>
  <h3 title>
    {{ title }}
  </h3>
  <div
    *ngIf="cacheLoading || loading"
    class="loading-placeholder"
  >
    <lefty-spinner></lefty-spinner>
  </div>
  <form
    #userForm="ngForm"
    *ngIf="!cacheLoading && !loading"
    class="grid"
  >
    <div class="col-12">
      <lefty-form-input
        label="Email"
        type="email"
        [(ngModel)]="signupReq.email"
        ngControl="email"
        name="email"
        autoFocus
        [required]="true"
      ></lefty-form-input>
    </div>
    <div class="col-6">
      <lefty-form-input
        label="First name"
        name="firstName"
        [(ngModel)]="signupReq.firstName"
        ngControl="firstName"
        [required]="true"
      ></lefty-form-input>
    </div>
    <div class="col-6">
      <lefty-form-input
        label="Last name"
        name="lastName"
        [(ngModel)]="signupReq.lastName"
        ngControl="lastName"
        [required]="true"
      ></lefty-form-input>
    </div>
    <div
      class="col-6"
      *ngIf="!edit"
    >
      <lefty-form-input
        label="Company"
        [disabled]="edit"
        name="company"
        [(ngModel)]="signupReq.company"
        ngControl="company"
        [required]="true"
      ></lefty-form-input>
    </div>
    <div class="col-9">
      <span class="body-1"> Login access </span>
      <lefty-radio-group class="outline">
        <lefty-radio
          [checked]="!ssoAccess"
          (checkedChange)="ssoAccess = !$event"
          no-ink
        >
          <div class="flex">
            <div class="body-1">Password</div>
            <div class="fill-space"></div>
            <div
              class="edit-password"
              *ngIf="edit && !ssoAccess"
              (click)="editPassword = !editPassword"
            >
              {{ editPassword ? 'Cancel' : 'Set new password' }}
            </div>
          </div>
          <lefty-form-input
            *ngIf="showPasswordInput"
            label=""
            [(ngModel)]="signupReq.password"
            ngControl="password"
            name="password"
            [required]="true"
          ></lefty-form-input>
        </lefty-radio>
        <lefty-radio
          [(checked)]="ssoAccess"
          no-ink
        >
          <div class="body-1">SSO</div>
          <lefty-form-select
            #provider
            *ngIf="ssoAccess"
            [options]="providersList()"
            [itemRenderer]="providerRenderer"
            [(ngModel)]="selectedProvider"
            name="ssoProvider"
          ></lefty-form-select>
        </lefty-radio>
      </lefty-radio-group>
    </div>
    <div class="col-12">
      <lefty-checkbox
        label="Activate Monitoring Dashboard"
        [(checked)]="signupReq.dashboardAccess"
      ></lefty-checkbox>
    </div>
    <div
      class="col-12"
      *ngIf="isSuperAdmin"
    >
      <lefty-checkbox
        label="Is Admin"
        [(checked)]="adminAccess"
      ></lefty-checkbox>
    </div>
  </form>
  <div footer>
    <button
      (click)="onSubmit()"
      class="primary"
      [disabled]="!isFormValid || loading"
    >
      <lefty-spinner *ngIf="loading"></lefty-spinner>
      Save
    </button>
  </div>
</lefty-dialog>
