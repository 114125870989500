<lefty-form-select
  class="time-select"
  [placeholder]="placeholder()"
  [options]="timeOptions()"
  [itemRenderer]="timeRenderer"
  [selection]="value()"
  (selectionChange)="timeChange($event)"
  [required]="required()"
  [isDisabledCheck]="isDisabledTime"
></lefty-form-select>
