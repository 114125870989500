import {
  ProfileMergeRequestCompletion,
  ProfileMergeRequestPb,
  ProfileMergeRequestStatus,
} from '@frontend2/proto/librarian/proto/profile_merge_requests_pb';

export function isGhostProfileMergeRequest(
  mergeRequest: ProfileMergeRequestPb,
): boolean {
  return mergeRequest.requestId === BigInt(0);
}

export function readableProfileMergeRequestStatus(
  status: ProfileMergeRequestStatus,
): string {
  switch (status) {
    case ProfileMergeRequestStatus.PROFILE_MERGE_PENDING:
      return $localize`Pending`;
    case ProfileMergeRequestStatus.PROFILE_MERGE_APPROVED:
      return $localize`Approved`;
    case ProfileMergeRequestStatus.PROFILE_MERGE_REJECTED:
      return $localize`Rejected`;
    case ProfileMergeRequestStatus.PROFILE_MERGE_DELETED:
      return $localize`Deleted`;
  }
}

export function readableProfileMergeRequestCompletion(
  completion: ProfileMergeRequestCompletion,
): string {
  switch (completion) {
    case ProfileMergeRequestCompletion.PROFILE_MERGE_COMPLETION_PENDING:
      return $localize`Pending`;
    case ProfileMergeRequestCompletion.PROFILE_MERGE_COMPLETION_IN_PROGRESS:
      return $localize`In progress`;
    case ProfileMergeRequestCompletion.PROFILE_MERGE_COMPLETION_DONE:
      return $localize`Completed`;
    case ProfileMergeRequestCompletion.PROFILE_MERGE_COMPLETION_FAILED:
      return $localize`Failed`;
    case ProfileMergeRequestCompletion.PROFILE_MERGE_COMPLETION_REVERSED:
      return $localize`Reversed`;
  }
}
