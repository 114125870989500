<div
  *ngIf="!teamIsGhost(team)"
  class="al-card with-border with-padding flex row vCenter clickable"
>
  <div class="infos team fill-space">
    <span class="value">{{ teamTitle }}</span>
    <span class="label">Added on {{ creationDate }}</span>
  </div>
  <div class="infos">
    <span class="value">
      {{ membersCount }}
    </span>
    <span class="label">Members</span>
  </div>
  <div class="infos subscription">
    <span class="value">
      {{ teamSubscription }}
    </span>
    <span class="label">Subscription</span>
  </div>
  <div class="infos owner">
    <span class="value flex">
      <span
        class="owner-email-text"
        leftyTooltipIfEllipsis
      >
        {{ ownerEmail }}
      </span>
      <button
        class="plain dark icon small copy-button"
        (click)="copyEmail($event)"
      >
        <lefty-icon icon="content_copy"></lefty-icon>
      </button>
    </span>
    <span class="label">Owner</span>
  </div>
  <lefty-menu-icon>
    <lefty-list-item (click)="editWorkspace()">
      Edit workspace
    </lefty-list-item>
  </lefty-menu-icon>
</div>

<div
  *ngIf="teamIsGhost(team)"
  class="al-card with-border with-padding flex row vCenter ghost"
>
  <div class="flex column fill-space">
    <h4 class="infos"></h4>
    <div class="secondary-text body-1"></div>
  </div>
</div>
