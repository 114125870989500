<lefty-page [loading]="routeLoading()">
  <lefty-section>
    <lefty-section-header>
      <h1>{{ customersCount }}</h1>
      <button
        class="primary"
        (click)="createOrEditUserDialog.openWith()"
        action
      >
        Add User
      </button>
    </lefty-section-header>
    <lefty-section-header>
      <lefty-form-input
        placeholder="Find by email, name or ID"
        leadingGlyph="search"
        [value]="email()"
        (valueChange)="this.searchValue.set($event)"
        class="outline rounded search-input"
      >
      </lefty-form-input>

      <lefty-sort-select
        placeholder="Default (Creation date)"
        [selection]="sort()"
        (selectionChange)="sortChange($event)"
        [options]="sortOptions"
        [itemRenderer]="sortRenderer"
        action
      >
      </lefty-sort-select>

      <search-and-select-dropdown
        multi
        popupPlacement="bottom-end"
        buttonClass="outline"
        [selection]="companies()"
        (selectionChange)="companiesChange($event)"
        [options]="companyOptions()"
        placeholder="All companies"
        action
      ></search-and-select-dropdown>
    </lefty-section-header>
    <customers-list
      [customers]="customers()"
      (customerToEdit$)="createOrEditUserDialog.openWith($event)"
    ></customers-list>

    <intersection-observer (trigger$)="nextPage()"></intersection-observer>
  </lefty-section>
</lefty-page>

<create-or-edit-user-dialog
  #createOrEditUserDialog
  (success$)="refreshView()"
></create-or-edit-user-dialog>
