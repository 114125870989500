import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  NavItem,
  LeftyRouterLinkDirective,
  LeftyRouterLinkActiveDirective,
} from '../router-utils';
import { NavigationSubMenuGroupComponent } from './submenu.component';
import { NgIf } from '@angular/common';

@Component({
  selector: 'navigation-menu-group',
  templateUrl: 'menu_group.component.html',
  styleUrls: ['menu_group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgIf,
    LeftyRouterLinkDirective,
    LeftyRouterLinkActiveDirective,
    NavigationSubMenuGroupComponent,
  ],
})
export class NavigationMenuGroupComponent {
  @Input()
  navigations: NavItem[] = [];
}
