import { NgClass, NgFor, NgIf } from '@angular/common';
import {
  Attribute,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Optional,
  Output,
  Self,
  Signal,
} from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import {
  Messages,
  escapeRegExp,
  isNil,
  isNotEmptyString,
} from '@frontend2/core';
import { LogicOperator } from '@frontend2/proto/common/proto/common_pb';
import { Placement } from '@popperjs/core';
import { Subject } from 'rxjs';
import { SelectorDropdownBase } from '../../dropdown';
import { ComponentFactory } from '../../dynamic-component.component';
import { LeftyFormValueBase } from '../../form';
import { LeftyIconComponent } from '../../icon/icon.component';
import { IntersectionObserverDirective } from '../../intersection-observer.directive';
import {
  ButtonSize,
  ButtonType,
  LeftyButtonDirective,
} from '../../lefty-button-directive/lefty-button.directive';
import { FilteringFn } from '../../lefty-form-autocomplete/lefty-form-autocomplete.component';
import { LeftyFormInputComponent } from '../../lefty-form-input/lefty-form-input.component';
import { LeftySelectDropdownItemComponent } from '../../lefty-form-select/lefty-select-dropdown-item.component';
import { ItemRenderer } from '../../lefty-form-select/utils';
import { LeftyFormComponent } from '../../lefty-form/lefty-form.component';
import { LeftyListComponent } from '../../lefty-list/lefty-list.component';
import { LeftyLogicOperatorComponent } from '../../lefty-logic-operator-selector/logic-operator.component';
import { LeftyPopupComponent } from '../../lefty-popup/lefty-popup.component';
import { LeftySpinnerComponent } from '../../loading.component';
import { AngularUtils, attributeToBool, createOutput } from '../../utils';

@Component({
  selector: 'search-and-select-dropdown',
  templateUrl: './search-and-select-dropdown.component.html',
  styleUrls: [
    '../selector.scss',
    './search-and-select-dropdown.component.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    LeftyFormComponent,
    LeftyButtonDirective,
    NgClass,
    NgIf,
    LeftyIconComponent,
    LeftyPopupComponent,
    LeftyFormInputComponent,
    LeftySpinnerComponent,
    LeftyListComponent,
    NgFor,
    LeftySelectDropdownItemComponent,
    IntersectionObserverDirective,
    LeftyLogicOperatorComponent,
  ],
})
export class SearchAndSelectDropdownComponent<T>
  extends SelectorDropdownBase<T>
  implements OnInit, ControlValueAccessor
{
  constructor(
    @Optional() @Self() public ngControl?: NgControl,
    @Attribute('multi') multi?: string,
  ) {
    super();

    this.multiSelect = attributeToBool(multi);
    this.disposer.addFunction(() => this.touched$.complete());

    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
  }

  @Input()
  closeOnSelect = false;

  @Input()
  errorMessage = '';

  @Input()
  hintText = '';

  @Input()
  topHintText = false;

  @Input()
  optional = false;

  @Input()
  large = false;

  @Output()
  readonly touched$: EventEmitter<void> = new EventEmitter<void>();

  @Input()
  multiSelect = false;

  @Input()
  hidePlaceholderCount = false;

  @Input()
  trackByFn: (index: number, _: T) => unknown = AngularUtils.trackByIndex;

  /// Function to filter [options] item
  /// if null, it will use [_simpleFilter] function
  @Input()
  filteringFn?: FilteringFn<T>;

  /// Loading state of the popup
  /// Show a loading indicato if true
  @Input()
  loading = false;

  @Input()
  showHeader = true;

  @Input()
  hideSearch = false;

  @Input()
  disabledItems: T[] = [];

  @Input()
  withLogicOperator = false;

  selectedLogicOperator: LogicOperator = LogicOperator.OR;

  @Output() readonly logicOperatorChange = createOutput<LogicOperator>();

  @Input()
  set logicOperator(logicOperator: LogicOperator) {
    this.selectedLogicOperator = logicOperator;
  }

  handleLogicOperatorChange(option: LogicOperator): void {
    if (this.selectedLogicOperator === option) {
      return;
    }
    if (isNil(option)) {
      return;
    }
    this.selectedLogicOperator = option;
    this.logicOperatorChange.next(option);
  }

  @Input()
  // DEPRECATED: use buttonType and buttonSize
  buttonClass = '';

  @Input()
  buttonType: ButtonType = 'secondary';

  @Input()
  buttonSize: ButtonSize = 'medium';

  private _popupClassName = '';

  get popupClassName(): string {
    return `search-and-select-popup ${this._popupClassName ?? ''}`;
  }

  @Input()
  set popupClassName(value) {
    this._popupClassName = value;
  }

  /// Message when filtering function return empty list
  @Input()
  notFoundMessage = Messages.notFound;

  @Input()
  componentFactory?: ComponentFactory<T>;

  @Input()
  itemRenderer: ItemRenderer<T> = AngularUtils.defaultItemRenderer;

  @Input()
  disabled = false;

  @Input()
  enableIndeterminate = false;

  // by default, the user must start typing in search box
  // do display search results
  // If allowEmptySearch is true, we will display results event if search box is empty
  @Input()
  allowEmptySearch = false;

  @Input()
  sortFn: (a: T, b: T) => number = (a, b) =>
    this.itemRenderer(a)
      .toLowerCase()
      .localeCompare(this.itemRenderer(b).toLowerCase());

  @Output()
  readonly indeterminateChange = new Subject<T[]>();

  private _indeterminate: T[] = [];
  get indeterminate(): T[] {
    return this._indeterminate;
  }

  @Input()
  set indeterminate(newVal: T[]) {
    if (this._indeterminate !== newVal) {
      this._indeterminate = newVal;
      this.indeterminateChange.next(newVal);
    }
  }

  @Input()
  override set popupVisible(newVal: boolean) {
    super.popupVisible = newVal;
    this.search = '';
  }

  override get popupVisible(): boolean {
    return super.popupVisible;
  }

  /// Placeholder if nothing selected
  private _placeholder = '';

  get placeholder(): string {
    if (this.selection.length === 0 && this.indeterminate.length === 0) {
      return this._placeholder;
    }

    if (this.selection.length !== 0 && this.indeterminate.length !== 0) {
      return `${this.selection.length} included, ${this.indeterminate.length} excluded`;
    }

    if (this.indeterminate.length !== 0) {
      return this.indeterminate
        .map(this.itemRenderer)
        .map((i) => `No ${i}`)
        .join(', ');
    }

    return this.selection.map(this.itemRenderer).join(', ');
  }

  @Input()
  set placeholder(value: string) {
    this._placeholder = value;
  }

  get placeholderCount(): number {
    return this.selection.length + this.indeterminate.length;
  }

  get selectionCount(): number {
    return this.selection.length;
  }

  private _search = '';
  get search(): string {
    return this._search;
  }

  set search(newVal: string) {
    if (this._search !== newVal) {
      this._search = newVal;
      this.filterAndUpdateUI(newVal);
    }
  }

  private _options: T[] = [];

  get options(): T[] {
    return this._options;
  }

  @Input()
  set options(options: T[]) {
    this._options = options ?? [];
    this.filterAndUpdateUI(this.search);
  }

  private _filteredOptions: T[] = [];

  get filteredOptions(): T[] {
    return this._filteredOptions;
  }

  set filteredOptions(values: T[]) {
    values = [...values].sort((a, b) => this.sortFn(a, b));
    this._filteredOptions = values;
  }

  get showOptions(): boolean {
    if (this.hideSearch || this.allowEmptySearch) {
      return this.loading === false;
    }
    return this.loading === false && isNotEmptyString(this.search);
  }

  private filterOptions(options: T[], name: string): Promise<T[]> | T[] {
    if (this.filteringFn) {
      return this.filteringFn(options, name);
    }
    return this.simpleFilter(options, name);
  }

  private _visibleOptions: T[] = [];

  get visibleOptions(): T[] {
    return this._visibleOptions;
  }

  private readonly _paginationSize = 100;

  private _paginateOptions(options: T[], from: number): T[] {
    const totalHits = options.length;
    const maxIndex = Math.min(from + this._paginationSize, totalHits);
    return options.slice(from, maxIndex);
  }

  nextPage(): void {
    if (this.visibleOptions.length === this.filteredOptions.length) {
      return;
    }

    this._visibleOptions = [
      ...this._visibleOptions,
      ...this._paginateOptions(
        this.filteredOptions,
        this.visibleOptions.length,
      ),
    ];

    this.changeDetection.markForCheck();
  }

  private async filterAndUpdateUI(name: string): Promise<void> {
    this.loading = true;
    this.changeDetection.markForCheck();

    this.filteredOptions = await this.filterOptions(this._options, name);
    this._visibleOptions = this._paginateOptions(this.filteredOptions, 0);

    this.loading = false;
    this.changeDetection.markForCheck();
  }

  private simpleFilter(options: T[], name: string): T[] {
    if (isNotEmptyString(name) === false) {
      return options;
    }
    const query = escapeRegExp(name.trim());
    const regExp = new RegExp(query, 'i');

    return options.filter((i) => this.itemRenderer(i).match(regExp));
  }

  override selectAll(): void {
    if (!this.multiSelect) {
      return;
    }

    this.selection = this.filteredOptions;
    this.indeterminate = [];
  }

  override clearAll(): void {
    if (!this.multiSelect) {
      return;
    }
    super.clearAll();
    this.indeterminate = [];
  }

  ngOnInit(): void {
    this.filterAndUpdateUI(this.search);
  }

  isDisabled(item: T): boolean {
    return this.disabledItems.includes(item);
  }

  isIndeterminate(item: T): boolean {
    return this.indeterminate.includes(item);
  }

  override selectItem(item: T): void {
    if (this.multiSelect) {
      super.selectItem(item);
      this.indeterminate = Array.from(
        new Set(this.indeterminate.filter((n) => n !== item)),
      );
    } else {
      this.selection = [item];
    }
  }

  override unselectItem(item: T): void {
    if (this.multiSelect) {
      super.unselectItem(item);
      this.indeterminate = this.indeterminate.filter((n) => n !== item);
    } else {
      this.selection = [];
    }
  }

  selectIndeterminate(item: T): void {
    this.unselectItem(item);
    this.indeterminate = Array.from(new Set([...this.indeterminate, item]));
  }

  override toggleItem(item: T): void {
    if (this.isDisabled(item)) {
      return;
    }
    if (this.enableIndeterminate) {
      if (this.isSelected(item)) {
        this.selectIndeterminate(item);
      } else if (this.isIndeterminate(item)) {
        this.unselectItem(item);
      } else {
        this.selectItem(item);
      }
    } else {
      if (this.isSelected(item)) {
        this.unselectItem(item);
      } else {
        this.selectItem(item);
      }
    }

    if (this.closeOnSelect && !this.multiSelect) {
      this.close();
    }
  }

  writeValue(obj: unknown): void {
    if (Array.isArray(obj)) {
      this.selection = obj as T[];
    } else if (isNil(obj)) {
      this.selection = [];
    } else {
      console.warn(
        `Failed to bind type ${typeof obj} on form control ${
          this.ngControl?.name
        }`,
      );
    }
  }

  registerOnChange(fn: (value: T[]) => void): void {
    this.disposer.addStreamSubscription(
      this.selectionChange.subscribe({ next: fn }),
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any
  registerOnTouched(fn: () => void): void {
    this.disposer.addStreamSubscription(this.touched$.subscribe(() => fn()));
  }

  onVisibleChange($event: boolean): void {
    if (this.popupVisible) {
      this.touched$.emit();
    }
    this.popupVisibleChange.next($event);
  }
}

@Component({
  template: '',
})
export abstract class LeftySearchAndSelectWrapperComponent<
  T,
> extends LeftyFormValueBase<T[]> {
  constructor(@Optional() ngControl?: NgControl) {
    super([], ngControl);
  }

  abstract readonly options: Signal<T[]>;

  @Input()
  canSelectAll = true;

  @Input()
  autoDismiss = true;

  @Input()
  popupPlacement: Placement = 'bottom-start';

  @Output()
  readonly popupVisibleChange = createOutput<boolean>();

  @Input()
  selection: T[] = [];

  @Output()
  readonly selectionChange = createOutput<T[]>();

  @Input()
  disabledItems: T[] = [];

  @Input()
  buttonType: ButtonType = 'secondary';

  @Input()
  buttonSize: ButtonSize = 'medium';

  /// Message when filtering function return empty list
  @Input()
  notFoundMessage = Messages.notFound;

  @Input()
  componentFactory?: ComponentFactory<T>;

  @Input()
  itemRenderer: ItemRenderer<T> = AngularUtils.defaultItemRenderer;

  @Input()
  enableIndeterminate = false;

  // by default, the user must start typing in search box
  // do display search results
  // If allowEmptySearch is true, we will display results event if search box is empty
  @Input()
  allowEmptySearch = false;

  @Output()
  readonly indeterminateChange = createOutput<T[]>();

  writeValue(obj: unknown): void {
    if (Array.isArray(obj)) {
      this.selection = obj as T[];
    } else if (isNil(obj)) {
      this.selection = [];
    } else {
      console.warn(
        `Failed to bind type ${typeof obj} on form control ${
          this.ngControl?.name
        }`,
      );
    }
  }
}
