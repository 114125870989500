<div
  class="container"
  [class.visible]="filtersVisible"
  [style.height.px]="filtersHeight"
>
  <div
    class="filters"
    #filtersGrid
  >
    <ng-content></ng-content>

    <span
      *ngIf="filtersCount > 0"
      class="clear"
    >
      <button
        class="plain dark"
        (click)="clear$.next($event)"
        i18n
      >
        Clear all filters
      </button>
    </span>
  </div>
</div>
