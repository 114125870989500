import {
  Component,
  ChangeDetectionStrategy,
  Input,
  HostBinding,
} from '@angular/core';

// mostly add padding-bottom: 120px.
// should be use with lefty-section and lefty-section-header
@Component({
  selector: 'lefty-page',
  template: '<ng-content></ng-content>',
  styleUrls: ['./lefty-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class LeftyPageComponent {
  // will be propagate to children (lefty-section, lefty-section-header)
  @Input()
  loading = false;

  // will be propagate to children (lefty-section, lefty-section-header)
  @Input()
  mobile = false;

  @Input()
  @HostBinding('class.no-padding-bottom')
  noPaddingBottom = false;
}
