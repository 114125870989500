import {
  ChangeDetectionStrategy,
  Component,
  Input,
  computed,
  inject,
} from '@angular/core';
import { LeftyLayoutObserverService } from '../layout-observer.service';
import {
  RouteCommand,
  LeftyRouterLinkDirective,
  LeftyRouterLinkActiveDirective,
} from '../router-utils';
import { LeftyBrandIconComponent } from '../icon/icon.component';
import { NgIf } from '@angular/common';

@Component({
  selector: 'lefty-layout',
  templateUrl: './lefty-layout.component.html',
  styleUrls: ['./lefty-layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgIf,
    LeftyRouterLinkDirective,
    LeftyRouterLinkActiveDirective,
    LeftyBrandIconComponent,
  ],
})
export class LeftyLayoutComponent {
  readonly layoutObserver = inject(LeftyLayoutObserverService);
  readonly isCompactLayout = computed(
    () => this.layoutObserver.isLargeHeight() === false,
  );

  @Input()
  homeLink: string | RouteCommand[] = '';

  @Input()
  hasHomeFeature = true;

  @Input()
  leftSectionVisible = true;
}
