import {
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
  model,
} from '@angular/core';
import {
  getAllFollowersCount,
  isGhostCreatorPoolResponse,
  LeftyFormat,
} from '@frontend2/core';
import { CreatorPoolResponse } from '@frontend2/proto/librarian/proto/pool_pb';
import { NetworksSlicedIconsComponent } from '../../../icon/networks-sliced-icons.component';
import { ProfilePictureComponent } from '../../../image/profile-pic.component';
import { LeftyCheckboxComponent } from '../../../checkbox/checkbox.component';

@Component({
  selector: 'select-from-directory-influencer-row-item',
  templateUrl: 'influencer-row-item.component.html',
  styleUrls: ['./influencer-row-item.component.scss'],
  providers: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    LeftyCheckboxComponent,
    ProfilePictureComponent,
    NetworksSlicedIconsComponent,
  ],
})
export class SelectFromDirectoryInfluencerRowItemComponent {
  readonly maxNetworksToShow = 2;

  readonly disabled = input(false);
  readonly checked = model(false);
  readonly influencer = input(new CreatorPoolResponse());

  readonly isGhost = computed(() =>
    isGhostCreatorPoolResponse(this.influencer()),
  );

  readonly username = computed(
    () => this.influencer().baseSnippet?.userName ?? '',
  );

  readonly networks = computed(() =>
    this.influencer().networkInfo.map((i) => i.network),
  );

  readonly followers = computed(() => {
    const count = getAllFollowersCount(this.influencer().networkInfo);
    return LeftyFormat.followers(count, { compact: true });
  });

  toggleChecked(): void {
    if (this.disabled()) {
      return;
    }
    this.checked.update((v) => !v);
  }

  checkboxClicked(e: MouseEvent): void {
    e.stopPropagation();
    e.preventDefault();
  }
}
