import {
  ChangeDetectionStrategy,
  Component,
  Input,
  Output,
} from '@angular/core';
import { Subject } from 'rxjs';
import {
  NavItem,
  LeftyRouterLinkDirective,
  LeftyRouterLinkActiveDirective,
} from '../router-utils';
import { LeftyComponent } from '../utils';
import { NgIf } from '@angular/common';
import { LeftyIconComponent } from '../icon/icon.component';

@Component({
  selector: 'navigation-submenu-group',
  templateUrl: 'submenu.component.html',
  styleUrls: ['menu_group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    LeftyIconComponent,
    NgIf,
    LeftyRouterLinkDirective,
    LeftyRouterLinkActiveDirective,
  ],
})
export class NavigationSubMenuGroupComponent extends LeftyComponent {
  constructor() {
    super();

    this.disposer.add(this.isOpenChange);
  }

  @Input()
  isOpen = false;

  @Output()
  readonly isOpenChange = new Subject<boolean>();

  @Input()
  label = '';

  @Input()
  navigations: NavItem[] = [];

  toggle(): void {
    this.setState(() => (this.isOpen = !this.isOpen));
    this.isOpenChange.next(this.isOpen);
  }
}
