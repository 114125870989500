<div class="flex row list-header">
  <h3>{{ workspaces }}</h3>
  <div class="fill-space"></div>
  <div class="flex row action">
    <button
      class="primary"
      (click)="createOrEditWorkspace(user)"
    >
      Create workspace
    </button>
  </div>
</div>
@for (user of users; track user.teamId) {
  @if (userHasTeamId(user)) {
    <a
      [class.ghost]="userIsGhost(user)"
      [routerLink]="teamLink(user)"
    >
      <workspace-list-item
        *ngIf="!userIsGhost(user)"
        [user]="user"
        (subscriptionToEdit$)="editSubscription(user)"
        (workspaceToEdit$)="createOrEditWorkspace(user)"
      >
      </workspace-list-item>
    </a>
  } @else {
    <div [class.ghost]="userIsGhost(user)">
      <workspace-list-item
        *ngIf="!userIsGhost(user)"
        [user]="user"
      >
      </workspace-list-item>
    </div>
  }
}
