import {
  AfterViewInit,
  Directive,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Subject } from 'rxjs';
import { isNotNil } from '@frontend2/core';

@Directive({
  selector: '[autoDismissable]:not(lefty-dialog)',
  standalone: true,
})
export class AutoDismissDirective
  implements OnDestroy, OnChanges, AfterViewInit
{
  private readonly ref: ElementRef;

  constructor(ref: ElementRef) {
    this.ref = ref;
  }

  private _initialized = false;

  get isInitialized(): boolean {
    return this._initialized;
  }

  // Function to validate a node
  // if node is valid, it won't trigger autoDismiss event
  @Input()
  autoDismissNodeValidator?: (node: Node) => boolean;

  /// Boolean indicating if the dismiss event be published.
  @Input()
  autoDismissable = false;

  /// Event which is published when a focus, mouseup, or click occurs outside of
  /// the element.
  @Output()
  readonly dismiss$ = new Subject<Event | null>();

  ngOnDestroy(): void {
    this.dismiss$.complete();
  }

  private _isInside(elementToCheck: HTMLElement): boolean {
    return (
      elementToCheck === this.ref.nativeElement ||
      this.ref.nativeElement.contains(elementToCheck)
    );
  }

  private _handleClickOutside = (event: Event): void => {
    const node = event.target as HTMLElement;

    // ignore FocusEvent
    // LeftyPopup may be used inside an Anchor Element
    // and there is an issue on Firefox when you click inside popup
    // it trigger focus event on the link, causing popup to close.
    //
    // Hopefully we do not really care about focus event here,
    // we just want the popup to close on Click event
    if (event instanceof FocusEvent) {
      return;
    }

    if (isNotNil(this.autoDismissNodeValidator)) {
      const validator = this.autoDismissNodeValidator;

      if (validator(event.target as Node)) {
        return;
      }
    }

    if (this._isInside(node) === false) {
      this.dismiss$.next(event);
    }
  };

  unsubscribeDocumentClick(): void {
    document.body.removeEventListener('click', this._handleClickOutside);
  }

  subscribeDocumentClick(): void {
    this.unsubscribeDocumentClick();

    if (this.autoDismissable) {
      document.body.addEventListener('click', this._handleClickOutside);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      (changes['autoDismissable'] || changes['autoDismissNodeValidator']) &&
      this.isInitialized
    ) {
      this.subscribeDocumentClick();
    }
  }

  ngAfterViewInit(): void {
    this._initialized = true;
    this.subscribeDocumentClick();
  }
}
