<button
  #popupTrigger
  leftyButton="secondary"
  leadingIcon="filters"
  i18n
  [size]="buttonSize"
  class="filters-button"
>
  Filters
  @if (filtersCount() > 0) {
    <span class="count"> ({{ filtersCount() }}) </span>
  }
</button>
<lefty-popup
  [popupTrigger]="popupTrigger"
  (visibleChange)="collapse()"
>
  <lefty-list>
    @if (hasExpandedCategory()) {
      <lefty-list-item
        [closeOnActivate]="false"
        class="back-category"
        (click)="collapse()"
      >
        <lefty-icon
          icon="arrow_right"
          size="medium"
          class="back-icon arrow-icon"
        ></lefty-icon>
        <span class="fill-space category-name">
          {{ getCategoryName(currentExpandedCategory()) }}
        </span>
      </lefty-list-item>

      @if (!hideHeader()) {
        <div class="header">
          <div>
            <span
              class="selector"
              (click)="selectAll()"
              [class.active]="!allSelected()"
              i18n
            >
              Select all
            </span>
            -

            <span
              class="selector"
              (click)="clearAll()"
              [class.active]="selectionIsNotEmpty()"
              i18n
            >
              Clear all
            </span>
          </div>

          <lefty-form-input
            leadingGlyph="search"
            [(value)]="searchValue"
            [placeholder]="searchPlaceholder()"
          >
          </lefty-form-input>
        </div>
      }

      @if (expandedCategoryIsCampaigns()) {
        @for (
          campaign of filteredAvailableCampaigns() | keyvalue;
          track $index
        ) {
          <lefty-list-item
            class="category-items"
            [closeOnActivate]="false"
            (click)="toggleCampaign(campaign.value)"
          >
            <lefty-checkbox
              [checked]="campaignIsSelected(campaign.key)"
            ></lefty-checkbox>

            <span class="fill-space category-name">{{
              campaign.value.name
            }}</span>
          </lefty-list-item>
        } @empty {
          <p
            i18n
            class="not-found"
          >
            No results
          </p>
        }
      } @else if (expandedCategoryIsLabels()) {
        @for (
          label of filteredAvailableInfluencerLabels() | keyvalue;
          track $index
        ) {
          <lefty-list-item
            class="category-items"
            [closeOnActivate]="false"
            (click)="toggleLabel(label.value)"
          >
            <lefty-checkbox
              [checked]="labelIsSelected(label.key)"
            ></lefty-checkbox>

            <span class="fill-space category-name">{{ label.value.name }}</span>
          </lefty-list-item>
        } @empty {
          <p
            i18n
            class="not-found"
          >
            No results
          </p>
        }
      }
    } @else {
      @for (category of filtersCategories(); track $index) {
        <lefty-list-item
          [closeOnActivate]="false"
          class="category-items"
          (click)="currentExpandedCategory.set(category)"
        >
          <span class="fill-space category-name">
            {{ getCategoryName(category) }}
          </span>
          <lefty-icon
            icon="arrow_right"
            size="medium"
            class="arrow-icon"
          ></lefty-icon>
        </lefty-list-item>
      }
    }
  </lefty-list>
</lefty-popup>
