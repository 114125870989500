import { Inject, Injectable } from '@angular/core';
import { Int64Value } from '@bufbuild/protobuf';
import {
  LibrarianAdminClient,
  LibrarianAdminClientProvider,
} from '@frontend2/api';
import { buildDefaultRouteState, RouteParams } from '@frontend2/core';
import {
  Account,
  GetUserAccountResp,
} from '@frontend2/proto/librarian/proto/admin_pb';
import { RouteBloc } from '@frontend2/ui';
import { CUSTOMER_ID_KEY } from '../../constants';

@Injectable()
export class CustomerBloc extends RouteBloc<Int64Value, GetUserAccountResp> {
  constructor(
    @Inject(LibrarianAdminClientProvider)
    private librarian: LibrarianAdminClient,
  ) {
    super({
      initialState: buildDefaultRouteState(
        new Int64Value(),
        new GetUserAccountResp(),
      ),
    });
  }

  protected async build(req: Int64Value): Promise<GetUserAccountResp> {
    return this.librarian.getUserAccountAPI(req);
  }

  override parseRouteParams(routeParams: RouteParams): Int64Value {
    if (routeParams.parameters[CUSTOMER_ID_KEY] === '') {
      return new Int64Value();
    }
    const req = new Int64Value();
    req.value = BigInt(routeParams.parameters[CUSTOMER_ID_KEY]);
    return req;
  }

  async _handleUpdateCustomer(customer: Account): Promise<void> {
    this.setViewState(
      new GetUserAccountResp({ ...this.viewState(), account: customer }),
    );
  }
}
