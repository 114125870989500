import { SetSourceType } from './create-set.models';

export function readableSetSourceType(source: SetSourceType): string {
  switch (source) {
    case SetSourceType.lefty:
      return 'Lefty';
    case SetSourceType.arkansas:
      return 'Auxiliary';
    case SetSourceType.discover:
      return 'Discover';
    case SetSourceType.directory:
      return 'Directory';
    case SetSourceType.manual:
      return 'Manual';
    default:
      return 'Unknown';
  }
}
