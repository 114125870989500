import { Injectable, Inject } from '@angular/core';
import { Empty, Int64Value, StringValue } from '@bufbuild/protobuf';
import {
  LibrarianAdminClientProvider,
  LibrarianAdminClient,
} from '@frontend2/api';
import {
  AutocompleteCustomersResponse,
  CreateTeamReq,
  EditCalculatorMethodReq,
  TeamDetails,
  UpdateTeamReq,
} from '@frontend2/proto/librarian/proto/admin_pb';

@Injectable({ providedIn: 'root' })
export class CreateOrEditWorkspaceService {
  constructor(
    @Inject(LibrarianAdminClientProvider)
    private librarian: LibrarianAdminClient,
  ) {}

  async create(req: CreateTeamReq): Promise<TeamDetails> {
    return await this.librarian.createTeamAPI(req);
  }

  async edit(req: UpdateTeamReq): Promise<Empty> {
    return await this.librarian.updateTeamAPI(req);
  }

  async updateMethodCalculator(req: EditCalculatorMethodReq): Promise<Empty> {
    const calculator = await this.librarian.editCalculatorMethodAPI(req);
    return calculator;
  }

  async getTeamDetailsById(id: bigint): Promise<TeamDetails> {
    return await this.librarian.getTeamByIdAPI(new Int64Value({ value: id }));
  }

  async getAutoCompleteOptions(
    searchText: string,
  ): Promise<AutocompleteCustomersResponse> {
    return await this.librarian.autocompleteCustomersAPIV2(
      new StringValue({
        value: searchText,
      }),
    );
  }
}
