<div class="main">
  <div class="content">
    <ng-content></ng-content>
  </div>
  <div class="actions">
    <ng-content select="[action]"></ng-content>
  </div>
</div>
<div class="bottom">
  <ng-content select="[bottom]"></ng-content>
</div>
