<lefty-form-autocomplete
  [options]="options()"
  [(inputText)]="searchValue"
  [loading]="loading()"
  name="team"
  [label]="label()"
  [noFiltering]="true"
  [itemRenderer]="teamRenderer"
  [value]="value()"
  (valueChange)="setValueAndNotify($event)"
  class="fill-space"
  [placeholder]="placeholder()"
></lefty-form-autocomplete>
@if (workspacesLoading()) {
  <lefty-spinner></lefty-spinner>
}
