<button
  type="button"
  #popupTrigger
  [ngClass]="buttonClass"
  [class.active]="visible"
>
  <lefty-icon
    class="menu-icon"
    [icon]="icon"
  >
  </lefty-icon>
</button>

<lefty-popup
  [placement]="popupPlacement"
  [(visible)]="visible"
  [popupTrigger]="popupTrigger"
>
  <lefty-list (click)="close()">
    <ng-content></ng-content>
  </lefty-list>
</lefty-popup>
