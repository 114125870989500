import { DatePipe, NgClass, NgIf, NgFor } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  model,
  NgZone,
  Optional,
  Output,
  Self,
  ViewChild,
} from '@angular/core';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { NgControl } from '@angular/forms';
import {
  formatDate,
  isAfterDate,
  isBeforeDate,
  isEmptyArray,
  isNil,
  isNotNil,
} from '@frontend2/core';
import { TimeRestriction } from '@frontend2/proto/librarian/proto/common_pb';
import { Placement } from '@popperjs/core';
import AirDatepicker from 'air-datepicker';
import { filter, pairwise } from 'rxjs';
import {
  datesToTimeRestriction,
  loadAirDatepickerLocale,
  predefinedDates,
  preventAutoDismissForNode,
  TimeRestrictionFormBase,
  timeRestrictionToDates,
} from './lefty-date-picker.helpers';
import { DatePickerRange } from './lefty-date-picker.models';
import { LeftyListItemComponent } from '../lefty-list/lefty-list-item.component';
import { LeftyListComponent } from '../lefty-list/lefty-list.component';
import { LeftyPopupComponent } from '../lefty-popup/lefty-popup.component';
import { LeftyIconComponent } from '../icon/icon.component';
import { LeftyFormComponent } from '../lefty-form/lefty-form.component';

@Component({
  selector: 'lefty-date-range-picker',
  templateUrl: './lefty-date-range-picker.component.html',
  styleUrls: ['./shared.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DatePipe],
  standalone: true,
  imports: [
    LeftyFormComponent,
    NgClass,
    NgIf,
    LeftyIconComponent,
    LeftyPopupComponent,
    LeftyListComponent,
    NgFor,
    LeftyListItemComponent,
  ],
})
export class LeftyDateRangePickerComponent
  extends TimeRestrictionFormBase
  implements AfterViewInit
{
  constructor(
    readonly zone: NgZone,
    @Self() @Optional() ngControl?: NgControl,
  ) {
    super(ngControl);

    this.watch(this.valueChange, {
      next: (val) => this._handleExternalChange(val),
    });

    this.popupClose$.pipe(takeUntilDestroyed()).subscribe(() => {
      if (this.emitChangesOnClose) {
        this._notifyChange(this.value);
      }
    });
  }

  private datepicker?: AirDatepicker;

  readonly autoDismissNodeValidator = preventAutoDismissForNode;

  @Input()
  emitChangesOnClose = false;

  @Input()
  popupPlacement: Placement = 'bottom-start';

  @ViewChild('calendar')
  calendarElementRef?: ElementRef;

  @Input()
  presets = predefinedDates;

  isPresetDisabled(range: DatePickerRange): boolean {
    const max = this.maxDate;
    const min = this.minDate;

    // Check if the start of the range is before the maximum date
    const isStartBeforeMax = isNotNil(max) && isBeforeDate(max, range.start);

    // Check if the end of the range is after the minimum date
    const isEndAfterMin = isNotNil(min) && isAfterDate(min, range.end);

    // Return true if either of the conditions is true
    return isStartBeforeMax || isEndAfterMin;
  }

  @Input()
  placeholder = $localize`Select date`;

  override get minDate(): Date | undefined {
    return super.minDate;
  }

  @Input()
  override set minDate(value: Date | undefined) {
    super.minDate = value;
    this.zone.runOutsideAngular(() =>
      this.datepicker?.update({ minDate: this.minDate }, { silent: true }),
    );
  }

  override get maxDate(): Date | undefined {
    return super.maxDate;
  }

  @Input()
  override set maxDate(value: Date | undefined) {
    super.maxDate = value;

    this.zone.runOutsideAngular(() =>
      this.datepicker?.update({ maxDate: this.maxDate }, { silent: true }),
    );
  }

  private _getSelectedPreset(): DatePickerRange | undefined {
    return this.presets.find((preset) => this.isPresetSelected(preset));
  }

  get readableRange(): string {
    if (isNil(this.value)) {
      return '';
    }

    const selectedPreset = this._getSelectedPreset();
    if (isNotNil(selectedPreset)) {
      return selectedPreset.label;
    }

    return timeRestrictionToDates(this.value)
      .map((date) => formatDate(date, this.dateFormat))
      .join(' - ');
  }

  private initDatepicker(): void {
    this.zone.runOutsideAngular(async () => {
      const locale = await loadAirDatepickerLocale();

      this.datepicker = new AirDatepicker(
        this.calendarElementRef?.nativeElement,
        {
          selectedDates: isNotNil(this.value)
            ? timeRestrictionToDates(this.value)
            : [],
          inline: true,
          range: true,
          minDate: this.minDate,
          maxDate: this.maxDate,
          locale: locale,
          navTitles: {
            days: 'MMMM, yyyy',
          },
          onSelect: ({ date }): void => {
            const dates = date as Date[];

            // we don't want to submit only 1 Date
            // we should always submit a range of dates (not just start date)
            if (dates.length === 2) {
              this.zone.run(() => this._setRangeAndMaybeNotify(dates));
            }
          },
        },
      );
    });
  }

  ngAfterViewInit(): void {
    this.initDatepicker();
  }

  selectPreset(preset: DatePickerRange): void {
    this._setRangeAndMaybeNotify([preset.start, preset.end]);
  }

  private _setRangeAndMaybeNotify(val: Date[]): void {
    const newRange = datesToTimeRestriction(val);

    if (newRange.equals(this.value)) {
      return;
    }

    this.value = newRange;
    if (this.emitChangesOnClose === false) {
      this._notifyChange(newRange);
    }
  }

  private _notifyChange(range: TimeRestriction): void {
    this.valueChange.emit(range);
  }

  isPresetSelected(preset: DatePickerRange): boolean {
    return (
      preset.start.getTime() === this.value?.start?.toDate()?.getTime() &&
      preset.end.getTime() === this.value?.end?.toDate()?.getTime()
    );
  }

  private _handleExternalChange(range: TimeRestriction): void {
    this.zone.runOutsideAngular(() => {
      if (!this.datepicker) {
        return;
      }

      const currentRange = datesToTimeRestriction(
        this.datepicker.selectedDates,
      );

      if (range.equals(currentRange) === false) {
        const newDates = timeRestrictionToDates(range);

        // silent: true won't trigger `onSelect` callback
        if (isEmptyArray(newDates)) {
          this.datepicker.clear({ silent: true });
        } else {
          this.datepicker.selectDate(newDates, { silent: true });
        }
      }
    });
  }

  onArrowIconClick(event: Event): void {
    if (this.disabled) {
      event.preventDefault();
      event.stopPropagation();
    }
  }

  readonly popupVisible = model(false);

  @Output()
  readonly popupOpen$ = toObservable(this.popupVisible).pipe(
    pairwise(),
    filter(([prev, curr]) => prev === false && curr === true),
  );

  @Output()
  readonly popupClose$ = toObservable(this.popupVisible).pipe(
    pairwise(),
    filter(([prev, curr]) => prev === true && curr === false),
  );
}
