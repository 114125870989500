import {
  WorkspacesListRequest,
  WorkspacesSort,
  WorskpacesListState,
} from './workspaces.models';

export function createWorkspacesListRequest(
  partialRequest?: Partial<WorkspacesListRequest>,
): WorkspacesListRequest {
  const req: WorkspacesListRequest = {
    name: partialRequest?.name || '',
    sortBy: partialRequest?.sortBy || WorkspacesSort.BY_MEMBERS,
    network: partialRequest?.network || [],
    subscription: partialRequest?.subscription || [],
  };
  return req;
}

export function createWorkspacesListState(
  partialRequest?: Partial<WorskpacesListState>,
): WorskpacesListState {
  const state: WorskpacesListState = {
    teams: partialRequest?.teams || [],
    totalHits: partialRequest?.totalHits || BigInt(0),
  };
  return state;
}
