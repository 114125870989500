export interface TimeValueSelection {
  readonly hour: number;
  readonly minute: number;
}

export function createTimeValueSelection(
  partial?: Partial<TimeValueSelection>,
): TimeValueSelection {
  return {
    hour: partial?.hour ?? 0,
    minute: partial?.minute ?? 0,
  };
}
