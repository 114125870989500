import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  FormsModule,
  NgControl,
  ReactiveFormsModule,
} from '@angular/forms';
import {
  LibrarianCWAdminClient,
  LibrarianCWAdminClientProvider,
} from '@frontend2/api';
import {
  escapeRegExp,
  isEmptyString,
  isGhostTeam,
  isNotEmptyString,
  isNotNil,
} from '@frontend2/core';
import {
  DirectorySourceRequest,
  SourceRequest,
} from '@frontend2/proto/librarian/proto/competitive_watch_pb';
import { SourceRequestForm } from '../source-forms';

import { TeamsResponse_TeamLight } from '@frontend2/proto/librarian/proto/admin_pb';
import { LeftyFormAutocompleteComponent } from '@frontend2/ui';
import { Subject, debounceTime } from 'rxjs';
import { WorkspacesCache } from '../../../workspaces.cache';

@Component({
  selector: 'directory-source-form',
  templateUrl: 'directory-source-form.component.html',
  styleUrls: ['./../source-forms.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, LeftyFormAutocompleteComponent],
})
export class CwDirectorySourceFormComponent extends SourceRequestForm {
  constructor(
    ngControl: NgControl,
    @Inject(LibrarianCWAdminClientProvider)
    protected librarian: LibrarianCWAdminClient,
    private workspacesCache: WorkspacesCache,
  ) {
    super(ngControl);

    this.formModel.valueChanges.subscribe(() =>
      this.handleValueChange(this._buildSourceRequest(this.formModel)),
    );

    this.searchValue$
      .pipe(debounceTime(300))
      .subscribe((val) => this.searchTeam(val));

    this.disposer.add(this.searchValue$);
  }

  override formModel = new FormGroup(
    {
      client: new FormControl<TeamsResponse_TeamLight | null>(null),
    },
    this._inputsValidator,
  );

  searchValue$ = new Subject<string>();
  teamOptions: TeamsResponse_TeamLight[] = [];
  cacheLoading = false;

  async searchTeam(teamText: string): Promise<void> {
    this.setState(() => (this.cacheLoading = true));
    const res = await this.workspacesCache.load();
    this.setState(() => (this.cacheLoading = false));
    const teams = res.teams;
    const query = escapeRegExp(teamText.trim());
    if (isEmptyString(query)) {
      this.teamOptions = teams;
    } else {
      const regexp = new RegExp(query, 'i');

      this.teamOptions = teams.filter(
        (t: TeamsResponse_TeamLight) =>
          regexp.test(t.name) ||
          regexp.test(t.owner?.email ?? '') ||
          regexp.test(t.owner?.userId?.toString() ?? '') ||
          regexp.test(t.owner?.userAccountId?.toString() ?? ''),
      );
    }

    this.changeDetection.markForCheck();
  }

  private _inputsValidator(
    control: AbstractControl,
  ): { [key: string]: boolean } | null {
    const client = control.get('client')?.value as TeamsResponse_TeamLight;
    if (isNotNil(client?.owner)) {
      return null;
    }
    return { required: true };
  }

  private _buildSourceRequest(controls: FormGroup): SourceRequest | undefined {
    if (this.formModel.invalid) {
      return undefined;
    }
    const request = new DirectorySourceRequest();
    const client = controls.get('client')?.value as TeamsResponse_TeamLight;
    if (isNotNil(client.owner)) {
      request.userId = client.owner.userId;
    }
    return new SourceRequest({
      request: { value: request, case: 'directory' },
    });
  }

  teamRenderer(team: TeamsResponse_TeamLight): string {
    if (isGhostTeam(team)) {
      return '';
    }
    if (isNotEmptyString(team.name)) {
      return `${team.name} (${team.owner?.email})`;
    }
    return `Team of ${team.owner?.email}`;
  }
}
