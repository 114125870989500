import { NgIf } from '@angular/common';
import { Component } from '@angular/core';
import { FormsModule, NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { Code, ConnectError } from '@connectrpc/connect';
import { LeftyAuthBloc } from '@frontend2/api';
import {
  LEFTY_LEGAL_URL,
  LEFTY_LOGO,
  LEFTY_PRIVACY_POLICY_URL,
} from '@frontend2/core';
import { LoginForm } from '@frontend2/proto/librarian/proto/frontend_misc_pb';
import {
  LeftyComponent,
  LeftyFeedbackComponent,
  LeftyFormInputComponent,
  LeftySpinnerComponent,
} from '@frontend2/ui';

@Component({
  selector: 'flander-login-route',
  templateUrl: 'login.route.html',
  styleUrls: ['./login.route.scss'],
  standalone: true,
  imports: [
    FormsModule,
    NgIf,
    LeftyFeedbackComponent,
    LeftyFormInputComponent,
    LeftySpinnerComponent,
  ],
})
export class FlandersLoginRouteComponent extends LeftyComponent {
  constructor(
    private _router: Router,
    private _auth: LeftyAuthBloc,
  ) {
    super();
  }

  loading = false;
  showError = false;
  errorMessage = '';
  year = new Date().getFullYear();

  async submit(form: NgForm): Promise<void> {
    if (form.invalid) {
      return;
    }

    this.setState(() => (this.loading = true));

    try {
      const boot = await this._auth.login(
        new LoginForm({
          email: form.value.email,
          password: form.value.password,
        }),
      );

      if (boot.isAdmin) {
        this._router.navigate(['/'], { replaceUrl: true });
      } else {
        this.errorMessage = $localize`Failed to login`;
        this.showError = true;
      }
    } catch (e: unknown) {
      if (e instanceof ConnectError) {
        if (e.code === Code.Unauthenticated) {
          this.errorMessage = $localize`The email address or password you entered is incorrect.`;
          this.showError = true;
        } else {
          this.errorMessage = $localize`Failed to login`;
          this.showError = true;
        }
      }
    } finally {
      this.setState(() => (this.loading = false));
    }
  }

  get leftyLogoSrc(): string {
    return LEFTY_LOGO;
  }

  get privacyPolicyUrl(): string {
    return LEFTY_PRIVACY_POLICY_URL;
  }

  get legalUrl(): string {
    return LEFTY_LEGAL_URL;
  }
}
