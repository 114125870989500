import {
  ChangeDetectionStrategy,
  Component,
  computed,
  inject,
  output,
  signal,
} from '@angular/core';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import {
  Influencer,
  createInfluencerAutocompleteResponse,
  isGhostCreator,
  isNotNil,
} from '@frontend2/core';
import { Network } from '@frontend2/proto/common/proto/common_pb';
import { CreatorTiniestCard } from '@frontend2/proto/librarian/proto/creators_pb';
import { NetworkConfigs } from '../../config/config.service';
import { CreatorAutocompleteMatch } from '../../influencer-autocomplete/influencer-autocomplete-base';
import {
  InfluencerWithNetworkInfoAutocomplete,
  createInfluencerWithNetworkInfoAutocomplete,
  getCreatorAutocompleteMatchFollowerCountForNetwork,
} from '../../influencer-autocomplete/influencer-autocomplete.helpers';
import { DialogBase } from '../../lefty-dialog/lefty-dialog.helpers';
import { LeftyButtonDirective } from '../../lefty-button-directive/lefty-button.directive';
import { NetworkIconComponent } from '../../icon/network.component';
import { ProfilePictureComponent } from '../../image/profile-pic.component';
import { LeftyCardComponent } from '../../lefty-card/lefty-card.component';
import { LeftyInfluencerSearchComponent } from '../../influencer-autocomplete/creator-search-with-network/creator-search-with-network.component';
import { LeftyDialogComponent } from '../../lefty-dialog/lefty-dialog.component';

@Component({
  selector: 'search-creator-method-dialog',
  templateUrl: 'search-creator-method-dialog.component.html',
  styleUrls: ['./search-creator-method-dialog.component.scss'],
  providers: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    LeftyDialogComponent,
    LeftyInfluencerSearchComponent,
    LeftyCardComponent,
    ProfilePictureComponent,
    NetworkIconComponent,
    LeftyButtonDirective,
  ],
})
export class SearchCreatorMethodDialogComponent extends DialogBase {
  constructor() {
    super();
    toObservable(this.influencerAutocompleteValue)
      .pipe(takeUntilDestroyed())
      .subscribe((val) => {
        const influencer = val.influencer;
        if (
          isNotNil(influencer.creator) &&
          !isGhostCreator(influencer.creator)
        ) {
          this.selection.set([influencer, ...this.selection()]);
          // setTimeout for reset autocomplete because when we select it should first compelet the input, then we should reset it
          setTimeout(() => {
            this.influencerAutocompleteValue.set(
              createInfluencerWithNetworkInfoAutocomplete({}),
            );
          });
        }
      });
  }

  readonly defaultNetwork = Network.INSTA;

  readonly network = signal(this.defaultNetwork);

  readonly networkConfigs = inject(NetworkConfigs);

  readonly saved$ = output<Influencer[]>();

  readonly back$ = output<void>();

  readonly influencerAutocompleteValue =
    signal<InfluencerWithNetworkInfoAutocomplete>(
      createInfluencerWithNetworkInfoAutocomplete({}),
    );

  readonly selection = signal<CreatorAutocompleteMatch[]>([]);

  readonly selectionIds = computed(() =>
    this.selection()
      .map((influencer) => influencer.creator?.userId)
      .filter(isNotNil),
  );

  getCreator(match: CreatorAutocompleteMatch): CreatorTiniestCard {
    return match.creator ?? new CreatorTiniestCard();
  }

  getFollowerCountForNetwork(match: CreatorAutocompleteMatch): string {
    const config = this.networkConfigs.of(this.network());
    return getCreatorAutocompleteMatchFollowerCountForNetwork(
      createInfluencerWithNetworkInfoAutocomplete({
        influencer: match,
        selectedNetwork: config.network,
      }),
      config.supportFollowers,
    );
  }

  getInfluencerNetworks(influencer: CreatorAutocompleteMatch): Network[] {
    return influencer.networkInfo
      .map((n) => n.network)
      .filter((n) => n !== Network.NETWORK_UNKNOWN);
  }

  deleteAtIndex(index: number): void {
    const newSelection = [...this.selection()];
    newSelection.splice(index, 1);
    this.selection.set(newSelection);
  }

  resetState(): void {
    this.selection.set([]);
    this.network.set(this.defaultNetwork);
    this.influencerAutocompleteValue.set(
      createInfluencerWithNetworkInfoAutocomplete({}),
    );
  }

  save(): void {
    const influencers: Influencer[] = this.selection().map((s) =>
      createInfluencerAutocompleteResponse(s),
    );

    this.saved$.emit(influencers);
    this.close();
  }

  back(): void {
    this.close();
    this.back$.emit();
  }

  override close(): void {
    this.resetState();
    super.close();
  }
}
