import {
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
} from '@angular/core';
import { isNil, isNotNil, MINUTES_IN_A_DAY } from '@frontend2/core';
import { LeftyControlValueAccessor } from '../../form';
import { LeftyFormSelectComponent } from '../../lefty-form-select/lefty-form-select.component';
import { isDisabledCheck } from '../../lefty-form-select/utils';
import { TimeValueSelection } from './time-picker.helpers';

@Component({
  selector: 'lefty-time-picker',
  templateUrl: 'lefty-time-picker.component.html',
  styleUrls: ['lefty-time-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [LeftyFormSelectComponent],
})
export class LeftyTimePickerComponent extends LeftyControlValueAccessor<
  TimeValueSelection | undefined
> {
  readonly defaultStep = 60;

  readonly steps = input(this.defaultStep, {
    transform: (v: number) => (v < 1 ? this.defaultStep : v),
  });

  override placeholder = input($localize`Select time`);

  readonly minTime = input<TimeValueSelection>();
  readonly maxTime = input<TimeValueSelection>();

  readonly timeOptions = computed(() => {
    const times: TimeValueSelection[] = [];
    const totalMinutes = MINUTES_IN_A_DAY;
    const step = this.steps();

    for (let minutes = 0; minutes < totalMinutes; minutes += step) {
      const hour = Math.floor(minutes / 60);
      const minute = minutes % 60;
      times.push({ hour, minute });
    }

    return times;
  });

  timeRenderer(timeSelection: TimeValueSelection): string {
    const period = timeSelection.hour >= 12 ? 'PM' : 'AM';
    const hour12 = timeSelection.hour % 12 || 12;
    const minuteFormatted = timeSelection.minute.toString().padStart(2, '0');
    return `${hour12}:${minuteFormatted} ${period}`;
  }

  timeChange(time: TimeValueSelection | undefined): void {
    this.setValueAndNotify(time);
  }

  override isValidType(obj: unknown): obj is TimeValueSelection | undefined {
    return (
      isNil(obj) ||
      (typeof obj === 'object' &&
        'hour' in (obj as object) &&
        'minute' in (obj as object))
    );
  }

  isDisabledTime: isDisabledCheck<TimeValueSelection> = (
    time: TimeValueSelection,
  ) => {
    const minTime = this.minTime();
    const maxTime = this.maxTime();

    const toMinutes = (t: TimeValueSelection): number => t.hour * 60 + t.minute;

    const minTimeMinutes = isNotNil(minTime) ? toMinutes(minTime) : 0;
    const maxTimeMinutes = isNotNil(maxTime)
      ? toMinutes(maxTime)
      : MINUTES_IN_A_DAY;

    const timeMinutes = toMinutes(time);
    return timeMinutes < minTimeMinutes || timeMinutes > maxTimeMinutes;
  };
}
