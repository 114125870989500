import { Attribute, ChangeDetectionStrategy, Component } from '@angular/core';
import { isNil } from '@frontend2/core';
import { NgIf } from '@angular/common';

@Component({
  selector: 'lefty-sidebar',
  templateUrl: './lefty-sidebar.component.html',
  styleUrls: ['./lefty-sidebar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf],
})
export class LeftySidebarComponent {
  /// Display top section (or not)
  withTop: boolean;

  ///Display bottom section (or not)
  withBottom: boolean;

  constructor(
    @Attribute('withTop') withTop?: string,
    @Attribute('withBottom') withBottom?: string,
  ) {
    this.withTop = isNil(withTop) === false && withTop !== 'false';
    this.withBottom = isNil(withBottom) === false && withBottom !== 'false';
  }
}
