import {
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
  signal,
} from '@angular/core';
import {
  getFailedResultFromInfluencerUpload,
  getSuccessResultFromInfluencerUpload,
  plural,
} from '@frontend2/core';
import {
  UploadInfluencerResultCode,
  UploadInfluencersResult,
} from '@frontend2/proto/librarian/proto/upload_influencer_pb';
import { LeftyButtonDirective } from '../../../lefty-button-directive/lefty-button.directive';
import { LeftyIconComponent } from '../../../icon/icon.component';

@Component({
  selector: 'influencer-upload-result',
  templateUrl: 'influencer-upload-result.component.html',
  styleUrls: ['./influencer-upload-result.component.scss'],
  providers: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [LeftyIconComponent, LeftyButtonDirective],
})
export class InfluencerUploadResultComponent {
  readonly result = input(new UploadInfluencersResult());

  private readonly _visibleLimit = 3;

  readonly showMoreFailed = signal(false);

  readonly successNames = computed(() =>
    getSuccessResultFromInfluencerUpload(this.result()).map((e) => e.input),
  );

  readonly failedResults = computed(() =>
    getFailedResultFromInfluencerUpload(this.result()),
  );

  readonly slicedFailedResultsNames = computed(() => {
    if (!this.showMoreFailed()) {
      return this.failedNames().slice(0, 3);
    }
    return this.failedNames();
  });

  readonly failedNames = computed(() =>
    this.failedResults().map((e) => e.input),
  );

  //TODO: this is for file upload, to be completed later
  readonly badFormatNames = computed(() =>
    this.failedResults()
      .filter(
        (element) =>
          element.statusDescription ===
          UploadInfluencerResultCode.UPLOAD_INFLUENCER_BAD_FIELD_FORMAT,
      )
      .map((e) => e.input),
  );

  readonly successListLabel = computed(() => {
    const countSuccess = this.successNames().length;
    if (countSuccess > 0) {
      return $localize`${countSuccess} ${plural(countSuccess, { one: $localize`influencer`, other: $localize`influencers` })} successfully imported.`;
    }
    return '';
  });

  readonly failedListLabel = computed(() => {
    const countFailed = this.failedResults().length;
    if (countFailed > 0) {
      return $localize`${countFailed} ${plural(countFailed, { one: $localize`influencer`, other: $localize`influencers` })} failed to be imported.`;
    }
    return '';
  });

  readonly showSuccess = computed(() => this.successNames().length > 0);
  readonly showFailed = computed(() => this.failedResults().length > 0);

  readonly hasMoreFailed = computed(
    () => this.failedResults().length > this._visibleLimit,
  );
}
