<lefty-form
  [required]="required"
  [errorMessage]="errorMessage"
  [label]="label"
>
  <button
    type="button"
    [disabled]="disabled"
    (focus)="handleFocus($event)"
    (blur)="handleBlur($event)"
    [ngClass]="buttonClass"
    class="selector-btn"
    #popupTrigger
  >
    <ng-container *ngIf="hasDate === true">
      {{ formattedDate }}
    </ng-container>

    <ng-container *ngIf="hasDate === false">
      {{ placeholder }}
    </ng-container>

    <lefty-icon
      (click)="onArrowIconClick($event)"
      icon="arrow_drop_down"
    ></lefty-icon>
  </button>

  <lefty-popup
    [matchMinSourceWidth]="false"
    [autoDismissNodeValidator]="autoDismissNodeValidator"
    [popupTrigger]="popupTrigger"
    [placement]="popupPlacement"
    [visible]="popupVisible()"
    (visibleChange)="popupVisible.set($event)"
  >
    <div class="calendar-container">
      <div
        class="lefty"
        #calendar
      ></div>

      <div
        class="clear-footer"
        *ngIf="clearable && hasDate"
      >
        <button
          type="button"
          class="plain dark large"
          (click)="clear()"
        >
          {{ Messages.clear }}
        </button>
      </div>
    </div>
  </lefty-popup>
</lefty-form>
