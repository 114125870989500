<lefty-layout [leftSectionVisible]="false">
  <lefty-sidebar-layout
    [withLeftPanel]="isAdmin"
    [leftPanelVisible]="true"
  >
    <lefty-sidebar
      left-panel
      withTop
      withBottom
    >
      <a
        top
        class="lefty-logo"
        leftyRouterLink="/"
      >
        <img src="lefty-images/lefty-logo-black.png" />
      </a>

      <navigation-menu-group [navigations]="menuItems"></navigation-menu-group>

      <div class="sync-chargebee">
        <button
          (click)="syncChargebee()"
          class="left-icon"
        >
          <lefty-icon icon="refresh"></lefty-icon>
          Sync chargebee
        </button>
      </div>

      <div bottom>
        <account-menu [state]="this.auth.currentState"></account-menu>
      </div>
    </lefty-sidebar>
    <div class="main-container">
      <router-outlet leftyRouterLifecycle></router-outlet>
    </div>
  </lefty-sidebar-layout>
</lefty-layout>

<lefty-toast-manager></lefty-toast-manager>
