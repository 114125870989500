import { Self, Optional, Component } from '@angular/core';
import { FormGroup, NgControl } from '@angular/forms';
import { SourceRequest } from '@frontend2/proto/librarian/proto/competitive_watch_pb';
import { LeftyFormValueBase } from '@frontend2/ui';

@Component({
  template: '',
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export abstract class SourceRequestForm extends LeftyFormValueBase<
  SourceRequest | undefined
> {
  constructor(@Optional() @Self() ngControl: NgControl) {
    super(undefined, ngControl);
  }

  abstract formModel: FormGroup;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  override writeValue(obj: unknown): void {
    if (obj instanceof SourceRequest) {
      this.value = obj;
    } else {
      this.formModel.reset();
    }
  }
}
