<lefty-form
  [label]="label"
  [errorMessage]="errorMessage"
  [hintText]="hintText"
  [topHintText]="topHintText"
  [large]="large"
  [optional]="optional"
>
  <button
    [ngClass]="buttonClass"
    [leftyButton]="buttonType"
    [size]="buttonSize"
    #popupTrigger
    class="selector-btn"
    focusableElement
    [class.active]="popupVisible"
    [disabled]="disabled"
  >
    <div class="button-content">
      <span
        class="placeholder"
        [class.empty]="selection.length === 0 && indeterminate.length === 0"
      >
        <span
          class="count"
          *ngIf="placeholderCount > 0 && !hidePlaceholderCount"
          >({{ placeholderCount }})</span
        >
        {{ placeholder }}
      </span>
    </div>
    <lefty-icon icon="arrow_drop_down"></lefty-icon>
  </button>
</lefty-form>

<lefty-popup
  [placement]="popupPlacement"
  [popupTrigger]="popupTrigger"
  [popupClassName]="popupClassName"
  [matchMinSourceWidth]="true"
  [visible]="popupVisible"
  (visibleChange)="onVisibleChange($event)"
>
  <div class="popup-content">
    <div
      class="header"
      *ngIf="showHeader && multiSelect"
    >
      <ng-container *ngIf="canSelectAll">
        <span
          class="selector"
          (click)="selectAll()"
          [class.active]="!allSelected"
        >
          {{ Messages.selectAll }}
        </span>
        -
      </ng-container>
      <span
        class="selector"
        (click)="clearAll()"
        [class.active]="selection.length !== 0"
      >
        {{ Messages.clearAll }}
      </span>
    </div>
    <div
      class="search-container"
      *ngIf="hideSearch === false"
    >
      <lefty-form-input
        leadingGlyph="search"
        [(value)]="search"
      >
      </lefty-form-input>
    </div>
    <div
      *ngIf="loading"
      class="loading-indicator"
    >
      <lefty-spinner></lefty-spinner>
    </div>
    <lefty-list
      *ngIf="showOptions"
      class="items-list"
    >
      <p
        class="not-found"
        *ngIf="filteredOptions.length === 0"
      >
        {{ notFoundMessage }}
      </p>
      <lefty-select-dropdown-item
        *ngFor="let item of visibleOptions; trackBy: trackByFn"
        (click)="toggleItem(item)"
        [supportMultiSelect]="multiSelect"
        [selected]="isSelected(item)"
        [disabled]="isDisabled(item)"
        [indeterminate]="isIndeterminate(item)"
        [value]="item"
        [itemRenderer]="itemRenderer"
        [componentFactory]="componentFactory"
        [closeOnActivate]="false"
      >
      </lefty-select-dropdown-item>

      <intersection-observer (trigger$)="nextPage()"></intersection-observer>
      <div
        class="footer"
        *ngIf="withLogicOperator"
      >
        <lefty-logic-operator-selector
          [value]="selectedLogicOperator"
          (valueChange)="handleLogicOperatorChange($event)"
        ></lefty-logic-operator-selector>
      </div>
    </lefty-list>
  </div>
</lefty-popup>
