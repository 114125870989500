import { Inject, Injectable } from '@angular/core';
import { Empty } from '@bufbuild/protobuf';
import {
  LibrarianAdminClient,
  LibrarianAdminClientProvider,
} from '@frontend2/api';
import { CacheBloc } from '@frontend2/ui';
import {
  TeamDetails,
  TeamsResponse,
  TeamsResponse_TeamLight,
} from '@frontend2/proto/librarian/proto/admin_pb';

@Injectable({ providedIn: 'root' })
export class WorkspacesCache extends CacheBloc<TeamsResponse> {
  constructor(
    @Inject(LibrarianAdminClientProvider)
    private adminRpc: LibrarianAdminClient,
  ) {
    super(new TeamsResponse());
  }

  override async fetch(): Promise<TeamsResponse> {
    return await this.adminRpc.getTeamsAPI(new Empty());
  }

  updateOrAddWorkspaceInCache(workspace: TeamDetails): void {
    if (!this.isLoaded) {
      return;
    }

    const index = this.cachedData().teams.findIndex(
      (t) => t.id === workspace.id,
    );

    const newTeam = new TeamsResponse_TeamLight({
      id: workspace.id,
      membersCount: workspace.members.length,
      name: workspace.name,
      owner: workspace.owner,
    });

    const newTeams = [...this.cachedData().teams];
    if (index === -1) {
      newTeams.push(newTeam);
    } else {
      newTeams[index] = newTeam;
    }

    this.updateCache(new TeamsResponse({ teams: newTeams }));
  }
}
