import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  FormsModule,
  NgControl,
  ReactiveFormsModule,
} from '@angular/forms';
import {
  LibrarianCWAdminClient,
  LibrarianCWAdminClientProvider,
} from '@frontend2/api';
import {
  AuxiliarySourceRequest,
  SourceRequest,
} from '@frontend2/proto/librarian/proto/competitive_watch_pb';
import {
  RangeSearch,
  SearchRequest,
} from '@frontend2/proto/universal/arkansas/proto/arkansas_pb';
import {
  AuxiliaryCategoriesCache,
  AuxiliaryCategory,
  AuxiliaryCountriesCache,
  AuxiliaryCountry,
  LeftyFormNumberInputComponent,
  SearchAndSelectDropdownComponent,
} from '@frontend2/ui';
import { SourceRequestForm } from '../source-forms';

@Component({
  selector: 'auxiliary-source-form',
  templateUrl: 'auxiliary-source-form.component.html',
  styleUrls: ['./../source-forms.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    SearchAndSelectDropdownComponent,
    LeftyFormNumberInputComponent,
  ],
})
export class CwAuxiliarySourceFormComponent extends SourceRequestForm {
  constructor(
    ngControl: NgControl,
    @Inject(LibrarianCWAdminClientProvider)
    protected librarian: LibrarianCWAdminClient,
    private categoriesCache: AuxiliaryCategoriesCache,
    private countriesCache: AuxiliaryCountriesCache,
  ) {
    super(ngControl);
    this._loadData();

    this.formModel.valueChanges.subscribe(() =>
      this.handleValueChange(this._buildSourceRequest(this.formModel)),
    );
  }

  readonly availableCountries = this.countriesCache.cachedData;
  readonly availableCategories = this.categoriesCache.cachedData;

  override formModel = new FormGroup(
    {
      countries: new FormControl<Iterable<AuxiliaryCountry>>([]),
      categories: new FormControl<Iterable<AuxiliaryCategory>>([]),
      minFollowers: new FormControl<number | null>(null),
    },
    this._inputsValidator,
  );

  private async _loadData(): Promise<void> {
    await Promise.all([
      this.categoriesCache.load(),
      this.countriesCache.load(),
    ]);
  }

  private _inputsValidator(
    control: AbstractControl,
  ): { [key: string]: boolean } | null {
    const countries = control.get('countries')?.value;
    const categories = control.get('categories')?.value;
    const minFollowers = control.get('minFollowers')?.value;

    if (countries?.length || categories?.length || minFollowers) {
      return null;
    }
    return { required: true };
  }

  readableCountry(item: AuxiliaryCountry): string {
    return item.name;
  }

  readableCategory(cat: AuxiliaryCategory): string {
    return cat.name;
  }

  private _buildSourceRequest(controls: FormGroup): SourceRequest | undefined {
    if (this.formModel.invalid) {
      return undefined;
    }
    const searchRequest = new SearchRequest();
    const request = new AuxiliarySourceRequest();

    const countries = controls.get('countries')?.value;
    const categories = controls.get('categories')?.value;
    const minFollowers = controls.get('minFollowers')?.value;

    if (countries?.length) {
      searchRequest.geo.push(...countries.map((e: AuxiliaryCountry) => e.id));
    }
    if (categories?.length) {
      searchRequest.brandCategory.push(
        ...categories.map((e: AuxiliaryCategory) => e.id),
      );
    }
    if (minFollowers) {
      searchRequest.followers = new RangeSearch({ min: minFollowers });
    }

    request.searchRequest = searchRequest;
    return new SourceRequest({
      request: { value: request, case: 'auxiliary' },
    });
  }
}
