import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
  Optional,
  Output,
  Self,
} from '@angular/core';
import { LeftyFormValueBase } from '../form';
import { Observable } from 'rxjs';
import { NgControl } from '@angular/forms';
import { LeftyIconComponent } from '../icon/icon.component';
import { NgIf } from '@angular/common';

export type ToggleSize = 'large' | 'medium' | 'small';

@Component({
  selector: 'lefty-toggle',
  templateUrl: 'lefty-toggle.component.html',
  styleUrls: ['lefty-toggle.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, LeftyIconComponent],
})
export class LeftyToggleComponent extends LeftyFormValueBase<boolean> {
  constructor(@Self() @Optional() ngControl?: NgControl) {
    super(false, ngControl);
  }

  @Input()
  size: ToggleSize = 'medium';

  @HostBinding('class')
  get className(): string {
    return `${this.size}`;
  }

  get checked(): boolean {
    return this.value;
  }

  @Input()
  set checked(val: boolean) {
    this.value = val;
  }

  @Output()
  get checkedChange(): Observable<boolean> {
    return this.valueChange;
  }

  toggle(): void {
    if (this.disabled) {
      return;
    }

    this.handleValueChange(!this.checked);
  }

  writeValue(obj: unknown): void {
    if (typeof obj === 'boolean') {
      this.value = obj;
    }
  }
}
