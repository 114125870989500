import { ChangeDetectionStrategy, Component } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  FormsModule,
  NgControl,
  ReactiveFormsModule,
} from '@angular/forms';
import { isNotNil } from '@frontend2/core';
import { StringList } from '@frontend2/proto/common/proto/common_pb';
import {
  LeftySourceRequest,
  SourceRequest,
} from '@frontend2/proto/librarian/proto/competitive_watch_pb';
import { GoogleLocation } from '@frontend2/proto/librarian/proto/local_pb';
import {
  AuxiliaryCountriesCache,
  Language,
  LanguagesCache,
  LeftyChipsEditorComponent,
  LeftyFormNumberInputComponent,
  LeftyPercentInputDirective,
  SearchAndSelectDropdownComponent,
  SearchLocationAutocompleteComponent,
} from '@frontend2/ui';
import { SourceRequestForm } from '../source-forms';

@Component({
  selector: 'lefty-source-form',
  templateUrl: 'lefty-source-form.component.html',
  styleUrls: ['./../source-forms.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    LeftyChipsEditorComponent,
    SearchAndSelectDropdownComponent,
    LeftyFormNumberInputComponent,
    LeftyPercentInputDirective,
    SearchLocationAutocompleteComponent,
  ],
})
export class CwLeftySourceFormComponent extends SourceRequestForm {
  constructor(
    ngControl: NgControl,
    protected countriesCache: AuxiliaryCountriesCache,
    protected languagesCache: LanguagesCache,
  ) {
    super(ngControl);
    this._loadData();

    this.formModel.valueChanges.subscribe(() =>
      this.handleValueChange(this._buildSourceRequest()),
    );
  }

  readonly availableCountries = this.countriesCache.cachedData;
  readonly availableLanguages = this.languagesCache.languagesList;

  override formModel = new FormGroup(
    {
      mentions: new FormControl<string[]>([]),
      hashtags: new FormControl<string[]>([]),
      minFollowers: new FormControl<number | null>(null),
      country: new FormControl<GoogleLocation | null>(null),
      language: new FormControl<Language[] | []>([]),
      percentage: new FormControl<number | null>(null),
    },
    this._inputsValidator,
  );

  private async _loadData(): Promise<void> {
    await Promise.all([this.countriesCache.load(), this.languagesCache.load()]);
  }

  private _inputsValidator(
    control: AbstractControl,
  ): { [key: string]: boolean } | null {
    const fieldNames = [
      'country',
      'percentage',
      'mentions',
      'hashtags',
      'language',
      'minFollowers',
    ];

    const hasNonEmptyValue = (fieldName: string): boolean => {
      const value = control.get(fieldName)?.value;
      return value?.length > 0;
    };

    if (
      fieldNames.some(hasNonEmptyValue) ||
      isNotNil(control.get('minFollowers')?.value)
    ) {
      return null;
    }

    return { required: true };
  }

  readableLanguage(item: Language): string {
    return item.name;
  }

  _buildSourceRequest(): SourceRequest {
    const mentions = this.formModel.get('mentions')?.value;
    const hashtags = this.formModel.get('hashtags')?.value;
    const language = this.formModel.get('language')?.value;
    const percentage = this.formModel.get('percentage')?.value;
    const country = this.formModel.get('country')?.value;
    const minFollowers = this.formModel.get('minFollowers')?.value;
    const request = new LeftySourceRequest();
    if (isNotNil(mentions)) {
      request.mentions = new StringList({ values: mentions });
    }
    if (isNotNil(hashtags)) {
      request.hashtags = new StringList({ values: hashtags });
    }
    if (isNotNil(language) && language.length > 0) {
      request.language = language[0].code;
    }
    if (isNotNil(percentage)) {
      request.languagePercentage = percentage;
    }
    if (isNotNil(country)) {
      request.locationId = country.googleId;
    }
    if (isNotNil(minFollowers)) {
      request.minFollowers = minFollowers;
    }

    return new SourceRequest({
      request: {
        value: request,
        case: 'lefty',
      },
    });
  }
}
