<div
  class="account"
  #popupTrigger
>
  <div class="avatar">{{ initials }}</div>
  <span class="body-1 name">{{ username }}</span>

  <lefty-icon
    size="medium"
    icon="arrow_drop_down"
  ></lefty-icon>
</div>
<lefty-popup
  placement="right"
  [popupTrigger]="popupTrigger"
>
  <lefty-list>
    <lefty-list-item (trigger$)="logout()">Logout</lefty-list-item>
  </lefty-list>
</lefty-popup>
