import {
  LocationStrategy,
  NgClass,
  NgFor,
  NgIf,
  NgTemplateOutlet,
} from '@angular/common';
import {
  AfterViewInit,
  Attribute,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  NgZone,
  OnChanges,
  Optional,
  Output,
  SimpleChanges,
  ViewChild,
  ViewChildren,
  inject,
} from '@angular/core';
import { ActivatedRoute, RouterLink, UrlTree } from '@angular/router';
import {
  MassSelectBloc,
  Observables,
  SortBy,
  copyToClipboard,
  createSelection,
  hasAllSelected,
  hasAllSelectedIndeterminated,
  isNil,
  isSelected,
  isSelectionActive,
} from '@frontend2/core';
import { SortDirection } from '@frontend2/proto/common/proto/common_pb';
import { Subscription, fromEvent } from 'rxjs';
import { LeftyCheckboxComponent } from '../checkbox/checkbox.component';
import {
  ComponentFactory,
  DynamicComponent,
  RendersValue,
} from '../dynamic-component.component';
import { LeftyIconComponent } from '../icon/icon.component';
import { injectRouter } from '../inject.helpers';
import { IntersectionObserverDirective } from '../intersection-observer.directive';
import { RouteCommand, getRouteParams } from '../router-utils';
import { SortItemComponent } from '../sort-item/sort-item.component';
import { ToastManager } from '../toast/toast.service';
import { LeftyComponent, attributeToBool } from '../utils';
import {
  createCell,
  isColumnSortable,
  isRowGhost,
} from './lefty-data-table.helpers';
import {
  Cell,
  ClickEvent,
  Column,
  IsActiveCheck,
  LinkBuilder,
  Row,
} from './lefty-data-table.models';

@Component({
  selector: 'lefty-data-table',
  templateUrl: 'lefty-data-table.component.html',
  styleUrls: ['lefty-data-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgIf,
    LeftyCheckboxComponent,
    NgFor,
    NgClass,
    SortItemComponent,
    NgTemplateOutlet,
    IntersectionObserverDirective,
    DynamicComponent,
    LeftyIconComponent,
    RouterLink,
  ],
})
export class LeftyDataTableComponent<SortType, RowDataModel>
  extends LeftyComponent
  implements AfterViewInit, OnChanges
{
  readonly horizontalScroll: boolean;

  constructor(
    readonly elementRef: ElementRef,
    readonly toastManager: ToastManager,
    readonly locationStrategy: LocationStrategy,
    @Attribute('horizontalScroll') horizontalScroll: string,
    readonly zone: NgZone,
    @Optional() readonly massSelectBloc: MassSelectBloc<RowDataModel>,
  ) {
    super();
    this.horizontalScroll = attributeToBool(horizontalScroll);

    this.disposer.addSubject(this.sortChange$);
    this.disposer.addSubject(this.rowClick$);
    this.disposer.addSubject(this.scrollEnd$);

    if (this.massSelectBloc) {
      this.watch(this.massSelectBloc.state$, {
        next: (state) => (this.massSelectState = state),
      });
    }
  }

  private resizeObserver?: ResizeObserver;
  private _withSelection = false;
  private _headerCells: Element[] = [];
  private initialOffsetTop?: number;

  headerFixed = false;
  massSelectState = createSelection<RowDataModel>({});

  get hasAllSelected(): boolean {
    return hasAllSelected(this.massSelectState);
  }

  get allSelectedIndeterminated(): boolean {
    return hasAllSelectedIndeterminated(this.massSelectState);
  }

  @Input()
  withCounter = false;

  /// default position is the size of lefty top bar
  @Input()
  stickyPosition = 66;

  @Input()
  @HostBinding('class.sticky')
  sticky = false;

  @Input()
  rows: Row<RowDataModel>[] = [];

  @Input()
  componentFactories: {
    [key: string]: ComponentFactory<RendersValue<unknown>>;
  } = {};

  @Input()
  columns: Column<SortType>[] = [];

  /// Compute visible column inside [ngAfterChanges] instead of a getter
  /// it avoid calling .where() and .toList() too many times
  visibleColumns: Column<SortType>[] = [];

  // Match visibleColumns to its true column index in columns list
  private _realColumnsIndex: {
    [visibleIndex: number]: number;
  } = {};

  @Input()
  loading = false;

  /// TODO: could be done with CSS only
  @Input()
  firstCellBold = true;

  @Input()
  @HostBinding('class.align-last-cell-to-right')
  alignLastCellToRight = false;

  @Input()
  sort?: SortBy<SortType>;

  // ids of columns you want to hide
  @Input()
  hiddenColumns: string[] = [];

  /// Determine which target strategy to use when add link on a row
  /// ex: rowLinkTarget="_blank" (to open in new tab)
  @Input()
  rowLinkTarget = '_self';

  /// Function to build link for a row
  @Input()
  rowLinkBuilder?: LinkBuilder<RowDataModel>;

  /// Function to check if a row is active or not
  /// If row is active, we will add `class="active"` on row element
  @Input()
  rowIsActiveCheck?: IsActiveCheck<RowDataModel>;

  /// boolean to activate selection on datatable
  @Input()
  set withSelection(val: boolean) {
    this._withSelection = val;
  }

  get withSelection(): boolean {
    return this._withSelection && isNil(this.massSelectBloc) === false;
  }

  /// Stream for click event on table row
  @Output()
  readonly rowClick$ = new EventEmitter<ClickEvent<RowDataModel>>();

  @Output()
  readonly sortChange$ = new EventEmitter<SortBy<SortType>>();

  @Output()
  readonly scrollEnd$ = new EventEmitter<IntersectionObserverEntry>();

  @HostBinding('class.active-selection')
  get activeSelection(): boolean {
    return isSelectionActive(this.massSelectState);
  }

  @ViewChild('table', { read: HTMLTableElement })
  tableElement?: HTMLTableElement;

  @ViewChild('tableContainer', { read: ElementRef })
  tableContainer?: ElementRef;

  @ViewChildren('th')
  set headerCells(cells: ElementRef[]) {
    this._headerCells = cells.map((ref) => ref.nativeElement);
    if (this.horizontalScroll) {
      // manually recompute header cell width
      // if table th width change
      this.resizeObserver?.disconnect();
      this.resizeObserver = new ResizeObserver((entries) => {
        entries
          .map((e) => e.target)
          .forEach((el) => {
            this.applyHeaderCellWidth(el);
          });
      });

      this._headerCells.forEach((cell) => {
        this.resizeObserver?.observe(cell);
      });

      // for some reason, th.column-counter is not catch by ViewChildren
      const thCoutner =
        this.elementRef.nativeElement.querySelector('.column-counter');
      if (thCoutner) {
        this.resizeObserver?.observe(thCoutner);
      }
    }
  }

  applyHeaderCellWidth(cell: Element): void {
    const headerCell = cell.querySelector('.header-cell') as HTMLElement;
    const headerCellWidth = cell.querySelector('.header-cell-width');

    if (headerCell && headerCellWidth) {
      headerCell.style.width = `${headerCellWidth.clientWidth}px`;
    }
  }

  get topPosition(): number | undefined {
    return this.horizontalScroll ? undefined : this.stickyPosition;
  }

  isSortable(col: Column<unknown>): boolean {
    return isColumnSortable(col);
  }

  isSortActive(sortValue?: SortType): boolean {
    if (!this.sort) {
      return false;
    }
    return sortValue === this.sort.value;
  }

  get isAscDir(): boolean {
    return this.sort?.direction === SortDirection.ASC;
  }

  sortBy(col: Column<SortType>): void {
    if (isNil(col.sortValue)) {
      return;
    }

    let newSort = SortBy.create<SortType>(col.sortValue);
    if (this.isSortActive(col.sortValue)) {
      if (this.isAscDir) {
        newSort = { ...newSort, direction: SortDirection.DESC };
      } else {
        newSort = { ...newSort, direction: SortDirection.ASC };
      }
    }

    this.sort = newSort;
    this.sortChange$.next(newSort);
  }

  isRowGhost(row: Row<RowDataModel>): boolean {
    return isRowGhost(row);
  }

  trackByRow(index: number, row: Row<RowDataModel>): unknown {
    return row.cells;
  }

  trackByCol(index: number, col: Column<SortType>): string {
    return col.id;
  }

  rowClass(row: Row<RowDataModel>): string {
    return `row-${row.id} ${row.className} ${row.isGhost ? 'row-ghost' : ''}`;
  }

  cellClass(col: Column<SortType>): string {
    return `cell-${col.id} ${col.className}`;
  }

  colClass(col: Column<SortType>): string {
    return `column-${col.id} ${col.className}`;
  }

  getCell(row: Row<RowDataModel>, visibleIndex: number): Cell<unknown> {
    const index = this._realColumnsIndex[visibleIndex];

    // return empty cell if index out of range
    if (isNil(index) || index >= row.cells.length) {
      return createCell();
    }

    return row.cells[index];
  }

  private _computeColumns(
    hiddenColumns: string[],
    columns: Column<SortType>[],
  ): void {
    this.visibleColumns = [];
    this._realColumnsIndex = {};
    for (let i = 0; i < columns.length; i++) {
      if (hiddenColumns.includes(columns[i].id) === false) {
        // OPTI
        // map the visible col index to the true index
        // so it's easier and faster to retry it later
        this._realColumnsIndex[this.visibleColumns.length] = i;
        this.visibleColumns.push(columns[i]);
      }
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['columns'] || changes['hiddenColumns']) {
      this._computeColumns(this.hiddenColumns, this.columns);
    }
  }

  /// List of selected data
  get selection(): RowDataModel[] {
    return this.massSelectState.selectedItems;
  }

  isSelected(data?: RowDataModel): boolean {
    return isSelected(this.massSelectState, data);
  }

  /// toggle [allSelected] boolean
  ///
  /// if next value is false, we also cleanup actual [selection] list
  toggleAllSelection(): void {
    this.massSelectBloc?.toggleAllSelection(
      this.rows
        .map((r) => r.data)
        .filter((data) => isNil(data) === false) as RowDataModel[],
    );
  }

  /// Select or unselect [Row.data]
  /// and emit [onSelectionChange] event
  toggleSelection(event: MouseEvent, data: RowDataModel): void {
    event.preventDefault();
    event.stopPropagation();

    this.massSelectBloc?.toggle(data);
  }

  readonly router = injectRouter();

  readonly activatedRoute = inject(ActivatedRoute, {
    optional: true,
  });

  /// intercept click on a row element
  /// and trigger selection only if currently active
  clickOnRow(event: MouseEvent, row: Row<RowDataModel>): void {
    if (this.activeSelection) {
      if (row.data) {
        this.toggleSelection(event, row.data);
      }
    } else {
      // if selection not active
      // propagate event to `onRowClick` output

      Observables.safeNext(this.rowClick$, {
        event,
        row,
      });
    }
  }

  async copyLinkToClipboard(cell: Cell<unknown>): Promise<void> {
    await copyToClipboard(cell.formattedValue);

    this.toastManager.showSuccess(
      `${cell.formattedValue} copied to clipboard !`,
    );
  }

  isRowActive(row: Row<RowDataModel>): boolean {
    if (isNil(this.rowIsActiveCheck)) {
      return false;
    }
    return this.rowIsActiveCheck(
      row,
      isNil(this.activatedRoute?.snapshot)
        ? undefined
        : getRouteParams(this.activatedRoute?.snapshot),
    );
  }

  buildLink(row: Row<RowDataModel>): UrlTree | RouteCommand[] {
    if (isNil(this.rowLinkBuilder)) {
      return [];
    }

    return this.rowLinkBuilder(
      row,
      isNil(this.activatedRoute?.snapshot)
        ? undefined
        : getRouteParams(this.activatedRoute?.snapshot),
    );
  }

  getTemplateRowContext(
    row: Row<RowDataModel>,
    index: number,
  ): { row: Row<RowDataModel>; rowIndex: number } {
    return {
      row: row,
      rowIndex: index,
    };
  }

  testSortSelector(sortValue?: SortType): string {
    return `sort_${sortValue}`;
  }

  resetScroll(): void {
    this.tableContainer?.nativeElement.scrollTo(0, 0);
  }

  _listenWindowScroll(): Subscription {
    return fromEvent(window.document, 'scroll').subscribe({
      next: () => {
        window.requestAnimationFrame(() => {
          if (isNil(this.tableElement)) {
            return;
          }

          // cache result of tableElement.offsetTop
          // because we want the initial position of table
          // but we don't want if if the offset is 0
          if (
            isNil(this.initialOffsetTop) &&
            this.tableElement.offsetTop === 0
          ) {
            this.headerFixed = false;
          } else {
            this.initialOffsetTop ??= this.tableElement.offsetTop;
            const offset = this.initialOffsetTop - this.stickyPosition;

            this.headerFixed = document.documentElement.scrollTop >= offset;
          }
          this.changeDetection.markForCheck();
        });
      },
    });
  }

  ngAfterViewInit(): void {
    if (this.horizontalScroll) {
      return;
    }

    this.disposer.addStreamSubscription(this._listenWindowScroll());
  }
}
