import { NgIf } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  Optional,
  Type,
  output,
  signal,
} from '@angular/core';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { injectLibrarianPoolClient } from '@frontend2/api';
import {
  addOrUpdateInArray,
  getEnumName,
  isNil,
  isNotEmptyString,
  isNotNil,
} from '@frontend2/core';
import {
  BasicPagination,
  LogicOperator,
  Network,
  StringList,
} from '@frontend2/proto/common/proto/common_pb';
import {
  CommonFilter,
  CreatorPoolRequestV2,
  DynamicFilter,
  DynamicFilterGroup,
  ListFilter,
  NativeFieldFilter,
  TextFilter,
} from '@frontend2/proto/librarian/proto/common_pb';
import { CreatorPoolResponse } from '@frontend2/proto/librarian/proto/pool_pb';
import { debounceTime } from 'rxjs';
import { SelectorDropdownBase } from '../dropdown';
import { ComponentFactory } from '../dynamic-component.component';
import { showToastException } from '../error-handler';
import { LeftyIconComponent } from '../icon/icon.component';
import { IntersectionObserverDirective } from '../intersection-observer.directive';
import {
  ButtonSize,
  ButtonType,
  LeftyButtonDirective,
} from '../lefty-button-directive/lefty-button.directive';
import { LeftyFormInputComponent } from '../lefty-form-input/lefty-form-input.component';
import { LeftySelectDropdownItemComponent } from '../lefty-form-select/lefty-select-dropdown-item.component';
import { LeftyFormComponent } from '../lefty-form/lefty-form.component';
import { LeftyListComponent } from '../lefty-list/lefty-list.component';
import { LeftyPopupComponent } from '../lefty-popup/lefty-popup.component';
import { LeftySpinnerComponent } from '../loading.component';
import { injectToastManager } from '../toast/toast.service';
import {
  DirectoryInfluencerListItemComponent,
  DirectoryInfluencerWithNetworkListItemComponent,
} from './influencer-item/directory-influencer-list-item.component';

@Component({
  selector: 'directory-influencer-search-and-select',
  templateUrl: 'directory-influencer-search-and-select.component.html',
  styleUrls: [
    'directory-influencer-search-and-select.component.scss',
    '../selector/selector.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    LeftyFormComponent,
    LeftyButtonDirective,
    LeftyIconComponent,
    LeftyPopupComponent,
    LeftyFormInputComponent,
    LeftySpinnerComponent,
    LeftyListComponent,
    NgIf,
    LeftySelectDropdownItemComponent,
    IntersectionObserverDirective,
  ],
})
export class DirectoryInfluencerSearchAndSelectComponent
  extends SelectorDropdownBase<CreatorPoolResponse>
  implements ControlValueAccessor
{
  constructor(@Optional() public ngControl?: NgControl) {
    super();

    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }

    toObservable(this.searchValue)
      .pipe(debounceTime(300), takeUntilDestroyed())
      .subscribe(async (n) => {
        this.loading.set(true);
        this.options = [];
        const pagination = new BasicPagination({
          from: 0,
          size: this._paginationSize,
        });
        if (this.allowEmptySearch || isNotEmptyString(n)) {
          this.options = await this.loadInfluencersByNameSearch(n, pagination);
        }
        this.loading.set(false);
      });
  }

  private readonly librarianPool = injectLibrarianPoolClient();
  private readonly toastManager = injectToastManager();

  readonly touched$ = output<void>();

  readonly preselectionLoaded$ = output<CreatorPoolResponse[]>();

  private readonly _paginationSize = 15;

  readonly searchValue = signal('');

  options: CreatorPoolResponse[] = [];

  readonly loading = signal(false);

  private readonly totalHits = signal(BigInt(0));

  @Input()
  multiSelect = false;

  @Input()
  activeNetwork = Network.NETWORK_UNKNOWN;

  private _selectedIds: string[] = [];

  @Input()
  set selectedIds(ids: string[]) {
    this._selectedIds = ids;
    this.handlePreSelection();
  }

  get selectedIds(): string[] {
    return this._selectedIds;
  }

  @Input()
  hideHeader = false;

  @Input()
  disabledItems: CreatorPoolResponse[] = [];

  @Input()
  buttonType: ButtonType = 'secondary';

  @Input()
  buttonSize: ButtonSize = 'medium';

  @Input()
  disabled = false;

  @Input()
  allowEmptySearch = false;

  @Input()
  withNetworkIcon = false;

  itemRenderer(item: CreatorPoolResponse): string {
    return item.baseSnippet?.userName ?? '';
  }

  get buttonText(): string {
    return this.selectionCount > 0
      ? this.selection.map(this.itemRenderer).join(', ')
      : this.placeholder;
  }

  @Input()
  placeholder = 'Influencers';

  get selectionCount(): number {
    return this.selection.length;
  }

  get showOptions(): boolean {
    if (this.allowEmptySearch) {
      return this.loading() === false;
    }
    return this.loading() === false && isNotEmptyString(this.searchValue());
  }

  async nextPage(): Promise<void> {
    if (
      this.loading() === false &&
      Number(this.totalHits()) > this.options.length
    ) {
      const pagination = new BasicPagination({
        from: this.options.length,
        size: this._paginationSize,
      });
      const newList = await this.loadInfluencersByNameSearch(
        this.searchValue(),
        pagination,
      );
      this.options = [...this.options, ...newList];
    }
  }

  override toggleItem(item: CreatorPoolResponse): void {
    if (this.isDisabled(item)) {
      return;
    }
    if (this.multiSelect) {
      super.toggleItem(item);
    } else {
      if (this.isSelected(item)) {
        this.selection = [];
      } else {
        this.selection = [item];
      }
    }
  }

  override isSelected(item: CreatorPoolResponse): boolean {
    return this.selection.some(
      (s) =>
        isNotNil(s.baseSnippet?.userId) &&
        s.baseSnippet?.userId === item.baseSnippet?.userId,
    );
  }

  isDisabled(item: CreatorPoolResponse): boolean {
    return this.disabledItems.includes(item);
  }

  async loadInfluencersByNameSearch(
    name: string,
    pagination: BasicPagination,
  ): Promise<CreatorPoolResponse[]> {
    let filter = new DynamicFilter();
    if (this.activeNetwork !== Network.NETWORK_UNKNOWN) {
      const networkGroup = this.filterWithNetwork(this.activeNetwork);
      filter.filterGroup.push(networkGroup);
    }

    filter = this.filterWithSearchText(name, filter);

    const _req: CreatorPoolRequestV2 = new CreatorPoolRequestV2();
    _req.dynamicFilter = filter;
    _req.pagination = pagination;

    try {
      const resp = await this.librarianPool.autocompleteCreatorsAPIV2(_req);
      this.totalHits.set(resp.totalHits);
      return resp.responses;
    } catch (error) {
      showToastException(this.toastManager, error);
      return [];
    }
  }

  async loadInfluencersWithIds(ids: string[]): Promise<CreatorPoolResponse[]> {
    const filter = new DynamicFilter();
    if (this.activeNetwork !== Network.NETWORK_UNKNOWN) {
      const networkGroup = this.filterWithNetwork(this.activeNetwork);
      filter.filterGroup.push(networkGroup);
    }

    const creatorsGroup = this.filterWithCreators(ids);
    filter.filterGroup.push(creatorsGroup);

    const pagination = new BasicPagination({ from: 0, size: ids.length });

    const _req: CreatorPoolRequestV2 = new CreatorPoolRequestV2();
    _req.dynamicFilter = filter;
    _req.pagination = pagination;

    try {
      return (await this.librarianPool.autocompleteCreatorsAPIV2(_req))
        .responses;
    } catch (error) {
      showToastException(this.toastManager, error);
      return [];
    }
  }

  async handlePreSelection(): Promise<void> {
    const preselection = await this.loadInfluencersWithIds(this.selectedIds);
    this.preselectionLoaded$.emit(preselection);
    this.selection = preselection;
  }

  filterWithSearchText(value: string, filter: DynamicFilter): DynamicFilter {
    const searchFilter = new CommonFilter({
      field: {
        case: 'nativeFieldFilter',
        value: NativeFieldFilter.SEARCH_TEXT_FILTER,
      },
      filter: {
        case: 'textFilter',
        value: new TextFilter({ value: { case: 'contains', value: value } }),
      },
    });
    const searchGroup = new DynamicFilterGroup({
      filters: [searchFilter],
      isDefault: true,
    });
    // we replace the group here because the search text can change in the same view
    const newGroupsList = addOrUpdateInArray(filter.filterGroup, searchGroup, {
      predicate: (el) =>
        el.filters[0].field.case === 'nativeFieldFilter' &&
        el.filters[0].field.value === NativeFieldFilter.SEARCH_TEXT_FILTER,
    });

    return new DynamicFilter({ ...filter, filterGroup: newGroupsList });
  }

  filterWithNetwork(network: Network): DynamicFilterGroup {
    const networksGroup = new DynamicFilterGroup({
      operator: LogicOperator.AND,
      filters: [
        new CommonFilter({
          field: {
            case: 'nativeFieldFilter',
            value: NativeFieldFilter.INFLUENCER_NETWORKS_FILTER,
          },
          filter: {
            case: 'listFilter',
            value: new ListFilter({
              value: {
                case: 'anyOf',
                value: new StringList({
                  values: [getEnumName(Network, network) ?? ''],
                }),
              },
            }),
          },
        }),
      ],
    });
    return networksGroup;
  }

  filterWithCreators(values: string[]): DynamicFilterGroup {
    const searchFilter = new CommonFilter({
      field: {
        case: 'nativeFieldFilter',
        value: NativeFieldFilter.CREATORS_FILTER,
      },
      filter: {
        case: 'listFilter',
        value: new ListFilter({
          value: { case: 'anyOf', value: new StringList({ values: values }) },
        }),
      },
    });
    return new DynamicFilterGroup({
      filters: [searchFilter],
      isDefault: true,
    });
  }

  override unselectItem(item: CreatorPoolResponse): void {
    this.selection = this.selection.filter(
      (n) => n.baseSnippet?.userId !== item.baseSnippet?.userId,
    );
  }

  writeValue(obj: unknown): void {
    if (Array.isArray(obj)) {
      this.selection = obj as CreatorPoolResponse[];
    } else if (isNil(obj)) {
      this.selection = [];
    } else {
      console.warn(
        `Failed to bind type ${typeof obj} on form control ${
          this.ngControl?.name
        }`,
      );
    }
  }

  registerOnChange(fn: (value: CreatorPoolResponse[]) => void): void {
    this.disposer.addStreamSubscription(
      this.selectionChange.subscribe({ next: fn }),
    );
  }

  registerOnTouched(fn: () => void): void {
    this.touched$.subscribe(() => fn());
  }

  onVisibleChange($event: boolean): void {
    if (this.popupVisible) {
      this.touched$.emit();
    }
    this.popupVisibleChange.next($event);
  }

  componentFactoryInfluencerWithNetwork(): Type<unknown> {
    return DirectoryInfluencerWithNetworkListItemComponent;
  }

  componentFactoryInfluencerNoNetwork(): Type<unknown> {
    return DirectoryInfluencerListItemComponent;
  }

  get componentFactory(): ComponentFactory<CreatorPoolResponse> {
    return this.withNetworkIcon
      ? this.componentFactoryInfluencerWithNetwork
      : this.componentFactoryInfluencerNoNetwork;
  }
}
