import {
  ChangeDetectionStrategy,
  Component,
  computed,
  inject,
  output,
  signal,
} from '@angular/core';
import {
  isNotEmptyString,
  isNotNil,
  LeftyFormat,
  Networks,
} from '@frontend2/core';
import { Network } from '@frontend2/proto/common/proto/common_pb';
import { UploadInfluencersResult } from '@frontend2/proto/librarian/proto/upload_influencer_pb';
import { showToastException } from '../../error-handler';
import { NetworkIconComponent } from '../../icon/network.component';
import { LeftyButtonDirective } from '../../lefty-button-directive/lefty-button.directive';
import { LeftyDialogComponent } from '../../lefty-dialog/lefty-dialog.component';
import { DialogBase } from '../../lefty-dialog/lefty-dialog.helpers';
import {
  LeftyProgressComponent,
  LeftySpinnerComponent,
} from '../../loading.component';
import { BroncoService } from '../../services/bronco.service';
import { injectToastManager } from '../../toast/toast.service';
import { PlainTextInfluencerUploadData } from '../influencers-management.models';
import { InfluencerUploadResultComponent } from './upload-result/influencer-upload-result.component';

@Component({
  selector: 'upload-influencer-loader-dialog',
  templateUrl: 'upload-influencer-loader-dialog.component.html',
  styleUrls: ['./upload-influencer-loader-dialog.component.scss'],
  providers: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    LeftyDialogComponent,
    NetworkIconComponent,
    InfluencerUploadResultComponent,
    LeftySpinnerComponent,
    LeftyProgressComponent,
    LeftyButtonDirective,
  ],
})
export class LeftyUploadInfluencerLoaderDialogComponent extends DialogBase {
  readonly broncoService = inject(BroncoService);
  readonly toastManager = injectToastManager();

  readonly broncoToken = signal('');

  //to make dialog title
  readonly uploadData = signal<PlainTextInfluencerUploadData | undefined>(
    undefined,
  );

  readonly influencersCount = computed(
    () => this.uploadData()?.usernames.length ?? 0,
  );

  readonly network = computed(
    () => this.uploadData()?.network ?? Network.NETWORK_UNKNOWN,
  );

  readonly readableNetwork = computed(() => Networks.readable(this.network()));

  readonly hasUploadData = computed(() => isNotNil(this.uploadData()));

  readonly loadingProgress = signal(0);
  readonly progressMessage = signal('');

  readonly result$ = output<UploadInfluencersResult>();

  readonly isLoaded = signal(false);

  readonly uploadResult = signal<UploadInfluencersResult>(
    new UploadInfluencersResult(),
  );

  readonly formattedLoadingProgress = computed(() =>
    LeftyFormat.percent(this.loadingProgress() / 100, {
      maximumFractionDigits: 0,
      showZero: true,
    }),
  );

  openWith(token: string, uploadData?: PlainTextInfluencerUploadData): void {
    this.broncoToken.set(token);
    this.uploadData.set(uploadData);
    this.isLoaded.set(false);
    this.open();
    this.subscribeBronco(token);
  }

  async subscribeBronco(token: string): Promise<void> {
    try {
      const broncoSubscription =
        this.broncoService.subscribeWithoutToast(token);

      broncoSubscription.taskChange$.subscribe((val) => {
        this.loadingProgress.set(val.progress ?? 0);
        this.progressMessage.set(val.progress_msg ?? '');
      });

      broncoSubscription.wait().then((task) => {
        this.isLoaded.set(true);

        if (isNotEmptyString(task.output)) {
          this.uploadResult.set(
            UploadInfluencersResult.fromJsonString(task.output),
          );
          this.result$.emit(this.uploadResult());
        }
      });
    } catch (e) {
      showToastException(this.toastManager, e);
      this.close();
    }
  }

  override close(): void {
    super.close();
    this.uploadData.set(undefined);
    this.isLoaded.set(false);
    this.progressMessage.set('');
    this.loadingProgress.set(0);
  }
}
