<lefty-page [loading]="routeLoading()">
  <lefty-section>
    <lefty-section-header>
      <h1>{{ totalHitsTitle() }}</h1>
      <button
        class="primary"
        (click)="createOrEditWorkspaceDialog.openWith()"
        action
      >
        Add workspace
      </button>
    </lefty-section-header>
    <lefty-section-header>
      <lefty-form-input
        placeholder="Find by name or owner"
        leadingGlyph="search"
        [value]="nameFilterValue()"
        (valueChange)="searchByName($event)"
        class="outline rounded search-input"
      >
      </lefty-form-input>
      <lefty-sort-select
        placeholder="Default (Members count)"
        [selection]="sortByValue()"
        (selectionChange)="sortChange($event)"
        [itemRenderer]="sortRenderer"
        [options]="sortOptions"
        action
      >
      </lefty-sort-select>
      <search-and-select-dropdown
        multi
        popupPlacement="bottom-end"
        [hideSearch]="true"
        [selection]="subscriptionFilterValue()"
        buttonClass="outline"
        placeholder="All subscriptions"
        (selectionChange)="subscriptionChange($event)"
        [itemRenderer]="subscriptionRenderer"
        [options]="subscriptionOptions"
        action
      ></search-and-select-dropdown>
      <networks-selector-dropdown
        popupPlacement="bottom-end"
        label=""
        [selection]="networkFilterValue()"
        buttonClass="outline"
        (selectionChange)="networkChange($event)"
        [networks]="networkOptions"
        action
      ></networks-selector-dropdown>
    </lefty-section-header>
    <workspaces-list
      [teams]="teams()"
      (workspaceToEdit$)="createOrEditWorkspaceDialog.openWith($event)"
    ></workspaces-list>
    <intersection-observer (trigger$)="nextPage()"></intersection-observer>
  </lefty-section>
</lefty-page>

<create-or-edit-workspace-dialog
  #createOrEditWorkspaceDialog
  (success$)="refreshView($event)"
></create-or-edit-workspace-dialog>
