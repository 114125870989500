import { NgModule } from '@angular/core';
import { MethodSelectionDialogComponent } from './method-selection-dialog/method-selection-dialog.component';
import { PlainTextMethodDialogComponent } from './plain-text-method-dialog/plain-text-method-dialog.component';
import { SearchCreatorMethodDialogComponent } from './search-creator-method-dialog/search-creator-method-dialog.component';
import { SelectFromDirectoryInfluencerRowItemComponent } from './select-from-directory-dialog/influencer-row-item/influencer-row-item.component';
import { SelectInfluencersFromDirectoryDialogComponent } from './select-from-directory-dialog/select-from-directory-dialog.component';
import { LeftySelectInfluencersDialogComponent } from './select-influencers-dialog.component';
import { LeftyUploadInfluencerLoaderDialogComponent } from './upload-influencer-loader-dialog/upload-influencer-loader-dialog.component';
import { InfluencerUploadResultComponent } from './upload-influencer-loader-dialog/upload-result/influencer-upload-result.component';

@NgModule({
  imports: [
    LeftySelectInfluencersDialogComponent,
    MethodSelectionDialogComponent,
    SearchCreatorMethodDialogComponent,
    PlainTextMethodDialogComponent,
    SelectInfluencersFromDirectoryDialogComponent,
    SelectFromDirectoryInfluencerRowItemComponent,
    LeftyUploadInfluencerLoaderDialogComponent,
    InfluencerUploadResultComponent,
  ],
  exports: [
    LeftySelectInfluencersDialogComponent,
    SelectInfluencersFromDirectoryDialogComponent,
    LeftyUploadInfluencerLoaderDialogComponent,
  ],
})
export class LeftyInfluencersManagementModule {}
