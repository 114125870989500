import { NgFor, NgIf } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Output,
  ViewChild,
} from '@angular/core';
import { FormsModule, NgForm } from '@angular/forms';
import { ConnectError } from '@connectrpc/connect';
import {
  capitalize,
  getEnumValues,
  isNil,
  isNotNil,
  mediaValueCalculatorMethodToReadable,
} from '@frontend2/core';
import { MediaValueCalculatorMethod } from '@frontend2/proto/common/proto/common_pb';
import {
  Account,
  AutocompleteCustomer,
  CreateTeamReq,
  EditCalculatorMethodReq,
  TeamDetails,
  TeamUser,
  UpdateTeamReq,
} from '@frontend2/proto/librarian/proto/admin_pb';
import {
  DialogBase,
  ItemRenderer,
  LeftyCheckboxComponent,
  LeftyDialogComponent,
  LeftyFormAutocompleteComponent,
  LeftyFormInputComponent,
  LeftyFormSelectComponent,
  LeftyIconComponent,
  LeftySpinnerComponent,
  ToastManager,
} from '@frontend2/ui';
import { Subject, debounceTime } from 'rxjs';
import { CreateOrEditWorkspaceService } from './create-or-edit-workspace.service';

@Component({
  selector: 'create-or-edit-workspace-dialog',
  templateUrl: 'create-or-edit-workspace-dialog.component.html',
  styleUrls: ['create-or-edit-workspace-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    LeftyDialogComponent,
    FormsModule,
    LeftyFormInputComponent,
    LeftyFormAutocompleteComponent,
    LeftyFormSelectComponent,
    LeftyCheckboxComponent,
    NgIf,
    NgFor,
    LeftyIconComponent,
    LeftySpinnerComponent,
  ],
})
export class CreateOrEditWorkspaceDialogComponent extends DialogBase {
  constructor(
    private createOrEditWorkspaceService: CreateOrEditWorkspaceService,
    private toastManager: ToastManager,
  ) {
    super();
    this.disposer.add(this.success$);
    this.disposer.add(this.ownerSearchValue$);
    this.disposer.add(this.memberSearchValue$);

    this.ownerSearchValue$
      .pipe(debounceTime(300))
      .subscribe((val) => this.searchCustomer(val, true));

    this.memberSearchValue$
      .pipe(debounceTime(300))
      .subscribe((val) => this.searchCustomer(val, false));
  }

  @Output()
  readonly success$ = new Subject<TeamDetails>();

  ownerSearchValue$ = new Subject<string>();

  memberSearchValue$ = new Subject<string>();

  creationLoading = false;

  ownerOptions: AutocompleteCustomer[] = [];

  membersOptions: AutocompleteCustomer[] = [];

  membersToAdd: AutocompleteCustomer[] = [];

  ownerToAdd: AutocompleteCustomer | undefined;

  emptyMember = new AutocompleteCustomer();

  workspaceName = '';

  searchValue = '';

  isCanary = true;

  mediaValCalculator: MediaValueCalculatorMethod =
    MediaValueCalculatorMethod.DEFAULT;

  edit = false;

  teamDets!: TeamDetails;

  @ViewChild('teamForm')
  teamForm!: NgForm;

  async openWith(userOrTeam?: Account | TeamUser | TeamDetails): Promise<void> {
    this.resetForm();
    if (isNotNil(userOrTeam)) {
      if (userOrTeam instanceof Account) {
        this.edit = false;
        this.ownerToAdd = new AutocompleteCustomer({
          email: userOrTeam.email,
          id: userOrTeam.userAccountId,
        });
        this.searchValue = this.ownerToAdd.email;
      } else {
        if (userOrTeam instanceof TeamUser) {
          this.teamDets =
            await this.createOrEditWorkspaceService.getTeamDetailsById(
              userOrTeam.teamId,
            );
        } else if (userOrTeam instanceof TeamDetails) {
          this.teamDets = userOrTeam;
        }
        const { mediaValueCalculatorMethod, name, isCanary, owner } =
          this.teamDets;
        this.edit = true;
        this.mediaValCalculator = mediaValueCalculatorMethod;
        this.workspaceName = name;
        this.isCanary = isCanary;
        if (owner) {
          const { email, userAccountId } = owner;
          this.ownerToAdd = new AutocompleteCustomer({
            email,
            id: userAccountId,
          });
          this.searchValue = email;
        }
      }
    }
    this.changeDetection.markForCheck();
    this.open();
  }

  customerRenderer: ItemRenderer<AutocompleteCustomer> = (customer) =>
    customer.email;

  resetForm(): void {
    this.isCanary = true;
    this.searchValue = '';
    this.workspaceName = '';
    this.ownerToAdd = undefined;
    this.membersToAdd = [this.emptyMember];
    this.mediaValCalculator = MediaValueCalculatorMethod.DEFAULT;
  }

  addField(): void {
    this.membersToAdd = [...this.membersToAdd, this.emptyMember];
    this.changeDetection.markForCheck();
  }

  closeField(index: number): void {
    if (this.membersToAdd && this.membersToAdd.length > index) {
      this.membersToAdd.splice(index, 1);
      this.changeDetection.markForCheck();
    }
  }

  selectMember(
    index: number,
    customer: AutocompleteCustomer | undefined,
  ): void {
    if (isNil(customer)) {
      return;
    }
    this.membersToAdd[index] = customer;
    this.membersOptions = [];
    this.changeDetection.markForCheck();
  }

  calculatorRenderer(method: MediaValueCalculatorMethod): string {
    return capitalize(mediaValueCalculatorMethodToReadable(method));
  }

  searchValueChange(customerText: string): void {
    if (customerText === this.searchValue) {
      return;
    }
    this.setState(() => (this.searchValue = customerText));
    this.ownerSearchValue$.next(customerText);
  }

  memberChanged(customerText: string): void {
    this.memberSearchValue$.next(customerText);
  }

  async searchCustomer(
    customerText: string,
    isSearchOwner: boolean,
  ): Promise<void> {
    if (isSearchOwner) {
      this.ownerOptions = (
        await this.createOrEditWorkspaceService.getAutoCompleteOptions(
          customerText,
        )
      ).autocompleteCustomers;
    } else {
      this.membersOptions = (
        await this.createOrEditWorkspaceService.getAutoCompleteOptions(
          customerText,
        )
      ).autocompleteCustomers;
    }

    this.changeDetection.markForCheck();
  }

  trackBy(index: number): number {
    return index;
  }

  async submit(): Promise<void> {
    if (!this.teamForm.valid) {
      return;
    }
    this.setState(() => (this.creationLoading = true));
    try {
      let team: TeamDetails;
      if (this.edit) {
        team = await this._editTeam();
        this.toastManager.showSuccess(`Workspace edited with success.`);
      } else {
        team = await this._createTeam();
        this.toastManager.showSuccess(
          `Workspace <b>${team.name}</b> created with success.`,
        );
      }
      this.success$.next(team);
      this.close();
    } catch (e: unknown) {
      if (e instanceof ConnectError) {
        this.toastManager.showError(e.message);
        this.changeDetection.markForCheck();
      }
    } finally {
      this.setState(() => (this.creationLoading = false));
    }
  }

  private async _createTeam(): Promise<TeamDetails> {
    const membersId = this.membersToAdd
      .filter((p) => p !== this.emptyMember)
      .map((p) => p.id);

    const team = await this.createOrEditWorkspaceService.create(
      new CreateTeamReq({
        name: this.workspaceName,
        ownerId: this.ownerToAdd?.id,
        membersId: membersId,
        isCanary: this.isCanary,
      }),
    );
    if (this.updateMethodCalculator && isNotNil(this.ownerToAdd)) {
      this._updateMethodCalculator(this.ownerToAdd.id);
    }
    return team;
  }

  private async _updateMethodCalculator(
    userId: bigint,
  ): Promise<MediaValueCalculatorMethod> {
    const request = new EditCalculatorMethodReq({
      mediaValueCalculatorMethod: this.mediaValCalculator,
      ownerId: userId,
    });
    await this.createOrEditWorkspaceService.updateMethodCalculator(request);
    return this.mediaValCalculator;
  }

  private async _editTeam(): Promise<TeamDetails> {
    const newTeamDetails = this.teamDets.clone();
    const updateReq = new UpdateTeamReq({
      teamId: this.teamDets.id,
      isCanary: this.isCanary,
    });
    if (this.teamDets.name !== this.workspaceName) {
      updateReq.newName = this.workspaceName;
      newTeamDetails.name = this.workspaceName;
    }
    if (this.teamDets.owner?.userAccountId !== this.ownerToAdd?.id) {
      updateReq.newOwnerId = this.ownerToAdd
        ? this.getUserIdByUserAccountId(this.ownerToAdd.id)
        : BigInt(0);
      newTeamDetails.owner = new TeamUser({
        userAccountId: this.ownerToAdd?.id,
      });
    }
    await this.createOrEditWorkspaceService.edit(updateReq);
    if (this.updateMethodCalculator && isNotNil(this.ownerToAdd)) {
      await this._updateMethodCalculator(this.ownerToAdd.id);
      newTeamDetails.mediaValueCalculatorMethod = this.mediaValCalculator;
    }
    return newTeamDetails;
  }

  private getUserIdByUserAccountId(userAccId: bigint): bigint | undefined {
    return this.teamDets.members.find(
      (user) => user.userAccountId === userAccId,
    )?.userId;
  }

  get title(): string {
    return this.edit
      ? `Edit "${this.teamDets.name}" workspace`
      : 'Create workspace';
  }

  get emvCalculators(): MediaValueCalculatorMethod[] {
    const availableMethodCalculator = getEnumValues<MediaValueCalculatorMethod>(
      MediaValueCalculatorMethod,
    );
    return availableMethodCalculator;
  }

  get formattedMethodCalculator(): string {
    return this.calculatorRenderer(this.mediaValCalculator);
  }

  get updateMethodCalculator(): boolean {
    return this.mediaValCalculator !== MediaValueCalculatorMethod.DEFAULT;
  }
}
