import { Injectable, Inject, computed } from '@angular/core';
import { Empty } from '@bufbuild/protobuf';
import { CacheBloc } from '../bloc';
import { SsoProvidersList } from '@frontend2/proto/librarian/proto/frontend_misc_pb';
import {
  LibrarianAdminClientProvider,
  LibrarianAdminClient,
} from '@frontend2/api';

@Injectable({ providedIn: 'root' })
export class SsoProvidersCache extends CacheBloc<SsoProvidersList> {
  constructor(
    @Inject(LibrarianAdminClientProvider)
    private librarian: LibrarianAdminClient,
  ) {
    super(new SsoProvidersList());
  }

  readonly providersList = computed(() => this.cachedData().values);

  override async fetch(): Promise<SsoProvidersList> {
    return await this.librarian.getSsoProvidersAPI(new Empty());
  }
}
