import {
  ChangeDetectionStrategy,
  Component,
  Input,
  Output,
} from '@angular/core';
import {
  copyToClipboard,
  formatDate,
  subscriptionToReadable,
} from '@frontend2/core';
import { TeamUser } from '@frontend2/proto/librarian/proto/admin_pb';
import {
  LeftyButtonDirective,
  LeftyCardComponent,
  LeftyComponent,
  LeftyListItemComponent,
  LeftyMenuIconComponent,
  ToastManager,
  TooltipIfEllipsisDirective,
} from '@frontend2/ui';
import { Subject } from 'rxjs';

@Component({
  selector: 'workspace-list-item',
  templateUrl: 'workspace-item.component.html',
  styleUrls: ['workspace-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    LeftyCardComponent,
    TooltipIfEllipsisDirective,
    LeftyButtonDirective,
    LeftyMenuIconComponent,
    LeftyListItemComponent,
  ],
})
export class WorkspaceItemComponent extends LeftyComponent {
  constructor(private toastManager: ToastManager) {
    super();
    this.disposer.add(this.workspaceToEdit$);
  }

  @Input()
  user = new TeamUser();

  @Output()
  readonly workspaceToEdit$ = new Subject<void>();

  @Output()
  readonly subscriptionToEdit$ = new Subject<void>();

  editWorkspace(): void {
    this.workspaceToEdit$.next();
  }

  editSubscription(): void {
    this.subscriptionToEdit$.next();
  }

  stayInPage(event: Event): void {
    event.preventDefault();
    event.stopPropagation();
  }

  get membersCount(): string {
    return (
      this.user.teamMembersCount.toString() +
      (this.user.teamMembersCount > 1 ? ' members' : ' member')
    );
  }

  get createdFormatted(): string {
    return formatDate(this.user.teamCreation?.toDate() ?? '');
  }

  get ownerInfo(): string {
    return this.user.owner?.userId.toString() + ' - ' + this.user.owner?.email;
  }

  get teamDetails(): string {
    return this.user.teamId !== BigInt(0)
      ? this.user.teamName
      : 'Not member of a team';
  }

  get teamSubscription(): string {
    return subscriptionToReadable(this.user.teamSubscription);
  }

  userHasTeamId(user: TeamUser): boolean {
    return user.teamId !== BigInt(0);
  }

  async copyEmail(e: MouseEvent): Promise<void> {
    e.preventDefault();
    e.stopPropagation();
    if (this.user.owner?.email) {
      await copyToClipboard(this.user.owner?.email);
      this.toastManager.showSuccess(
        '"' + this.user.owner?.email + '" copied to clipboard',
      );
    }
  }
}
