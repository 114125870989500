<lefty-dialog
  [(visible)]="visible"
  size="small"
  [hideFooter]="!isLoaded()"
  [hideCloseButton]="!isLoaded()"
  [autoDismissable]="false"
>
  <h3
    title
    i18n
    class="title"
  >
    @if (hasUploadData()) {
      Importing {{ influencersCount() }}
      <network-icon [network]="network()"></network-icon>
      {{ readableNetwork() }}
      influencers
    } @else {
      Importing influencers
    }
  </h3>
  <div class="container">
    @if (isLoaded()) {
      <influencer-upload-result
        [result]="uploadResult()"
      ></influencer-upload-result>
    } @else {
      <div class="progress">
        <div class="desc">
          <p>{{ formattedLoadingProgress() }} - {{ progressMessage() }}</p>
          <lefty-spinner></lefty-spinner>
        </div>

        <lefty-progress
          [activeProgress]="loadingProgress()"
          [max]="100"
        ></lefty-progress>
      </div>
    }
  </div>
  <ng-container footer>
    <button
      (click)="close()"
      leftyButton="primary"
      i18n
    >
      Close
    </button>
  </ng-container>
</lefty-dialog>
