import { Inject, Injectable } from '@angular/core';
import { Empty } from '@bufbuild/protobuf';
import {
  LibrarianAdminClient,
  LibrarianAdminClientProvider,
} from '@frontend2/api';
import { isNotEmptyString } from '@frontend2/core';
import {
  BroncoService,
  TOAST_TYPE_ERROR,
  TOAST_TYPE_SUCCESS,
  Toast,
  ToastManager,
} from '@frontend2/ui';

@Injectable({ providedIn: 'root' })
export class SyncChargebeeService {
  constructor(
    private _toastManager: ToastManager,
    @Inject(LibrarianAdminClientProvider)
    private _librarian: LibrarianAdminClient,
    private _bronco: BroncoService,
  ) {}

  readonly _syncingMessage = 'Syncing ChargeBee';
  readonly _successMessage = 'Chargebee successfully synchronized';
  readonly _failedMessage = 'Failed to synchronize Chargebee';

  private _subscribeBronco(handle: string, existingToast?: Toast): void {
    this._bronco.subscribe(handle, {
      successMessage: () => this._successMessage,
      errorMessage: () => this._failedMessage,
      progressMessage: () => this._syncingMessage,
      toast: existingToast,
    });
  }

  async sync(): Promise<void> {
    //Display loading toast before we get the bronco handle

    //So user does not wait server response
    //to get a visual feedback when click on button
    const toast = this._toastManager.showLoading(this._syncingMessage);

    try {
      const token = await this._librarian.syncChargebeeAPI(new Empty());

      if (isNotEmptyString(token.value)) {
        this._subscribeBronco(token.value, toast);
      } else {
        this._toastManager.update(toast.id, {
          text: this._successMessage,
          type: TOAST_TYPE_SUCCESS,
        });
      }
    } catch (e) {
      this._toastManager.update(toast.id, {
        text: this._failedMessage,
        type: TOAST_TYPE_ERROR,
      });
    }
  }
}
