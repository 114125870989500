import {
  ChangeDetectionStrategy,
  Component,
  Optional,
  Self,
} from '@angular/core';
import { NgControl } from '@angular/forms';
import { isNil } from '@frontend2/core';
import { LogicOperator } from '@frontend2/proto/common/proto/common_pb';
import { LeftyFormValueBase } from '../form';
import { readableLogicOperator } from './logic-operator.models';
import { LeftyRadioComponent } from '../lefty-radio/lefty-radio.component';
import { NgFor } from '@angular/common';
import { LeftyRadioGroupComponent } from '../lefty-radio/lefty-radio-group.component';
import { LeftyFormComponent } from '../lefty-form/lefty-form.component';

@Component({
  selector: 'lefty-logic-operator-selector',
  templateUrl: './logic-operator.component.html',
  styleUrls: ['./logic-operator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    LeftyFormComponent,
    LeftyRadioGroupComponent,
    NgFor,
    LeftyRadioComponent,
  ],
})
export class LeftyLogicOperatorComponent extends LeftyFormValueBase<LogicOperator> {
  constructor(@Optional() @Self() public override ngControl?: NgControl) {
    super(LogicOperator.UNKNOWN_OPERATOR);
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
  }

  readonly logicOperatorOptions: LogicOperator[] = [
    LogicOperator.OR,
    LogicOperator.AND,
  ];

  get logicOperator(): LogicOperator {
    return this._logicOperator;
  }

  private _logicOperator: LogicOperator = LogicOperator.OR;

  readableLogicOperator(logicOperator: LogicOperator): string {
    return readableLogicOperator(logicOperator);
  }

  handleLogicOperator(ev: LogicOperator | undefined): void {
    if (isNil(ev)) {
      return;
    }
    this._logicOperator = ev;
    this.handleValueChange(this._logicOperator);
  }

  override writeValue(obj: LogicOperator): void {
    this.value = obj;
  }
}
