<form
  class="grid"
  [formGroup]="formModel"
>
  <div class="col-12">
    <search-and-select-dropdown
      #country
      multi
      class="countries-input"
      formControlName="countries"
      [options]="availableCountries()"
      label="Countries"
      placeholder="any country"
      [itemRenderer]="readableCountry"
    ></search-and-select-dropdown>
  </div>
  <div class="col-12">
    <search-and-select-dropdown
      #category
      multi
      class="categories-input"
      formControlName="categories"
      [options]="availableCategories()"
      label="Categories"
      placeholder="any category"
      [itemRenderer]="readableCategory"
    ></search-and-select-dropdown>
  </div>
  <div class="col-12">
    <lefty-form-number-input
      label="Min. followers"
      class="minfollowers-input"
      formControlName="minFollowers"
      [disallowSignedValue]="true"
    >
    </lefty-form-number-input>
  </div>
</form>
