import { Inject, Injectable } from '@angular/core';
import {
  LibrarianAdminClient,
  LibrarianAdminClientProvider,
} from '@frontend2/api';
import {
  Account,
  CreateUserRequest,
  EditUserRequest,
} from '@frontend2/proto/librarian/proto/admin_pb';
import { ToastManager } from '@frontend2/ui';

@Injectable({ providedIn: 'root' })
export class CreateOrEditUserService {
  constructor(
    private toastManager: ToastManager,
    @Inject(LibrarianAdminClientProvider)
    private librarian: LibrarianAdminClient,
  ) {}

  async create(req: CreateUserRequest): Promise<Account> {
    const profile = await this.librarian.createUserAPI(req);

    this.toastManager.showSuccess(
      `User <b>${profile.email}</b> created with success.`,
    );
    return profile;
  }

  async edit(req: EditUserRequest): Promise<Account> {
    const profile = await this.librarian.editUserAPI(req);
    this.toastManager.showSuccess(
      `User <b>${profile.email}</b> edited with success.`,
    );
    return profile;
  }
}
