<lefty-form>
  <lefty-radio-group
    (selectedChange)="handleLogicOperator($event)"
    [selected]="logicOperator"
  >
    <lefty-radio
      *ngFor="let option of logicOperatorOptions"
      [value]="option"
      [checked]="option === logicOperator"
    >
      {{ readableLogicOperator(option) }}
    </lefty-radio>
  </lefty-radio-group>
</lefty-form>
