import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
} from '@angular/core';

import { createOutput } from '../../utils';
import {
  ButtonType,
  LeftyButtonDirective,
} from '../../lefty-button-directive/lefty-button.directive';
import { DialogBase } from '../lefty-dialog.helpers';
import { LeftyDialogComponent } from '../lefty-dialog.component';

@Component({
  selector: 'lefty-yes-no-dialog',
  templateUrl: 'lefty-yes-no-dialog.component.html',
  styleUrls: ['lefty-yes-no-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [LeftyDialogComponent, LeftyButtonDirective],
})
export class LeftyYesNoDialogComponent extends DialogBase {
  @Input()
  noLabel = $localize`No`;

  @Input()
  yesLabel = $localize`Yes`;

  @Input()
  loading = false;

  @Input()
  disabled = false;

  @Input()
  title = '';

  @Input()
  destructive = false;

  @Output()
  readonly yes$ = createOutput<void>();

  @Output()
  readonly no$ = createOutput<void>();

  get yesButtonType(): ButtonType {
    return this.destructive ? 'destructive' : 'primary';
  }

  no(): void {
    this.no$.next();
    this.close();
  }

  yes(): void {
    this.yes$.next();
  }
}
