import { Component, inject } from '@angular/core';
import { LeftyAuthBloc } from '@frontend2/api';
import {
  FirebaseBloc,
  LeftyComponent,
  NavItem,
  buildNavItem,
} from '@frontend2/ui';
import { environment } from '../environments/environment';
import { SyncChargebeeService } from './common/sync-chargebee.service';
import {
  CUSTOMER_PATH,
  CW_BRANDS_PATH,
  CW_REPORTS_PATH,
  CW_SETS_PATH,
  CW_SETTINGS_PATH,
  TEAMS_PATH,
  TOOLS_DEBUG_PATH,
  TOOLS_JOBS_PATH,
  TOOLS_MERGE_PATH,
  TOOLS_MERGE_REQUEST_PATH,
  TOOLS_UPLOAD_STORY_PATH,
} from './constants';

@Component({
  selector: 'flanders-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class FlandersAppComponent extends LeftyComponent {
  constructor(
    public auth: LeftyAuthBloc,
    private _syncChargebee: SyncChargebeeService,
  ) {
    super();

    inject(FirebaseBloc).subscribeUserSignin(auth.state$);

    this.watch(this.auth.state$);
  }

  get isAdmin(): boolean {
    return this.auth.isAdmin;
  }

  readonly isProd = environment.isProduction;

  menuItems: NavItem[] = [
    buildNavItem('Users', CUSTOMER_PATH),
    buildNavItem('Workspaces', TEAMS_PATH),
    buildNavItem('Competitive Watch', '', {
      children: [
        buildNavItem('Reports', CW_REPORTS_PATH),
        buildNavItem('Brands', CW_BRANDS_PATH),
        buildNavItem('Sets', CW_SETS_PATH),
        buildNavItem('Settings', CW_SETTINGS_PATH),
      ],
    }),
    buildNavItem('Tools', '', {
      children: [
        buildNavItem('Debug', TOOLS_DEBUG_PATH),
        buildNavItem('Jobs', TOOLS_JOBS_PATH),
        buildNavItem('Stories upload', TOOLS_UPLOAD_STORY_PATH),
        buildNavItem('Merge profiles', TOOLS_MERGE_PATH),
        buildNavItem('Merge requests', TOOLS_MERGE_REQUEST_PATH, {
          isHidden: this.isProd,
        }),
      ],
    }),
  ];

  syncChargebee(): void {
    this._syncChargebee.sync();
  }
}
