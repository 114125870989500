import { inject } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { LeftyAuthBloc } from '@frontend2/api';

/**
 * If the user is already connected as and ADMIN.
 * we don't want him to acces LOGIN route, we redirect him to Home page
 */
export const flandersLoginGuard = (): boolean | UrlTree => {
  const router = inject(Router);
  const auth = inject(LeftyAuthBloc);

  // redirect to main path if already logged in
  if (auth.isAdmin) {
    return router.parseUrl('/');
  }
  return true;
};
