import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import { isNotNil } from '@frontend2/core';
import { Subject } from 'rxjs';
import { LeftyComponent } from '../utils';
import { NgIf } from '@angular/common';

// Must be use with lefty-filters-button
@Component({
  selector: 'lefty-filters-grid',
  templateUrl: 'lefty-filters-grid.component.html',
  styleUrls: ['lefty-filters-grid.component.scss'],
  standalone: true,
  imports: [NgIf],
})
export class LeftyFiltersGridComponent
  extends LeftyComponent
  implements AfterViewInit, OnDestroy
{
  constructor() {
    super();

    this.disposer.add(this.clear$);
  }

  @Output()
  readonly clear$ = new Subject<Event>();

  private _resizeObserver?: ResizeObserver;

  private _filtersCount = 0;

  set filtersCount(val: number) {
    this.setState(() => (this._filtersCount = val));
  }

  get filtersCount(): number {
    return this._filtersCount;
  }

  private _filtersVisible = false;

  toggleFilters(): void {
    this.setState(() => {
      this._filtersVisible = !this._filtersVisible;
    });
  }

  get filtersVisible(): boolean {
    return this._filtersVisible;
  }

  get _filtersHeight(): number {
    return this._gridRef?.nativeElement.clientHeight ?? 0;
  }

  private _gridRef?: ElementRef<HTMLElement>;

  @ViewChild('filtersGrid')
  set filtersGrid(ref: ElementRef<HTMLElement>) {
    this._gridRef = ref;
  }

  get filtersHeight(): number {
    return this._filtersVisible ? this._filtersHeight : 0;
  }

  ngAfterViewInit(): void {
    this._resizeObserver = new ResizeObserver(() => {
      this.changeDetection.markForCheck();
    });
    if (isNotNil(this._gridRef)) {
      this._resizeObserver.observe(this._gridRef.nativeElement);
    }
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
    if (isNotNil(this._gridRef)) {
      this._resizeObserver?.unobserve(this._gridRef?.nativeElement);
    }
  }
}
