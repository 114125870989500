import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { UiModule } from '@frontend2/ui';
import { CreateOrEditCustomerDialogComponent } from './create-or-edit-customer-dialog/create-or-edit-customer-dialog.component';
import { CreateOrEditWorkspaceDialogComponent } from './create-or-edit-workspace-dialog/create-or-edit-workspace-dialog.component';
import { CustomerItemComponent } from './customer-item/customer-item.component';
import { AddSourceDialogComponent } from './cw-add-source-to-set-dialog/add-source-dialog.component';
import { CwAuxiliarySourceFormComponent } from './cw-add-source-to-set-dialog/auxiliary-form/auxiliary-source-form.component';
import { CwDirectorySourceFormComponent } from './cw-add-source-to-set-dialog/directory-form/directory-source-form.component';
import { CwDiscoverSourceFormComponent } from './cw-add-source-to-set-dialog/discover-form/discover-source-form.component';
import { CwLeftySourceFormComponent } from './cw-add-source-to-set-dialog/lefty-form/lefty-source-form.component';
import { CwManualSourceFormComponent } from './cw-add-source-to-set-dialog/manual-form/manual-source-form.component';
import { ArchiveReportDialogComponent } from './cw-archive-report-dialog/archive-report-dialog.component';
import { ArchiveSetDialogComponent } from './cw-archive-set-dialog/archive-set-dialog.component';
import { CreateOrEditReportDialogComponent } from './cw-create-or-edit-report-dialog/create-or-edit-report-dialog.component';
import { DuplicateReportDialogComponent } from './cw-duplicate-report-dialog /duplicate-report-dialog.component';
import { EditSetDialogComponent } from './cw-edit-set-dialog/edit-set-dialog.component';
import { EditSubscriptionDialogComponent } from './edit-subscription-dialog/edit-subscription-dialog.component';
import { AuxiliarySourceCardComponent } from './sources-list/source-card/auxiliary-source-card.component';
import { DirectorySourceCardComponent } from './sources-list/source-card/directory-source-card.component';
import { DiscoverSourceCardComponent } from './sources-list/source-card/discover-source-card.component';
import { LeftySourceCardComponent } from './sources-list/source-card/lefty-source-card.component';
import { ManualSourceCardComponent } from './sources-list/source-card/manual-source-card.component';
import { SourcesListComponent } from './sources-list/sources-list.component';
import { WorkspaceAutoCompleteComponent } from './workspace-autocomplete/workspace-autocomplete.component';

@NgModule({
    imports: [
        UiModule,
        CommonModule,
        RouterModule.forChild([]),
        FormsModule,
        ReactiveFormsModule,
        WorkspaceAutoCompleteComponent,
        CreateOrEditWorkspaceDialogComponent,
        EditSubscriptionDialogComponent,
        CreateOrEditCustomerDialogComponent,
        DuplicateReportDialogComponent,
        CreateOrEditReportDialogComponent,
        ArchiveReportDialogComponent,
        AuxiliarySourceCardComponent,
        DirectorySourceCardComponent,
        SourcesListComponent,
        DiscoverSourceCardComponent,
        LeftySourceCardComponent,
        AddSourceDialogComponent,
        CwAuxiliarySourceFormComponent,
        ArchiveSetDialogComponent,
        EditSetDialogComponent,
        CwDirectorySourceFormComponent,
        CwDiscoverSourceFormComponent,
        CwLeftySourceFormComponent,
        CwManualSourceFormComponent,
        ManualSourceCardComponent,
        CustomerItemComponent,
    ],
    exports: [
        CreateOrEditWorkspaceDialogComponent,
        EditSubscriptionDialogComponent,
        CreateOrEditCustomerDialogComponent,
        DuplicateReportDialogComponent,
        CreateOrEditReportDialogComponent,
        ArchiveReportDialogComponent,
        SourcesListComponent,
        AddSourceDialogComponent,
        ArchiveSetDialogComponent,
        EditSetDialogComponent,
        CustomerItemComponent,
    ],
})
export class CommonComponentsModule {}
