<lefty-dialog
  [autoDismissable]="true"
  size="medium"
  [(visible)]="visible"
  (closedWithCloseButtonOrDismiss$)="resetState()"
>
  <h3
    title
    i18n
  >
    Add influencers
  </h3>
  <div class="container">
    <network-form-select
      [(value)]="network"
      [networks]="availableNetworks"
    ></network-form-select>
    <lefty-form-textarea
      label="Enter usernames or profile URLs"
      hintText="One Influencer per line"
      i18n-label
      [placeholder]="textareaPlaceholder()"
      [rows]="10"
      [(value)]="textareaVal"
    ></lefty-form-textarea>
  </div>

  <ng-container footer>
    <button
      leftyButton="secondary"
      i18n
      (click)="back()"
    >
      Back
    </button>
    <button
      leftyButton="primary"
      i18n
      (click)="save()"
    >
      Save
    </button>
  </ng-container>
</lefty-dialog>
