<div
  class="form-label"
  *ngIf="showLabelContainer()"
>
  {{ label() }}
</div>
<div
  class="container"
  [class.compact]="compactGap()"
>
  <lefty-date-picker
    class="form-input"
    [value]="value()"
    [maxDate]="maxDate()"
    [minDate]="minDate()"
    (valueChange)="dateChange($event)"
    [required]="required()"
  ></lefty-date-picker>

  <lefty-time-picker
    class="form-input"
    placeholder="select time"
    [steps]="timeStep()"
    (valueChange)="timeChange($event)"
    [value]="timeValue()"
    [required]="required()"
    [minTime]="minTime()"
    [maxTime]="maxTime()"
  ></lefty-time-picker>
</div>
