<lefty-dialog
  [autoDismissable]="true"
  size="medium"
  [(visible)]="visible"
  (closedWithCloseButtonOrDismiss$)="resetState()"
>
  <h3
    title
    i18n
  >
    Add influencers
  </h3>

  <div description>
    <lefty-creator-search-with-network
      [(value)]="influencerAutocompleteValue"
      [hideSearchItem]="true"
      [(network)]="network"
      [excludedIfluencerIds]="selectionIds()"
    ></lefty-creator-search-with-network>
  </div>
  <div class="content">
    <div class="selection-list">
      @for (influencer of selection(); track influencer.creator?.userId) {
        <lefty-card
          [withBorder]="true"
          class="influencer-card"
        >
          <profile-picture [creator]="getCreator(influencer)"></profile-picture>
          <div class="info fill-space">
            <p class="body-1">{{ getCreator(influencer).userName }}</p>
            <p class="followers body-1">
              {{ getFollowerCountForNetwork(influencer) }}
            </p>
          </div>
          <div class="networks">
            @for (network of getInfluencerNetworks(influencer); track $index) {
              <network-icon [network]="network"></network-icon>
            }
          </div>
          <button
            leftyButton="secondary"
            icon="delete"
            class="delete-button"
            size="small"
            (click)="deleteAtIndex($index)"
          ></button>
        </lefty-card>
      } @empty {
        <div class="empty">
          <p i18n>No influencer yet.</p>
          <p i18n>Start by selecting your first influencer.</p>
        </div>
      }
    </div>
  </div>

  <ng-container footer>
    <button
      leftyButton="secondary"
      i18n
      (click)="back()"
    >
      Back
    </button>
    <button
      leftyButton="primary"
      i18n
      (click)="save()"
    >
      Save
    </button>
  </ng-container>
</lefty-dialog>
