import { Injectable } from '@angular/core';
import {
  buildDefaultRouteState,
  getEnumName,
  isGhostTeam,
  isNil,
  isNotEmptyString,
  MapStringString,
  parseEnumString,
  RouteParams,
} from '@frontend2/core';
import {
  BasicPagination,
  Network,
} from '@frontend2/proto/common/proto/common_pb';
import {
  TeamDetails,
  TeamsResponse,
  TeamsResponse_TeamLight,
} from '@frontend2/proto/librarian/proto/admin_pb';
import { SubscriptionType } from '@frontend2/proto/librarian/proto/payments_pb';
import { RouteWithPaginationBloc } from '@frontend2/ui';
import { WorkspacesCache } from '../common/workspaces.cache';
import {
  createWorkspacesListRequest,
  createWorkspacesListState,
} from './workspaces.helpers';
import {
  WorkspacesListRequest,
  WorkspacesSort,
  WorskpacesListState,
} from './workspaces.models';

@Injectable()
export class WorkspacesListBloc extends RouteWithPaginationBloc<
  WorkspacesListRequest,
  WorskpacesListState
> {
  constructor(private teamsCache: WorkspacesCache) {
    super({
      initialState: buildDefaultRouteState(
        createWorkspacesListRequest(),
        createWorkspacesListState(),
      ),
    });
  }

  override get paginationSize(): number {
    return 15;
  }

  protected override getTotalHits(
    request: WorkspacesListRequest,
    viewState: WorskpacesListState,
  ): number {
    return Number(viewState.totalHits);
  }

  static readonly NAME_KEY = 'name';
  static readonly SORT_KEY = 'sortBy';
  static readonly NETWORK_KEY = 'network';
  static readonly SUBSCRIPTION_KEY = 'subscription';

  static requestToRouteParams(request: WorkspacesListRequest): RouteParams {
    const query: MapStringString = {};
    if (isNotEmptyString(request.name)) {
      query[WorkspacesListBloc.NAME_KEY] = request.name;
    }
    if (request.sortBy !== WorkspacesSort.BY_MEMBERS) {
      query[WorkspacesListBloc.SORT_KEY] =
        getEnumName(WorkspacesSort, request.sortBy) ?? '';
    }
    if (request.network.length !== 0) {
      query[WorkspacesListBloc.NETWORK_KEY] = encodeURIComponent(
        JSON.stringify(request.network),
      );
    }
    if (request.subscription.length !== 0) {
      query[WorkspacesListBloc.SUBSCRIPTION_KEY] = encodeURIComponent(
        JSON.stringify(request.subscription),
      );
    }

    return {
      parameters: {},
      queryParameters: query,
    };
  }

  protected parseRouteParams(
    params: RouteParams,
  ): WorkspacesListRequest | Promise<WorkspacesListRequest> {
    const query = params.queryParameters;
    return createWorkspacesListRequest({
      name: query[WorkspacesListBloc.NAME_KEY],
      sortBy:
        parseEnumString<WorkspacesSort>(
          WorkspacesSort,
          query[WorkspacesListBloc.SORT_KEY],
        ) ?? WorkspacesSort.BY_MEMBERS,
      network: query[WorkspacesListBloc.NETWORK_KEY]
        ? JSON.parse(decodeURIComponent(query[WorkspacesListBloc.NETWORK_KEY]))
        : [],
      subscription: query[WorkspacesListBloc.SUBSCRIPTION_KEY]
        ? JSON.parse(
            decodeURIComponent(query[WorkspacesListBloc.SUBSCRIPTION_KEY]),
          )
        : [],
    });
  }

  override beforeBuild(req: WorkspacesListRequest): void {
    super.beforeBuild(req);

    this.updateViewState((state) => {
      return {
        ...state,
        teams: [
          ...state.teams,
          ...Array(10).fill(new TeamsResponse_TeamLight()),
        ],
      };
    });
  }

  protected async build(
    req: WorkspacesListRequest,
  ): Promise<WorskpacesListState> {
    const resp = await this.teamsCache.load();
    const filteredTeams = this._applyFilters(resp.teams, req);
    const paginatedTeams = this._paginate(filteredTeams.teams, req.pagination);
    return createWorkspacesListState({
      teams: paginatedTeams,
      totalHits: BigInt(filteredTeams.teams.length),
    });
  }

  async updateTeamInView(team: TeamDetails): Promise<void> {
    this.teamsCache.updateOrAddWorkspaceInCache(team);
    this.refresh();
  }

  private _applyFilters(
    values: TeamsResponse_TeamLight[],
    req: WorkspacesListRequest,
  ): TeamsResponse {
    values = this._filterByName(values, req.name);
    values = this._filterBySubscription(values, req.subscription);
    values = this._filterByNetwork(values, req.network);
    return new TeamsResponse({ teams: this._sort(values, req.sortBy) });
  }

  private _filterByName(
    values: TeamsResponse_TeamLight[],
    searchValue: string,
  ): TeamsResponse_TeamLight[] {
    if (isNotEmptyString(searchValue)) {
      searchValue = searchValue.toLowerCase();
      return values.filter(
        (i) =>
          i.name.toLowerCase().includes(searchValue) ||
          i.owner?.email.toLowerCase().includes(searchValue) ||
          i.id.toString().includes(searchValue),
      );
    }
    return values;
  }

  private _filterByNetwork(
    values: TeamsResponse_TeamLight[],
    networks: Network[],
  ): TeamsResponse_TeamLight[] {
    if (networks.length !== 0) {
      return values.filter((i) => {
        if (i.subscription?.allowedNetworks) {
          return networks.every((network) =>
            i.subscription?.allowedNetworks.includes(network),
          );
        }
        return false;
      });
    }
    return values;
  }

  private _filterBySubscription(
    values: TeamsResponse_TeamLight[],
    subscription: SubscriptionType[],
  ): TeamsResponse_TeamLight[] {
    if (subscription.length !== 0) {
      return values.filter((i) => {
        if (i.subscription) {
          return subscription.includes(i.subscription.subscriptionType);
        }
        return false;
      });
    }
    return values;
  }

  private _sort(
    values: TeamsResponse_TeamLight[],
    sortBy: WorkspacesSort,
  ): TeamsResponse_TeamLight[] {
    switch (sortBy) {
      case WorkspacesSort.BY_DATE:
        return values.sort((a, b) => {
          if (b.created && a.created) {
            return b.created.toDate() > a.created.toDate() ? 1 : -1;
          } else if (isNil(a.created)) {
            return -1;
          } else {
            return 1;
          }
        });

      case WorkspacesSort.BY_EMAIL:
        return values.sort((a, b) => {
          if (b.owner?.email && a.owner?.email) {
            return b.owner?.email > a.owner?.email ? -1 : 1;
          } else if (isNil(a.owner?.email)) {
            return 1;
          } else {
            return -1;
          }
        });

      case WorkspacesSort.BY_MEMBERS:
        return values.sort((a, b) => b.membersCount - a.membersCount);

      default:
        return values.sort((a, b) => b.membersCount - a.membersCount);
    }
  }

  private _paginate(
    values: TeamsResponse_TeamLight[],
    pagination?: BasicPagination,
  ): TeamsResponse_TeamLight[] {
    if (isNil(pagination)) {
      pagination = new BasicPagination({ from: 0, size: this.paginationSize });
    }

    const totalHits = values.length;
    const maxIndex = Math.min(pagination.from + pagination.size, totalHits);
    return values.slice(pagination.from, maxIndex);
  }

  applyPagination(
    request: WorkspacesListRequest,
    from: number,
  ): WorkspacesListRequest {
    const pagination = request.pagination?.clone() ?? new BasicPagination();
    pagination.from = from;
    pagination.size = this.paginationSize;

    return {
      ...request,
      pagination: pagination,
    };
  }

  appendNewPage(
    currentState: WorskpacesListState,
    newPage: WorskpacesListState,
  ): WorskpacesListState {
    return createWorkspacesListState({
      ...newPage,
      teams: [
        ...currentState.teams.filter((i) => isGhostTeam(i) === false),
        ...newPage.teams,
      ],
    });
  }
}
