<lefty-dialog
  (dismiss$)="close()"
  [autoDismissable]="true"
  [(visible)]="visible"
  size="small"
>
  <h3 title>Edit subscription</h3>
  <form
    [formGroup]="formModel"
    class="grid"
  >
    <div class="col-12">
      <div class="grid">
        <div class="col-9">
          <lefty-form-select
            label="Subscription type"
            formControlName="type"
            [itemRenderer]="subscriptionRenderer"
            [options]="availableSubscriptions"
          >
          </lefty-form-select>
        </div>
        <div class="col-9">
          <networks-selector-dropdown
            [(selection)]="selectedNetworks"
            [networks]="availableNetworks"
          ></networks-selector-dropdown>
        </div>
        <div class="col-9">
          <lefty-date-picker
            label="Expiration date"
            [disabled]="!canEditSubscription"
            name="expires"
            [(value)]="expirationDate"
          >
          </lefty-date-picker>
        </div>
        <div class="col-12">
          <span class="fill-space">Options</span>
        </div>
        <div class="col-12">
          <div class="al-card with-border numbers-card">
            <div class="flex row">
              <lefty-checkbox
                label="Additional campaigns"
                class="numbers-checkbox"
                [disabled]="!canEditSubscription"
                [(checked)]="hasAdditional.campaigns"
                (checkedChange)="additionalChange($event, 'campaigns')"
              >
              </lefty-checkbox>
            </div>
            <div class="fill-space"></div>
            <div
              class="flex row vCenter numbers-row"
              *ngIf="hasAdditional.campaigns"
            >
              <div class="col-10 fill-space">
                <lefty-form-input
                  type="number"
                  formControlName="campaigns"
                  [disabled]="hasUnlimited.campaigns || !canEditSubscription"
                >
                </lefty-form-input>
              </div>
              <div class="col-2">
                <lefty-checkbox
                  class="vCenter"
                  label="Unlimited"
                  [(checked)]="hasUnlimited.campaigns"
                  [disabled]="!canEditSubscription"
                  (checkedChange)="unlimitedChange($event, 'campaigns')"
                >
                </lefty-checkbox>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12">
          <div class="al-card with-border numbers-card">
            <div class="flex row">
              <lefty-checkbox
                label="Additional running campaigns"
                class="numbers-checkbox"
                [disabled]="!canEditSubscription"
                [(checked)]="hasAdditional.runningCampaigns"
                (checkedChange)="additionalChange($event, 'runningCampaigns')"
              >
              </lefty-checkbox>
            </div>
            <div class="fill-space"></div>
            <div
              class="flex row vCenter numbers-row"
              *ngIf="hasAdditional.runningCampaigns"
            >
              <div class="col-10 fill-space">
                <lefty-form-input
                  type="number"
                  formControlName="runningCampaigns"
                  [disabled]="
                    hasUnlimited.runningCampaigns || !canEditSubscription
                  "
                >
                </lefty-form-input>
              </div>
              <div class="col-2">
                <lefty-checkbox
                  class="vCenter"
                  label="Unlimited"
                  [(checked)]="hasUnlimited.runningCampaigns"
                  [disabled]="!canEditSubscription"
                  (checkedChange)="unlimitedChange($event, 'runningCampaigns')"
                >
                </lefty-checkbox>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12">
          <div class="al-card with-border numbers-card">
            <div class="flex row">
              <lefty-checkbox
                label="Additional influencers in campaign"
                class="numbers-checkbox"
                [(checked)]="hasAdditional.influencersInCampaign"
                [disabled]="!canEditSubscription"
                (checkedChange)="
                  additionalChange($event, 'influencersInCampaign')
                "
              >
              </lefty-checkbox>
            </div>
            <div class="fill-space"></div>
            <div
              class="flex row vCenter numbers-row"
              *ngIf="hasAdditional.influencersInCampaign"
            >
              <div class="col-10 fill-space">
                <lefty-form-input
                  type="number"
                  formControlName="influencersInCampaign"
                  [disabled]="
                    hasUnlimited.influencersInCampaign || !canEditSubscription
                  "
                >
                </lefty-form-input>
              </div>
              <div class="col-2">
                <lefty-checkbox
                  class="vCenter"
                  label="Unlimited"
                  [(checked)]="hasUnlimited.influencersInCampaign"
                  [disabled]="!canEditSubscription"
                  (checkedChange)="
                    unlimitedChange($event, 'influencersInCampaign')
                  "
                >
                </lefty-checkbox>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12">
          <div class="al-card with-border numbers-card">
            <div class="flex row">
              <lefty-checkbox
                label="Additional reports"
                class="numbers-checkbox"
                [(checked)]="hasAdditional.reports"
                [disabled]="!canEditSubscription"
                (checkedChange)="additionalChange($event, 'reports')"
              >
              </lefty-checkbox>
            </div>
            <div class="fill-space"></div>
            <div
              class="flex row vCenter numbers-row"
              *ngIf="hasAdditional.reports"
            >
              <div class="col-10 fill-space">
                <lefty-form-input
                  type="number"
                  formControlName="reports"
                  [disabled]="hasUnlimited.reports || !canEditSubscription"
                >
                </lefty-form-input>
              </div>
              <div class="col-2">
                <lefty-checkbox
                  class="vCenter"
                  label="Unlimited"
                  [(checked)]="hasUnlimited.reports"
                  [disabled]="!canEditSubscription"
                  (checkedChange)="unlimitedChange($event, 'reports')"
                >
                </lefty-checkbox>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12">
          <div class="al-card with-border numbers-card">
            <div class="flex row">
              <lefty-checkbox
                label="Influencers tracked"
                class="numbers-checkbox"
                [(checked)]="hasAdditional.influencersTracked"
                [disabled]="!canEditSubscription"
                (checkedChange)="additionalChange($event, 'influencersTracked')"
              >
              </lefty-checkbox>
            </div>
            <div class="fill-space"></div>

            <div
              class="flex row vCenter numbers-row"
              *ngIf="hasAdditional.influencersTracked"
            >
              <div class="col-10 fill-space">
                <lefty-form-input
                  type="number"
                  formControlName="influencersTracked"
                  [disabled]="
                    hasUnlimited.influencersTracked || !canEditSubscription
                  "
                >
                </lefty-form-input>
              </div>
              <div class="col-2">
                <lefty-checkbox
                  class="vCenter"
                  label="Unlimited"
                  [(checked)]="hasUnlimited.influencersTracked"
                  [disabled]="!canEditSubscription"
                  (checkedChange)="
                    unlimitedChange($event, 'influencersTracked')
                  "
                >
                </lefty-checkbox>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12">
          <div class="al-card with-border numbers-card">
            <div class="flex row">
              <lefty-checkbox
                label="Additional affiliates"
                class="numbers-checkbox"
                [(checked)]="hasAdditional.affiliates"
                [disabled]="!canEditSubscription"
                (checkedChange)="additionalChange($event, 'affiliates')"
              >
              </lefty-checkbox>
            </div>
            <div class="fill-space"></div>

            <div
              class="flex row vCenter numbers-row"
              *ngIf="hasAdditional.affiliates"
            >
              <div class="col-10 fill-space">
                <lefty-form-input
                  type="number"
                  formControlName="affiliates"
                  [disabled]="hasUnlimited.affiliates || !canEditSubscription"
                >
                </lefty-form-input>
              </div>
              <div class="col-2">
                <lefty-checkbox
                  class="vCenter"
                  label="Unlimited"
                  [(checked)]="hasUnlimited.affiliates"
                  [disabled]="!canEditSubscription"
                  (checkedChange)="unlimitedChange($event, 'affiliates')"
                >
                </lefty-checkbox>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
  <div footer>
    <button
      (click)="close()"
      [disabled]="formLoading"
    >
      Cancel
    </button>
    <button
      class="primary"
      [disabled]="formLoading"
      (click)="submit()"
    >
      <lefty-spinner *ngIf="formLoading"></lefty-spinner>
      Done
    </button>
  </div>
</lefty-dialog>
