import {
  Account,
  CustomerSort,
  TeamDetails,
  TeamUser,
  TeamsResponse_TeamLight,
} from '@frontend2/proto/librarian/proto/admin_pb';
import { isNil } from '../../utils/common.helpers';

export function isGhostCustomer(customer: Account | TeamUser): boolean {
  return customer.userAccountId !== BigInt(0) ? false : true;
}

export function isGhostTeamUser(user: TeamUser): boolean {
  return user.userAccountId !== BigInt(0) ? false : true;
}

export function isGhostTeam(
  team: TeamsResponse_TeamLight | TeamDetails | undefined,
): boolean {
  if (isNil(team)) {
    return true;
  }
  return team.id !== BigInt(0) ? false : true;
}
export function customerSortToReadable(customerSort: CustomerSort): string {
  switch (customerSort) {
    case CustomerSort.UNDEFINED:
      return 'Undefined';
    case CustomerSort.DATE:
      return 'Creation date';
    case CustomerSort.EMAIL:
      return 'Email';
    case CustomerSort.COMPANY:
      return 'Company';
    default:
      return 'unknown';
  }
}

export function teamDetailsToTeamLight(
  team: TeamDetails,
): TeamsResponse_TeamLight {
  return new TeamsResponse_TeamLight({
    id: team.id,
    membersCount: team.members.length,
    name: team.name,
    owner: team.owner,
  });
}
