import { ChangeDetectionStrategy, Component, Output } from '@angular/core';
import { isEmptyString } from '@frontend2/core';
import { createOutput } from '../utils';
import { LeftyFileInputBase } from './lefty-file-input.base';
import { LeftyProgressComponent } from '../loading.component';
import { NgIf } from '@angular/common';
import { LeftyFormComponent } from '../lefty-form/lefty-form.component';

@Component({
  selector: 'lefty-media-input',
  templateUrl: 'lefty-media-input.component.html',
  styleUrls: ['lefty-file-input.base.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [LeftyFormComponent, NgIf, LeftyProgressComponent],
})
export class LeftyMediaInputComponent extends LeftyFileInputBase {
  dataSrc?: string;

  @Output()
  readonly dataSrc$ = createOutput<string | undefined>();

  isDataSrc(src: string): boolean {
    return src.startsWith('data:');
  }

  isVideoSrc(src: string): boolean {
    return src.endsWith('.mp4') || src.endsWith('.avi');
  }

  get isVideo(): boolean {
    if (this.value instanceof File) {
      return this.value.type.startsWith('video/');
    }

    if (typeof this.value === 'string') {
      return this.isVideoSrc(this.value);
    }

    return false;
  }

  private _readFile(file: File): void {
    const reader = new FileReader();

    this.setState(() => {
      this.progress = 0;
      this.loading = true;
    });

    reader.onprogress = (event): void => {
      this.setState(() => (this.progress = (100 * event.loaded) / event.total));
    };

    reader.onloadend = (): void => {
      const result = reader.result;

      this.setState(() => {
        this.loading = false;

        if (typeof result === 'string') {
          this.dataSrc = result;
          this.dataSrc$.next(result);
        }
      });
    };

    reader.readAsDataURL(file);
  }

  override clear(): void {
    super.clear();
    this.dataSrc = undefined;
    this.dataSrc$.next(undefined);
  }

  override set file(file: File) {
    super.file = file;
    this._readFile(file);
  }

  override set path(path: string) {
    super.path = path;

    this.setState(() => {
      this.dataSrc = path;
      this.dataSrc$.next(path);
      this.loading = false;
    });
  }

  override get isEmptyState(): boolean {
    return super.isEmptyState || isEmptyString(this.dataSrc);
  }
}
