<lefty-dialog
  [autoDismissable]="true"
  (dismiss$)="close()"
  [(visible)]="visible"
  size="small"
>
  <h3 title>Add source</h3>

  <form [formGroup]="formModel">
    <div class="grid">
      <div class="col-12">
        <network-form-select
          formControlName="network"
          (ngModelChange)="networkChange()"
          [networks]="networks"
          label="Network"
        ></network-form-select>
      </div>
      <div class="col-12">
        <lefty-form-select
          class="source-input"
          formControlName="sourceType"
          label="Source"
          [buttonText]="formattedSourceType"
          [options]="availableSourceType"
          [itemRenderer]="formatSourceType"
        >
        </lefty-form-select>
      </div>
      <div
        *ngIf="isLefty"
        class="col-12"
      >
        <lefty-source-form formControlName="sourceRequest"> </lefty-source-form>
      </div>

      <div
        *ngIf="isFromAuxiliarySource"
        class="col-12"
      >
        <auxiliary-source-form formControlName="sourceRequest">
        </auxiliary-source-form>
      </div>

      <div
        class="col-12"
        *ngIf="isDirectory"
      >
        <directory-source-form formControlName="sourceRequest">
        </directory-source-form>
      </div>

      <div
        class="col-12"
        *ngIf="isDiscover"
      >
        <discover-source-form
          [network]="network"
          formControlName="sourceRequest"
        >
        </discover-source-form>
      </div>

      <div
        class="col-12"
        *ngIf="isManual"
      >
        <manual-source-form
          [network]="network"
          formControlName="sourceRequest"
        >
        </manual-source-form>
      </div>
    </div>
  </form>
  <div footer>
    <div class="results">
      <span
        [class.ghost]="resultsLoading"
        class="body-1"
        >{{ formattedResults }}</span
      >
    </div>

    <button
      type="button"
      (click)="close()"
    >
      {{ Messages.cancel }}
    </button>
    <button
      type="button"
      class="primary"
      (click)="addSource()"
      [disabled]="addLoading || formModel.invalid"
    >
      <lefty-spinner *ngIf="addLoading"></lefty-spinner> {{ Messages.save }}
    </button>
  </div>
</lefty-dialog>
