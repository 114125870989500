import { MassSelectState, SelectAllStrategy } from './mass-select.models';

export function createSelection<T>(
  state?: Partial<MassSelectState<T>>,
): MassSelectState<T> {
  return {
    selectedItems: state?.selectedItems ?? [],
    availableItemsCount: state?.availableItemsCount ?? 0,
    selectAllStrategy: state?.selectAllStrategy ?? SelectAllStrategy.visible,
    allSelected: state?.allSelected ?? false,
  };
}

export function hasAllSelected<T>(state: MassSelectState<T>): boolean {
  if (state.selectAllStrategy === SelectAllStrategy.all) {
    return state.allSelected;
  }

  return (
    state.availableItemsCount !== 0 &&
    state.availableItemsCount === state.selectedItems.length
  );
}

export function hasAllSelectedIndeterminated<T>(
  state: MassSelectState<T>,
): boolean {
  return !hasAllSelected(state) && state.selectedItems.length !== 0;
}

export function isSelectionActive<T>(state: MassSelectState<T>): boolean {
  return (
    state.availableItemsCount !== 0 &&
    (state.selectedItems.length !== 0 || hasAllSelected(state))
  );
}

export function getSelectionCount<T>(state: MassSelectState<T>): number {
  if (
    state.selectAllStrategy === SelectAllStrategy.all &&
    hasAllSelected(state)
  ) {
    return state.availableItemsCount;
  }
  return state.selectedItems.length;
}

export function isSelected<T>(state: MassSelectState<T>, data: T): boolean {
  if (state.selectAllStrategy === SelectAllStrategy.all) {
    return hasAllSelected(state) || state.selectedItems.includes(data);
  }

  return state.selectedItems.includes(data);
}
