import {
  Component,
  ChangeDetectionStrategy,
  Input,
  HostBinding,
  inject,
} from '@angular/core';
import { LeftyPageComponent } from './lefty-page.component';

@Component({
  selector: 'lefty-section-header',
  templateUrl: './lefty-section-header.component.html',
  styleUrls: ['./lefty-section-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class LeftySectionHeaderComponent {
  readonly page = inject(LeftyPageComponent);

  @HostBinding('class.mobile')
  get mobile(): boolean {
    return this.page.mobile;
  }

  @HostBinding('class.large')
  @Input()
  large = false;

  @HostBinding('class.loading')
  get isLoading(): boolean {
    return this.page.loading === true;
  }
}
