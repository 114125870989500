/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { leftyIsAdminGuard } from '@frontend2/api';
import { LEFTY_LOGIN_PATH } from '@frontend2/core';
import { LeftyEventsModule } from '@frontend2/ui';
import { FlandersEventsBus } from './common/events/events';
import {
  CUSTOMER_PATH,
  CW_BRANDS_PATH,
  CW_CREATE_SET_PATH,
  CW_REPORTS_PATH,
  CW_SETS_PATH,
  CW_SETTINGS_PATH,
  TEAMS_PATH,
  TEAM_PATH,
  TOOLS_DEBUG_PATH,
  TOOLS_JOBS_PATH,
  TOOLS_MERGE_PATH,
  TOOLS_MERGE_REQUEST_PATH,
  TOOLS_UPLOAD_STORY_PATH,
} from './constants';
import { flandersLoginGuard } from './login-route/login.guard';
import { FlandersLoginRouteComponent } from './login-route/login.route';

@NgModule({
  imports: [
    LeftyEventsModule.forRoot(FlandersEventsBus),
    RouterModule.forRoot([
      {
        path: '',
        redirectTo: CUSTOMER_PATH,
        pathMatch: 'full',
      },
      {
        path: CUSTOMER_PATH,
        loadChildren: () =>
          import('./customers-route/customers.module').then(
            (m) => m.CustomersModule,
          ),
        canActivate: [leftyIsAdminGuard],
      },
      {
        path: TEAMS_PATH,
        loadChildren: () =>
          import('./workspaces-route/workspaces.module').then(
            (m) => m.WorkspacesModule,
          ),
        data: {
          cacheComponent: true,
        },
        canActivate: [leftyIsAdminGuard],
      },
      {
        path: TEAM_PATH,
        redirectTo: TEAMS_PATH,
        pathMatch: 'prefix',
      },
      {
        path: CW_REPORTS_PATH,
        loadChildren: () =>
          import('./cw-route/reports-route/reports.module').then(
            (m) => m.ReportsModule,
          ),
        data: {
          cacheComponent: true,
        },
        canActivate: [leftyIsAdminGuard],
      },
      {
        path: CW_BRANDS_PATH,
        loadChildren: () =>
          import('./cw-route/brands-route/brands.module').then(
            (m) => m.BrandsModule,
          ),
        data: {
          cacheComponent: true,
        },
        canActivate: [leftyIsAdminGuard],
      },
      {
        path: CW_SETS_PATH,
        loadChildren: () =>
          import('./cw-route/sets-route/sets.module').then((m) => m.SetsModule),
        data: {
          cacheComponent: true,
        },
        canActivate: [leftyIsAdminGuard],
      },
      {
        path: CW_CREATE_SET_PATH,
        loadChildren: () =>
          import(
            './cw-route/sets-route/create-set-route/create-set.module'
          ).then((m) => m.CreateSetModule),
        data: {
          cacheComponent: true,
        },
        canActivate: [leftyIsAdminGuard],
      },
      {
        path: CW_SETTINGS_PATH,
        loadChildren: () =>
          import('./cw-route/settings-route/settings.module').then(
            (m) => m.CwSettingsModule,
          ),
        data: {
          cacheComponent: true,
        },
        canActivate: [leftyIsAdminGuard],
      },
      {
        path: TOOLS_JOBS_PATH,
        loadChildren: () =>
          import('./jobs-route/jobs.module').then((m) => m.JobsModule),
        data: {
          cacheComponent: true,
        },
        canActivate: [leftyIsAdminGuard],
      },
      {
        path: TOOLS_DEBUG_PATH,
        loadChildren: () =>
          import('./debug-route/debug.module').then((m) => m.DebugModule),
        data: {
          cacheComponent: true,
        },
        canActivate: [leftyIsAdminGuard],
      },
      {
        path: TOOLS_UPLOAD_STORY_PATH,
        loadChildren: () =>
          import('./stories-upload-route/stories-upload.module').then(
            (m) => m.StoriesUploadModule,
          ),
        canActivate: [leftyIsAdminGuard],
      },
      {
        path: TOOLS_MERGE_PATH,
        loadChildren: () =>
          import('./merge-profiles-route/merge-profiles.module').then(
            (m) => m.MergeProfilesModule,
          ),
        canActivate: [leftyIsAdminGuard],
      },
      {
        path: TOOLS_MERGE_REQUEST_PATH,
        loadChildren: () =>
          import('./merge-requests-route/merge-requests.module').then(
            (m) => m.MergeRequestsModule,
          ),
        canActivate: [leftyIsAdminGuard],
      },
      {
        component: FlandersLoginRouteComponent,
        path: LEFTY_LOGIN_PATH,
        canActivate: [flandersLoginGuard],
      },
    ]),
  ],
  exports: [RouterModule],
})
export class AppRouterModule {}
