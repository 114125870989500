<lefty-card
  [withBorder]="true"
  [withPadding]="true"
  [withShadowHover]="true"
>
  <div class="infos team fill-space">
    @if (!userHasTeamId(user)) {
      <h4 class="value">The user is not member of a team.</h4>
    } @else {
      <h4 class="value">{{ user.teamId }} - {{ user.teamName }}</h4>
      <p class="label">{{ membersCount }}</p>
    }
  </div>
  @if (userHasTeamId(user)) {
    <div class="infos creation">
      <p class="value">
        {{ createdFormatted }}
      </p>
      <p class="label">Creation</p>
    </div>
    <div class="infos subscription">
      <p class="value">
        {{ teamSubscription }}
      </p>
      <p class="label">Subscription</p>
    </div>
    <div class="infos">
      <p class="value">
        {{ user.owner?.userId }}
      </p>
      <p class="label">Owner Id</p>
    </div>
    <div class="infos owner">
      <span class="value">
        <p
          class="owner-email-text"
          leftyTooltipIfEllipsis
        >
          {{ user.owner?.email }}
        </p>
        <button
          leftyButton="plain"
          [dark]="true"
          size="small"
          icon="content_copy"
          (click)="copyEmail($event)"
        ></button>
      </span>
      <p class="label">Owner</p>
    </div>
    <lefty-menu-icon (click)="stayInPage($event)">
      <lefty-list-item (click)="editSubscription()">
        Edit subscription
      </lefty-list-item>
      <lefty-list-item (click)="editWorkspace()">
        Edit workspace
      </lefty-list-item>
    </lefty-menu-icon>
  }
</lefty-card>
