import { NgIf } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  Input,
} from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  FormsModule,
  NgControl,
  ReactiveFormsModule,
} from '@angular/forms';
import {
  LibrarianSearchClient,
  LibrarianSearchClientProvider,
} from '@frontend2/api';
import { isEmptyString, isNotNil } from '@frontend2/core';
import { Int32Range, Network } from '@frontend2/proto/common/proto/common_pb';
import {
  DiscoverSourceRequest,
  SourceRequest,
} from '@frontend2/proto/librarian/proto/competitive_watch_pb';
import { DiscoverRequest } from '@frontend2/proto/librarian/proto/discover_pb';
import {
  Geo,
  GeoRequest,
  GeoType,
} from '@frontend2/proto/universal/arkansas/proto/arkansas_pb';
import {
  AuxiliaryCategoriesCache,
  AuxiliaryCategory,
  AuxiliaryCountriesCache,
  FilteringFn,
  Language,
  LanguagesCache,
  LeftyChipsEditorComponent,
  LeftyFormNumberInputComponent,
  SearchAndSelectDropdownComponent,
  ToastManager,
  showToastException,
} from '@frontend2/ui';
import { SourceRequestForm } from '../source-forms';

@Component({
  selector: 'discover-source-form',
  templateUrl: 'discover-source-form.component.html',
  styleUrls: ['./../source-forms.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    LeftyChipsEditorComponent,
    NgIf,
    SearchAndSelectDropdownComponent,
    LeftyFormNumberInputComponent,
  ],
})
export class CwDiscoverSourceFormComponent extends SourceRequestForm {
  constructor(
    ngControl: NgControl,
    protected categoriesCache: AuxiliaryCategoriesCache,
    protected countriesCache: AuxiliaryCountriesCache,
    protected languagesCache: LanguagesCache,
    @Inject(LibrarianSearchClientProvider)
    private librarian: LibrarianSearchClient,
    private toastManager: ToastManager,
  ) {
    super(ngControl);
    this._loadData();

    this.formModel.valueChanges.subscribe(() =>
      this.handleValueChange(this._buildSourceRequest()),
    );
  }
  keywordsDelimiters = ',#@';
  mentionsDelimiters = ',# @';
  hashtagsDelimiters = ',# @';

  private _network: Network = Network.INSTA;

  readonly availableCountries = this.countriesCache.cachedData;
  readonly availableCategories = this.categoriesCache.cachedData;
  readonly availableLanguages = this.languagesCache.languagesList;

  override formModel = new FormGroup(
    {
      keywords: new FormControl<string[]>([]),
      mentions: new FormControl<string[]>([]),
      hashtags: new FormControl<string[]>([]),
      minFollowers: new FormControl<number | null>(null),
      language: new FormControl<Language[]>([]),
      countries: new FormControl<Geo[]>([]),
      categories: new FormControl<AuxiliaryCategory[]>([]),
    },
    this._inputsValidator,
  );

  private async _loadData(): Promise<void> {
    await Promise.all([
      this.categoriesCache.load(),
      this.countriesCache.load(),
      this.languagesCache.load(),
    ]);
  }

  private _inputsValidator(
    control: AbstractControl,
  ): { [key: string]: boolean } | null {
    const fieldNames = [
      'countries',
      'categories',
      'keywords',
      'mentions',
      'hashtags',
      'language',
    ];

    const hasNonEmptyValue = (fieldName: string): boolean => {
      const value = control.get(fieldName)?.value;
      return value?.length > 0;
    };

    if (
      fieldNames.some(hasNonEmptyValue) ||
      isNotNil(control.get('minFollowers')?.value)
    ) {
      return null;
    }

    return { required: true };
  }

  readableLanguage(item: Language): string {
    return item.name;
  }

  readableCountry(item: Geo): string {
    return item.title;
  }

  readableCategory(cat: AuxiliaryCategory): string {
    return cat.name;
  }

  get network(): Network {
    return this._network;
  }

  @Input()
  set network(val: Network) {
    if (this._network !== val) {
      this._network = val;
    }
  }

  get isWeibo(): boolean {
    return this.network === Network.WEIBO;
  }

  get isTikTok(): boolean {
    return this.network === Network.TIK_TOK;
  }

  get isYoutube(): boolean {
    return this.network === Network.YOUTUBE;
  }

  _buildSourceRequest(): SourceRequest | undefined {
    if (this.formModel.invalid) {
      return undefined;
    }
    const countries = this.formModel.get('countries')?.value ?? [];
    const categories = this.formModel.get('categories')?.value ?? [];
    const languages = this.formModel.get('language')?.value ?? [];
    const minFollowers = this.formModel.get('minFollowers')?.value;

    const request = new DiscoverRequest({
      network: this.network,
      auxiliaryLocations: countries,
      categories: categories.map((e) => e.id.toString()),
      languages: languages.map((e) => e.code),
      keywords: this.formModel.get('keywords')?.value ?? [],
      mentions: this.formModel.get('mentions')?.value ?? [],
      hashtags: this.formModel.get('hashtags')?.value ?? [],
    });

    if (isNotNil(minFollowers)) {
      request.followers = new Int32Range({
        min: minFollowers,
      });
    }

    request.followers;

    return new SourceRequest({
      request: {
        value: new DiscoverSourceRequest({ discoverRequest: request }),
        case: 'discover',
      },
    });
  }

  searchCountries: FilteringFn<Geo> = async (
    options: Geo[],
    query: string,
  ): Promise<Geo[]> => {
    if (isEmptyString(query)) {
      return [];
    }
    const request = new GeoRequest({
      query: query,
      limit: 15,
      types: [GeoType.COUNTRY],
    });

    try {
      const response = await this.librarian?.searchGeo(request);
      return response?.geos ?? [];
    } catch (e) {
      showToastException(this.toastManager, e);
      return [];
    }
  };
}
