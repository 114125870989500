import { Directive, ElementRef } from '@angular/core';
import { LeftyTooltipDirective } from './lefty-tooltip.directive';

@Directive({
  selector: '[leftyTooltipIfEllipsis]',
  standalone: true,
})
export class TooltipIfEllipsisDirective extends LeftyTooltipDirective {
  constructor(elementRef: ElementRef<HTMLElement>) {
    super(elementRef);
  }

  override onMouseEnter(): void {
    const element = this.elementRef.nativeElement;
    if (element.offsetWidth < element.scrollWidth) {
      this.message = element.innerHTML;
      super.onMouseEnter();
    }
  }
}
