import { Inject, Injectable } from '@angular/core';
import {
  LibrarianAdminClient,
  LibrarianAdminClientProvider,
} from '@frontend2/api';
import {
  ProtoUtils,
  RouteParams,
  buildDefaultRouteState,
  isGhostCustomer,
} from '@frontend2/core';
import { BasicPagination } from '@frontend2/proto/common/proto/common_pb';
import {
  Account,
  CustomerSort,
  GetCustomersRequest,
} from '@frontend2/proto/librarian/proto/admin_pb';
import { RouteWithPaginationBloc } from '@frontend2/ui';
import { CustomersListState } from './customers.models';

@Injectable()
export class CustomersListBloc extends RouteWithPaginationBloc<
  GetCustomersRequest,
  CustomersListState
> {
  constructor(
    @Inject(LibrarianAdminClientProvider)
    private librarian: LibrarianAdminClient,
  ) {
    super({
      initialState: buildDefaultRouteState(new GetCustomersRequest(), {
        totalHits: 0,
        customers: [],
      }),
    });
  }

  static readonly EMAIL_KEY = 'email';
  static readonly CHARGEBEE_ONLY_KEY = 'chargebee_only';
  static readonly QUERY_KEY = 'q';

  override getTotalHits(
    request: GetCustomersRequest,
    viewSate: CustomersListState,
  ): number {
    return viewSate.totalHits;
  }

  override get paginationSize(): number {
    return 10;
  }

  static requestToRouteParams(
    request: GetCustomersRequest,
    routeParams: RouteParams,
  ): RouteParams {
    const req = request.clone();
    if (req.sort === CustomerSort.DATE) {
      req.sort = CustomerSort.UNDEFINED;
    }
    return ProtoUtils.protoToRouteParams(
      req,
      CustomersListBloc.QUERY_KEY,
      routeParams,
    );
  }

  protected parseRouteParams(
    params: RouteParams,
  ): GetCustomersRequest | Promise<GetCustomersRequest> {
    const req = ProtoUtils.protoFromRouteParams(
      GetCustomersRequest.fromBinary,
      params,
      CustomersListBloc.QUERY_KEY,
    );
    if (req.sort === CustomerSort.UNDEFINED) {
      req.sort = CustomerSort.DATE;
    }
    return req;
  }

  protected override beforeBuild(req: GetCustomersRequest): void {
    super.beforeBuild(req);
    this.setViewState({
      ...this.viewState(),
      customers: [
        ...this.viewState().customers,
        ...Array(this.paginationSize).fill(new Account()),
      ],
    });
  }

  protected async build(req: GetCustomersRequest): Promise<CustomersListState> {
    if (!req.pagination) {
      req = this.applyPagination(req, 0);
    }
    if (req.sort === CustomerSort.UNDEFINED) {
      req.sort = CustomerSort.DATE;
    }
    const response = await this.librarian.getUserAccountsAPI(req);
    const customers = response.userAccounts;
    return {
      totalHits: response.totalHits,
      customers,
    };
  }

  applyPagination(
    request: GetCustomersRequest,
    from: number,
  ): GetCustomersRequest {
    const pagination = request.pagination?.clone() ?? new BasicPagination();
    pagination.from = from;
    pagination.size = this.paginationSize;
    request = request.clone();
    request.pagination = pagination;
    return request;
  }

  appendNewPage(
    currentState: CustomersListState,
    newPage: CustomersListState,
  ): CustomersListState {
    return {
      ...newPage,
      customers: [
        ...currentState.customers.filter((i) => isGhostCustomer(i) === false),
        ...newPage.customers,
      ],
    };
  }
}
