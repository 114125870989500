<lefty-form
  [label]="label"
  [required]="required"
  [optional]="optional"
  [helpText]="helpText"
  [externalHelpLink]="externalHelpLink"
  [helpLink]="helpLink"
  [hintText]="hintText"
  [tooltip]="tooltip"
  [large]="large"
  [topHintText]="topHintText"
>
  <input
    type="file"
    #input
    [attr.accept]="accept"
    style="display: none"
    (change)="onFilesAdded()"
  />

  <div
    class="container media"
    [class.empty]="isEmptyState"
  >
    <div
      class="drop-container"
      *ngIf="isEmptyState"
      [class.loadingBlob]="loading"
      [class.ondragover]="draggingOver"
      (dragover)="onDragOver()"
      (dragleave)="onDragLeave()"
      (drop)="onDrop($event)"
    >
      <ng-container *ngIf="loading === false">
        <button
          (click)="addFile()"
          class="add-file-button"
          type="button"
          i18n
        >
          Add file
        </button>
        <span
          class="drop-img-helper"
          i18n
        >
          or drop image to upload
        </span>

        <div
          class="file-format-helper"
          *ngIf="showFileFormatHelper"
        >
          {{ fileFormatHelper }}
        </div>

        <span
          class="error-message"
          *ngIf="showErrorMessage"
        >
          {{ errorMessage }}
        </span>
      </ng-container>

      <lefty-progress
        *ngIf="loading === true"
        [activeProgress]="progress"
      >
      </lefty-progress>
    </div>
    <div
      class="file-card"
      *ngIf="!isEmptyState"
    >
      <div class="media-container">
        <img
          class="media"
          *ngIf="isVideo === false"
          [attr.src]="dataSrc"
        />

        <video
          class="media"
          *ngIf="isVideo === true"
          [attr.src]="dataSrc"
        ></video>
      </div>

      <button
        type="button"
        class="clear primary plain"
        (click)="clear()"
        i18n
      >
        Remove
      </button>
    </div>
  </div>
</lefty-form>
