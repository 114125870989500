import {
  ChangeDetectionStrategy,
  Component,
  Input,
  Optional,
  Output,
  Self,
} from '@angular/core';
import { ConnectError } from '@connectrpc/connect';
import { injectLibrarianUsersClient } from '@frontend2/api';
import { isNotEmptyString } from '@frontend2/core';
import { Network } from '@frontend2/proto/common/proto/common_pb';
import { CreatorTiniestCard } from '@frontend2/proto/librarian/proto/creators_pb';
import { CreatorAutocompleteRequest } from '@frontend2/proto/librarian/proto/users_pb';
import { debounceTime } from 'rxjs';
import { createOutput } from '../utils';

import { NgControl } from '@angular/forms';
import { ComponentFactory } from '../dynamic-component.component';
import { LeftyFormValueBase } from '../form';
import { InfluencerAutocompleteItemComponent } from './influencer-autocomplete-item/influencer-autocomplete-item.component';
import { LeftyFormAutocompleteComponent } from '../lefty-form-autocomplete/lefty-form-autocomplete.component';

@Component({
  selector: 'lefty-basic-influencer-autocomplete',
  template: `
    <lefty-form-autocomplete
      leadingGlyph="search"
      [options]="options"
      [value]="selection"
      [loading]="loading"
      name="inf-autocomplete"
      [itemRenderer]="itemRenderer"
      (inputTextChange)="handleSearchValueChange($event)"
      [popupMatchInputWidth]="true"
      [errorMessage]="errorMessage"
      [placeholder]="placeholder"
      [inputClassName]="inputClassName"
      (selectionChange)="influencerSelected($event)"
      [componentFactory]="componentFactory"
      [label]="label"
    >
    </lefty-form-autocomplete>
  `,
  styles: `
    :host {
      display: block;
    }
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [LeftyFormAutocompleteComponent],
})
export class BasicInfluencerAutocompleteComponent extends LeftyFormValueBase<
  CreatorTiniestCard | undefined
> {
  private librarianUser = injectLibrarianUsersClient();

  constructor(@Self() @Optional() ngControl?: NgControl) {
    super(undefined, ngControl);
    this.searchValueChange
      .pipe(debounceTime(300))
      .subscribe(() => this.searchInfluencer());
  }

  @Input()
  searchValue = '';

  @Input()
  network = Network.INSTA;

  @Input()
  optionsSize = 10;

  @Input()
  allowNotFoundInfluencer = false;

  @Input()
  inputClassName = '';

  @Input()
  placeholder = 'Select influencer';

  @Input()
  set selection(val: CreatorTiniestCard | undefined) {
    this.value = val;
  }

  get selection(): CreatorTiniestCard | undefined {
    return this.value;
  }

  @Input()
  loading = false;

  options: CreatorTiniestCard[] = [];

  @Output()
  readonly searchValueChange = createOutput<string>();

  handleSearchValueChange(newValue: string): void {
    this.errorMessage = '';
    if (this.searchValue === newValue) {
      return;
    }

    this.searchValue = newValue;
    this.searchValueChange.next(newValue);
  }

  async searchInfluencer(): Promise<void> {
    try {
      this.setState(() => (this.loading = true));
      if (isNotEmptyString(this.searchValue)) {
        const req = new CreatorAutocompleteRequest({
          namePrefix: this.searchValue,
          count: this.optionsSize,
          network: this.network,
        });
        const response =
          await this.librarianUser.autocompleteCreatorsV2API(req);
        this.options = this._processOptions(response.creatorMatches);
        this.changeDetection.markForCheck();
      }
    } catch (e: unknown) {
      if (e instanceof ConnectError) {
        this.errorMessage = e.message;
      }
    }
  }

  private _processOptions(
    response: CreatorTiniestCard[],
  ): CreatorTiniestCard[] {
    if (this.allowNotFoundInfluencer && isNotEmptyString(this.searchValue)) {
      const exist = response.some((u: CreatorTiniestCard) => {
        u.userName.toLowerCase() === this.searchValue.toLowerCase();
      });

      if (exist === false) {
        return [
          new CreatorTiniestCard({ userName: this.searchValue }),
          ...response,
        ];
      }
    }
    return response;
  }

  influencerSelected(val: CreatorTiniestCard | undefined): void {
    this.handleValueChange(val);
  }

  itemRenderer = (item: CreatorTiniestCard): string => item.userName;

  componentFactory: ComponentFactory<CreatorTiniestCard> = () =>
    InfluencerAutocompleteItemComponent;

  override writeValue(obj: unknown): void {
    if (obj instanceof CreatorTiniestCard) {
      this.value = obj;
    } else {
      this.value = new CreatorTiniestCard();
      this.searchValue = '';
    }
  }
}
