<a
  target="_blank"
  href="https://lefty.io"
  rel="noopener"
  class="logo-container"
>
  <img
    height="58"
    [src]="leftyLogoSrc"
  />
</a>

<h4 class="welcome-label">{{ Messages.welcome }}.</h4>

<h4 class="page-title">Admin</h4>

<form
  #form="ngForm"
  (ngSubmit)="submit(form)"
  class="login-card"
>
  <lefty-feedback
    type="error"
    *ngIf="showError"
    [hideIcon]="true"
  >
    {{errorMessage}}
  </lefty-feedback>
  <lefty-form-input
    i18n-label
    label="Email"
    placeholder="name@company.com"
    autoFocus
    ngModel
    type="email"
    name="email"
    [email]="true"
    autocomplete="email"
    [required]="true"
  >
  </lefty-form-input>

  <lefty-form-input
    autocomplete="password"
    i18n-label
    label="Password"
    i18n-placeholder
    placeholder="password"
    i18n-helpText
    ngModel
    minlength="8"
    name="password"
    type="password"
    [required]="true"
    [tooltip]="false"
  >
  </lefty-form-input>
  <button
    class="action-btn primary large"
    [disabled]="loading"
  >
    <lefty-spinner *ngIf="loading"></lefty-spinner>
    {{ Messages.signIn }}
  </button>
</form>

<footer>
  <span class="copyright"> © {{year}} </span>
  <a
    class="black"
    target="_blank"
    rel="noopener"
    href="https://lefty.io"
  >
    About
  </a>
  <a
    class="black"
    target="_blank"
    rel="noopener"
    [href]="legalUrl"
  >
    Legal
  </a>
  <a
    class="black"
    target="_blank"
    rel="noopener"
    [href]="privacyPolicyUrl"
  >
    Privacy
  </a>
</footer>
