<form
  class="grid"
  [formGroup]="formModel"
>
  <div class="col-12">
    <lefty-chips-editor
      label="Mentions"
      formControlName="mentions"
      [delimiters]="mentionsDelimiters"
      prefix="@"
    >
    </lefty-chips-editor>
  </div>
  <div class="col-12">
    <lefty-chips-editor
      label="Hashtags"
      formControlName="hashtags"
      [delimiters]="hashtagsDelimiters"
      prefix="#"
    >
    </lefty-chips-editor>
  </div>
  <div
    class="col-12"
    *ngIf="!isTikTok"
  >
    <lefty-chips-editor
      label="Keywords"
      formControlName="keywords"
      [delimiters]="keywordsDelimiters"
    >
    </lefty-chips-editor>
  </div>

  <div
    class="col-12"
    *ngIf="!isWeibo"
  >
    <search-and-select-dropdown
      #country
      multi
      class="countries-input"
      formControlName="countries"
      [showHeader]="false"
      [filteringFn]="searchCountries"
      label="countries"
      placeholder="Any country"
      [itemRenderer]="readableCountry"
    ></search-and-select-dropdown>
  </div>
  <div
    class="col-12"
    *ngIf="!isWeibo"
  >
    <search-and-select-dropdown
      #language
      class="language-input"
      formControlName="language"
      [showHeader]="false"
      [loading]="languagesCache.isLoading()"
      [options]="availableLanguages()"
      label="language"
      placeholder="Any language"
      [itemRenderer]="readableLanguage"
    ></search-and-select-dropdown>
  </div>
  <div
    class="col-12"
    *ngIf="isWeibo"
  >
    <search-and-select-dropdown
      #category
      multi
      class="categories-input"
      formControlName="categories"
      [loading]="categoriesCache.isLoading()"
      [options]="availableCategories()"
      label="categories"
      placeholder="Any category"
      [itemRenderer]="readableCategory"
    ></search-and-select-dropdown>
  </div>
  <div class="col-12">
    <lefty-form-number-input
      label="Min. followers"
      class="minfollowers-input"
      formControlName="minFollowers"
    >
    </lefty-form-number-input>
  </div>
</form>
