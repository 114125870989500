<div
  class="left-section"
  *ngIf="leftSectionVisible"
  [class.compact]="isCompactLayout()"
>
  <a
    [leftyRouterLink]="homeLink"
    class="logo"
    leftyRouterLinkActive="active"
    [leftyRouterLinkActiveOptions]="{ exact: !hasHomeFeature }"
  >
    <lefty-brand-icon></lefty-brand-icon>
  </a>
  <div class="left-section-header">
    <ng-content select="[left-section-header]"></ng-content>
  </div>
  <div class="nav-section">
    <ng-content select="[nav]"></ng-content>
  </div>
  <div class="left-section-footer">
    <ng-content select="[left-section-footer]"></ng-content>
  </div>
</div>
<div
  class="lefty-content"
  [class.compact]="isCompactLayout()"
  [class.left-section-visible]="leftSectionVisible"
>
  <ng-content></ng-content>
</div>
