import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { LeftyAuthBloc } from '@frontend2/api';
import { isNotEmptyString } from '@frontend2/core';
import { LoggedBootstrapping } from '@frontend2/proto/librarian/proto/frontend_misc_pb';
import {
  LeftyIconComponent,
  LeftyListComponent,
  LeftyListItemComponent,
  LeftyPopupComponent,
} from '@frontend2/ui';

@Component({
  selector: 'account-menu',
  templateUrl: 'account-menu.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['account-menu.component.scss'],
  standalone: true,
  imports: [
    LeftyIconComponent,
    LeftyPopupComponent,
    LeftyListComponent,
    LeftyListItemComponent,
  ],
})
export class AccountMenuComponent {
  constructor(private _auth: LeftyAuthBloc) {}

  @Input()
  state = new LoggedBootstrapping();

  get username(): string {
    return this.state.firstName + ' ' + this.state.lastName;
  }

  get initials(): string {
    return [this.state.firstName, this.state.lastName]
      .filter((s) => isNotEmptyString(s))
      .map((s) => s[0].toUpperCase())
      .join('');
  }

  logout(): void {
    this._auth.logout();
  }
}
