<div
  class="menu-item submenu"
  [class.isOpen]="isOpen"
>
  <div
    class="toggle-menu"
    (click)="toggle()"
  >
    {{ label }}
    <lefty-icon
      icon="arrow_drop_down"
      [class.active]="isOpen"
      size="medium"
    ></lefty-icon>
  </div>

  <div
    *ngIf="isOpen"
    class="menu-group"
  >
    @for (nav of navigations; track $index) {
      @if (nav.isHidden !== true) {
        <a
          class="menu-item"
          [leftyRouterLink]="nav.link"
          leftyRouterLinkActive="active"
          [class.active]="nav.isActive"
        >
          {{ nav.label }}
        </a>
      }
    }
  </div>
</div>
