import { NgIf } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  Output,
} from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import {
  LibrarianCWAdminClient,
  LibrarianCWAdminClientProvider,
} from '@frontend2/api';
import { LeftyFormat, Networks, isNil } from '@frontend2/core';
import { Network } from '@frontend2/proto/common/proto/common_pb';
import { NetworkSource } from '@frontend2/proto/librarian/proto/admin_cw_pb';
import { SourceRequest } from '@frontend2/proto/librarian/proto/competitive_watch_pb';
import {
  DialogBase,
  LeftyDialogComponent,
  LeftyFormSelectComponent,
  LeftySpinnerComponent,
  LeftyValidators,
  NetworkFormSelectComponent,
  ToastManager,
  showToastException,
} from '@frontend2/ui';
import { Subject, debounceTime, map } from 'rxjs';
import { readableSetSourceType } from '../../../cw-route/sets-route/create-set-route/create-set.helpers';
import { SetSourceType } from '../../../cw-route/sets-route/create-set-route/create-set.models';
import { CwAuxiliarySourceFormComponent } from './auxiliary-form/auxiliary-source-form.component';
import { CwDirectorySourceFormComponent } from './directory-form/directory-source-form.component';
import { CwDiscoverSourceFormComponent } from './discover-form/discover-source-form.component';
import { CwLeftySourceFormComponent } from './lefty-form/lefty-source-form.component';
import { CwManualSourceFormComponent } from './manual-form/manual-source-form.component';

@Component({
  selector: 'add-source-dialog',
  templateUrl: 'add-source-dialog.component.html',
  styleUrls: ['add-source-dialog.component.scss'],
  providers: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    LeftyDialogComponent,
    FormsModule,
    ReactiveFormsModule,
    NetworkFormSelectComponent,
    LeftyFormSelectComponent,
    NgIf,
    CwLeftySourceFormComponent,
    CwAuxiliarySourceFormComponent,
    CwDirectorySourceFormComponent,
    CwDiscoverSourceFormComponent,
    CwManualSourceFormComponent,
    LeftySpinnerComponent,
  ],
})
export class AddSourceDialogComponent extends DialogBase {
  constructor(
    private toastManager: ToastManager,
    @Inject(LibrarianCWAdminClientProvider)
    private librarian: LibrarianCWAdminClient,
  ) {
    super();
    this.disposer.add(this.success$);
    this.formModel.valueChanges
      .pipe(
        debounceTime(500),
        map(() => this.formModel.get('sourceRequest')?.value),
      )
      .subscribe((request) => this._requestSourceCount(request));
  }

  @Output()
  readonly success$ = new Subject<NetworkSource>();

  resultsLoading = false;

  addLoading = false;

  errorMessage = '';

  results = 0;

  get formattedResults(): string {
    return `${LeftyFormat.profiles(this.results, { showZero: true })} results`;
  }

  networks: Network[] = Networks.supportedCW;

  get availableSourceType(): SetSourceType[] {
    return [
      SetSourceType.discover,
      SetSourceType.directory,
      SetSourceType.manual,
    ];
  }

  formModel = new FormGroup({
    network: new FormControl<Network>(
      Network.INSTA,
      LeftyValidators.requiredNetwork,
    ),
    sourceType: new FormControl<SetSourceType | null>(
      null,
      Validators.required,
    ),
    sourceRequest: new FormControl<SourceRequest | null>(
      null,
      this.requiredSource,
    ),
  });

  get network(): Network {
    return this.formModel.get('network')?.value ?? Network.INSTA;
  }

  get sourceRequest(): SourceRequest {
    return this.formModel.get('sourceRequest')?.value ?? new SourceRequest();
  }

  get sourceType(): SetSourceType {
    return this.formModel.get('sourceType')?.value ?? SetSourceType.unkown;
  }

  get isLefty(): boolean {
    return this.sourceType === SetSourceType.lefty;
  }

  get isDiscover(): boolean {
    return this.sourceType === SetSourceType.discover;
  }

  get isFromAuxiliarySource(): boolean {
    return this.sourceType === SetSourceType.arkansas;
  }

  get isDirectory(): boolean {
    return this.sourceType === SetSourceType.directory;
  }

  get isManual(): boolean {
    return this.sourceType === SetSourceType.manual;
  }

  override open(): void {
    this.formModel.reset({
      network: Network.INSTA,
      sourceType: SetSourceType.discover,
      sourceRequest: undefined,
    });
    this.results = 0;
    super.open();
  }

  formatSourceType(type: SetSourceType): string {
    return readableSetSourceType(type);
  }

  get formattedSourceType(): string {
    return this.formatSourceType(this.sourceType);
  }

  async addSource(): Promise<void> {
    if (this.formModel.invalid) {
      this.toastManager.showError('Form not valid');
      return;
    }

    const source = new NetworkSource({
      network: this.network,
      request: this.sourceRequest,
      count: BigInt(this.results),
    });

    this.success$.next(source);
    this.close();
  }

  private async _requestSourceCount(
    request?: SourceRequest | null,
  ): Promise<void> {
    if (!request || isNil(request.request.case)) {
      return;
    }

    this.setState(() => (this.resultsLoading = true));

    try {
      const resp = await this.librarian.cWCountSourceV2({
        input: { value: request, case: 'request' },
      });
      this.results = Number(resp.value);
      this.changeDetection.markForCheck();
    } catch (e) {
      showToastException(this.toastManager, e);
    } finally {
      this.setState(() => (this.resultsLoading = false));
    }
  }

  requiredSource(control: AbstractControl): ValidationErrors | null {
    const source: SourceRequest = control.value;
    return isNil(source) || isNil(source.request) || isNil(source.request.case)
      ? { required: true }
      : null;
  }

  networkChange(): void {
    if (!this.availableSourceType.includes(this.sourceType)) {
      this.formModel.controls['sourceType'].setValue(
        this.availableSourceType[0],
      );
    }
    this.changeDetection.markForCheck();
  }
}
