@for (customer of customers; track customer.userAccountId) {
  <a [routerLink]="customerLink(customer)">
    <lefty-card
      [withBorder]="true"
      [withPadding]="true"
      [withShadowHover]="true"
    >
      <customer-item [customer]="customer">
        <lefty-list-item
          (click)="editCustomer(customer)"
          action
        >
          Edit customer
        </lefty-list-item>
      </customer-item>
    </lefty-card>
  </a>
}
