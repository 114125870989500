import {
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
  inject,
} from '@angular/core';
import { NgControl } from '@angular/forms';
import { formatBytes, isNil, isNotEmptyString } from '@frontend2/core';
import { fromEvent, merge } from 'rxjs';
import { LeftyFormValueBase } from '../form';

@Component({
  template: '',
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export abstract class LeftyFileInputBase
  extends LeftyFormValueBase<File | string | undefined>
  implements OnInit
{
  constructor() {
    super(undefined, inject(NgControl, { optional: true }));
  }

  loading = false;
  progress = 0;
  filename = '';

  size?: number;

  get formattedSize(): string {
    if (isNil(this.size)) {
      return '';
    }
    return formatBytes(this.size);
  }

  get isEmptyState(): boolean {
    return isNil(this.value) || this.loading || this.showErrorMessage;
  }

  @Input()
  sizeLimit?: number;

  get hasReachSizeLimit(): boolean {
    if (isNil(this.sizeLimit) || isNil(this.size)) {
      return false;
    }
    return this.size > this.sizeLimit;
  }

  /// Filter file format we accept in the input
  @Input()
  accept = '';

  @ViewChild('input')
  inputElementRef?: ElementRef<HTMLInputElement>;

  set file(file: File) {
    this.setState(() => {
      this.size = file.size;
      this.filename = file.name;
    });
  }

  set path(path: string) {
    this.setState(() => {
      this.size = undefined;
      this.filename = path;
    });
  }

  override get value(): File | string | undefined {
    return super.value;
  }

  override set value(val: File | string | undefined) {
    if (this.value === val) {
      return;
    }

    if (isNil(val)) {
      this.setState(() => {
        this.size = undefined;
      });
    } else if (val instanceof File) {
      this.file = val;
    } else if (typeof val === 'string') {
      this.path = val;
    }

    super.value = val;
  }

  addFile(): void {
    this.inputElementRef?.nativeElement?.click();
  }

  clear(): void {
    if (this.inputElementRef?.nativeElement) {
      this.inputElementRef.nativeElement.value = '';
    }

    this.handleValueChange(undefined);
  }

  @Input()
  fileFormatHelper = '';

  get showFileFormatHelper(): boolean {
    return isNotEmptyString(this.fileFormatHelper);
  }

  get fileSizeError(): string {
    return $localize`Sorry, this file exceeds our size limit of (${formatBytes(
      this.sizeLimit ?? 0,
    )})`;
  }

  override get showErrorMessage(): boolean {
    return super.showErrorMessage || this.hasReachSizeLimit;
  }

  override get errorMessage(): string {
    if (this.hasReachSizeLimit) {
      return this.fileSizeError;
    }
    return super.errorMessage;
  }

  onFilesAdded(): void {
    const input = this.inputElementRef?.nativeElement;
    const files = input?.files;
    if (isNil(input) || isNil(files)) {
      return;
    }

    // can be empty on some browser when click on cancel button
    // of the upload dialog
    if (files.length !== 0) {
      this.handleValueChange(files[0]);
    }
  }

  draggingOver = false;

  onDrop(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();

    this.setState(() => (this.draggingOver = false));

    const files = event.dataTransfer?.files ?? [];

    if (files.length !== 0) {
      const fileNameParts = files[0].name.split('.');
      const extension = fileNameParts[fileNameParts.length - 1].toLowerCase();
      if (this.accept.toLowerCase().indexOf(extension) > 0) {
        this.handleValueChange(files[0]);
      }
    }
  }

  onDragOver(): void {
    this.setState(() => (this.draggingOver = true));
  }

  onDragLeave(): void {
    this.setState(() => (this.draggingOver = false));
  }

  ngOnInit(): void {
    this.disposer.add(
      merge(fromEvent(window, 'drop'), fromEvent(window, 'dragover')).subscribe(
        (event) => event.preventDefault(),
      ),
    );
  }

  override writeValue(obj: unknown): void {
    if (typeof obj === 'string' || obj instanceof File) {
      this.value = obj;
    } else {
      this.value = undefined;
    }
  }
}
