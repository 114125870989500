export class ArgumentError implements Error {
  readonly name: string;
  readonly message: string;
  readonly stack?: string;

  constructor(message: string, stack?: string) {
    this.name = 'ArgumentError';
    this.message = message;
    this.stack = stack;
  }
}
