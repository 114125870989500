import { ChangeDetectionStrategy, Component, computed } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  LeftyFormat,
  Networks,
  getEnumValues,
  plural,
  subscriptionToReadable,
} from '@frontend2/core';
import { Network } from '@frontend2/proto/common/proto/common_pb';
import { TeamDetails } from '@frontend2/proto/librarian/proto/admin_pb';
import { SubscriptionType } from '@frontend2/proto/librarian/proto/payments_pb';
import {
  IntersectionObserverDirective,
  LeftyFormInputComponent,
  LeftyPageComponent,
  LeftySectionComponent,
  LeftySectionHeaderComponent,
  LeftySortSelectComponent,
  NetworksSelectorDropdownComponent,
  RouteBlocComponent,
  SearchAndSelectDropdownComponent,
  createSubject,
} from '@frontend2/ui';
import { debounceTime } from 'rxjs';
import { CreateOrEditWorkspaceDialogComponent } from '../common/components/create-or-edit-workspace-dialog/create-or-edit-workspace-dialog.component';
import { WorkspacesListComponent } from './workspaces-list/workspaces-list.component';
import { WorkspacesListBloc } from './workspaces.bloc';
import {
  WorkspacesListRequest,
  WorkspacesSort,
  WorskpacesListState,
} from './workspaces.models';

@Component({
  selector: 'flander-Workspaces-route',
  templateUrl: 'workspaces.route.html',
  styleUrls: ['workspaces.route.scss'],
  providers: [WorkspacesListBloc],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    LeftyPageComponent,
    LeftySectionComponent,
    LeftySectionHeaderComponent,
    LeftyFormInputComponent,
    LeftySortSelectComponent,
    SearchAndSelectDropdownComponent,
    NetworksSelectorDropdownComponent,
    WorkspacesListComponent,
    IntersectionObserverDirective,
    CreateOrEditWorkspaceDialogComponent,
  ],
})
export class FlandersWorkspacesRouteComponent extends RouteBlocComponent<
  WorkspacesListRequest,
  WorskpacesListState
> {
  constructor(routeViewBloc: WorkspacesListBloc) {
    super(routeViewBloc);

    this._searchByName$
      .pipe(debounceTime(300), takeUntilDestroyed())
      .subscribe((val) => this._searchByName(val));
  }

  readonly subscriptionOptions: SubscriptionType[] =
    getEnumValues<SubscriptionType>(SubscriptionType).splice(1);

  readonly networkOptions: Network[] = Networks.supportedAdmin;

  private readonly _searchByName$ = createSubject<string>();

  readonly nameFilterValue = computed(() => this.request().name);
  searchByName(val: string): void {
    this._searchByName$.next(val);
  }

  private _searchByName(val: string): void {
    val = val.trim();
    if (val === this.nameFilterValue()) {
      return;
    }

    this.changeRequest({
      ...this.request(),
      name: val,
    });
  }

  readonly sortByValue = computed(() => this.request().sortBy);
  sortChange(val: WorkspacesSort): void {
    if (val === this.sortByValue()) {
      return;
    }

    this.changeRequest({
      ...this.request(),
      sortBy: val,
    });
  }

  readonly networkFilterValue = computed(() => this.request().network);
  networkChange(val: Network[]): void {
    if (val === this.networkFilterValue()) {
      return;
    }

    this.changeRequest({
      ...this.request(),
      network: val,
    });
  }

  readonly subscriptionFilterValue = computed(
    () => this.request().subscription,
  );
  subscriptionChange(val: SubscriptionType[]): void {
    if (val === this.subscriptionFilterValue()) {
      return;
    }
    this.changeRequest({
      ...this.request(),
      subscription: val,
    });
  }

  private changeRequest(request: WorkspacesListRequest): void {
    this.router.navigate(['.'], {
      relativeTo: this.route,
      queryParams:
        WorkspacesListBloc.requestToRouteParams(request).queryParameters,
    });
  }

  sortRenderer(sortOption: WorkspacesSort): string {
    switch (sortOption) {
      case WorkspacesSort.BY_DATE:
        return 'Creation date';
      case WorkspacesSort.BY_EMAIL:
        return 'Email';
      case WorkspacesSort.BY_MEMBERS:
        return 'Team members';
    }
  }

  subscriptionRenderer(sub: SubscriptionType): string {
    return subscriptionToReadable(sub);
  }

  async refreshView(team: TeamDetails | void): Promise<void> {
    if (team instanceof TeamDetails) {
      await this.bloc.updateTeamInView(team);
    }
  }

  get bloc(): WorkspacesListBloc {
    return this.routeViewBloc as WorkspacesListBloc;
  }

  readonly sortOptions = getEnumValues<WorkspacesSort>(WorkspacesSort);

  readonly teams = computed(() => this.viewState().teams);

  readonly totalHits = computed(() => this.viewState().totalHits);

  readonly totalHitsTitle = computed(() => {
    const count = this.totalHits();
    return (
      LeftyFormat.profiles(count, { showZero: true }) +
      ' ' +
      plural(count, {
        one: 'Workspace',
        other: 'Workspaces',
      })
    );
  });

  nextPage(): void {
    this.bloc.nextPage();
  }
}
