<div class="form-input">
  <div
    class="form-label"
    *ngIf="hasLabel"
  >
    {{ label }}
  </div>

  <button
    [ngClass]="buttonClass"
    #popupTrigger
    class="selector-btn"
    focusableElement
    [class.active]="popupVisible"
  >
    <div class="button-content">
      <span
        class="placeholder"
        [class.empty]="selection.length === 0"
      >
        <span
          class="count"
          *ngIf="selection.length !== 0"
          >({{ selection.length }})</span
        >
        {{ placeholder }}
      </span>
    </div>
    <lefty-icon icon="arrow_drop_down"></lefty-icon>
  </button>

  <lefty-popup
    [placement]="popupPlacement"
    [popupTrigger]="popupTrigger"
    [matchMinSourceWidth]="true"
    [(visible)]="popupVisible"
  >
    <div class="flex column">
      <div class="header">
        <ng-container *ngIf="canSelectAll">
          <span
            class="selector"
            (click)="selectAll()"
            [class.active]="!allSelected"
          >
            {{ Messages.selectAll }}
          </span>
          -
        </ng-container>
        <span
          class="selector"
          (click)="clearAll()"
          [class.active]="selection.length !== 0"
        >
          {{ Messages.clearAll }}
        </span>
      </div>
      <lefty-list class="networks-list">
        <lefty-list-item
          class="network"
          [closeOnActivate]="false"
          *ngFor="let network of options"
          (click)="toggleItem(network)"
          [ngClass]="readableNetwork(network).toLowerCase()"
        >
          <lefty-checkbox [checked]="isSelected(network)"></lefty-checkbox>
          <network-icon [network]="network"></network-icon>
          <div class="name">{{ readableNetwork(network) }}</div>
        </lefty-list-item>
      </lefty-list>
    </div>
  </lefty-popup>
</div>
