import { Injectable } from '@angular/core';
import { CacheBloc } from '../bloc';
export interface AuxiliaryCategory {
  readonly id: number;
  readonly name: string;
  readonly count: number;
  readonly deprecated: boolean;
}

export function createAuxiliaryCategory(
  partial: Partial<AuxiliaryCategory>,
): AuxiliaryCategory {
  const cat: AuxiliaryCategory = {
    id: partial.id ?? 0,
    name: partial.name ?? '',
    count: partial.count ?? 0,
    deprecated: partial.deprecated ?? false,
  };
  return cat;
}

@Injectable({ providedIn: 'root' })
export class AuxiliaryCategoriesCache extends CacheBloc<AuxiliaryCategory[]> {
  constructor() {
    super([]);
  }

  async fetch(): Promise<AuxiliaryCategory[]> {
    const assets: AuxiliaryCategory[] = (
      await import('packages/lefty-assets/src/lib/db/auxiliary-categories.json')
    ).default;
    return assets;
  }

  getName(id: number): string | null {
    if (this.isLoaded()) {
      const category = this.cachedData().find((c) => c.id === id);
      return category ? category.name : null;
    }
    return null;
  }

  getCategory(id: number): AuxiliaryCategory | null {
    if (this.isLoaded()) {
      return this.cachedData().find((c) => c.id === id) || null;
    }
    return null;
  }
}
