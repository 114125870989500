import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
  input,
  model,
} from '@angular/core';

@Component({
  selector: 'lefty-tabs',
  template: `
    @for (tab of tabs(); track $index) {
      <div
        (click)="switchTo($index)"
        class="tab-button"
        [class.active]="activeTabIndex() === $index"
      >
        {{ tab.label }}
      </div>
    }
  `,
  styleUrls: ['lefty-tabs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class LeftyTabsComponent {
  @Input()
  @HostBinding('class.align-left')
  alignLeft = false;

  readonly activeTabIndex = model(0);

  readonly tabs = input<TabItem[]>([]);

  switchTo(index: number): void {
    this.activeTabIndex.set(index);
  }
}

export interface TabItem {
  readonly label: string;
  readonly id: string;
}
