import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { isNotNil } from '@frontend2/core';
import {
  isGhostCreator,
  isGhostSnippet,
} from '@frontend2/proto-helpers/librarian/creator-pb.helpers';
import {
  CreatorCardSnippet,
  CreatorTiniestCard,
} from '@frontend2/proto/librarian/proto/creators_pb';
import { RendersValue } from '../../dynamic-component.component';
import { LeftyComponent } from '../../utils';
import { ProfilePictureComponent } from '../../image/profile-pic.component';
import { NgIf } from '@angular/common';

@Component({
  selector: 'influencer-autocomplete-list-item',
  templateUrl: 'influencer-autocomplete-item.component.html',
  styleUrls: ['influencer-autocomplete-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, ProfilePictureComponent],
})
export class InfluencerAutocompleteItemComponent
  extends LeftyComponent
  implements RendersValue<CreatorTiniestCard>
{
  setValue(
    newValue?: CreatorTiniestCard | CreatorCardSnippet | undefined,
  ): void {
    if (isNotNil(newValue)) {
      this.creator = newValue;
    }
  }
  @Input()
  creator: CreatorTiniestCard | CreatorCardSnippet = new CreatorTiniestCard();

  isGhostCreator(creator: CreatorTiniestCard | CreatorCardSnippet): boolean {
    if (creator instanceof CreatorTiniestCard) {
      return isGhostCreator(creator);
    } else {
      return isGhostSnippet(creator);
    }
  }
}
