import { Injectable, Optional } from '@angular/core';
import { GenerikMediaSnippet } from '@frontend2/proto/librarian/proto/users_pb';
import { BlocState } from '../bloc';
import { createSelection, hasAllSelected } from './mass-select.helpers';
import { MassSelectState, SelectAllStrategy } from './mass-select.models';

@Injectable()
export class MassSelectBloc<T> extends BlocState<MassSelectState<T>> {
  constructor(@Optional() selectAllStrategy?: SelectAllStrategy) {
    super(
      createSelection<T>({
        selectAllStrategy,
      }),
    );
  }

  toggle(item: T): MassSelectState<T> {
    let all = hasAllSelected(this.currentState);

    const elements: T[] = [...this.currentState.selectedItems];
    const index = elements.indexOf(item);
    if (index !== -1) {
      elements.splice(index, 1);
      all = false;
    } else {
      elements.push(item);
      if (elements.length === this.currentState.availableItemsCount) {
        all = true;
      }
    }

    return this.updateState({
      ...this.currentState,
      selectedItems: elements,
      allSelected: all,
    });
  }

  // add visibleElements to the list of selected elements
  // and mark the state as `allSelected`
  selectAll(visibleElements: T[]): MassSelectState<T> {
    return this.updateState({
      ...this.currentState,
      selectedItems: visibleElements,
      allSelected: true,
    });
  }

  cancel(): MassSelectState<T> {
    return this.updateState({
      ...this.currentState,
      selectedItems: [],
      allSelected: false,
    });
  }

  // Set the total number of items we can select
  // this is mandatory when using SelectAllStrategy.all
  setAvailableItemsCount(count: number): MassSelectState<T> {
    return this.updateState({
      ...this.currentState,
      availableItemsCount: count,
    });
  }

  // if some or every items are selected
  // it will unselect all items
  //
  // Or if nothing selected, it will call `sellecAll` using visible items
  toggleAllSelection(visibleElements: T[]): MassSelectState<T> {
    if (
      hasAllSelected(this.currentState) ||
      this.currentState.selectedItems.length !== 0
    ) {
      return this.cancel();
    } else {
      return this.selectAll(visibleElements);
    }
  }
}

@Injectable()
export class MediaMassSelect extends MassSelectBloc<GenerikMediaSnippet> {}
