<lefty-dialog
  [(visible)]="visible"
  (close)="no()"
  [autoDismissable]="true"
  (dismiss$)="no()"
  size="small"
>
  <h3 title>{{ title }}</h3>

  <p class="dialog-text">
    <ng-content></ng-content>
  </p>
  <ng-container footer>
    <button
      leftyButton="secondary"
      (click)="no()"
      [disabled]="loading || disabled"
    >
      {{ noLabel }}
    </button>
    <button
      [leftyButton]="yesButtonType"
      [disabled]="loading || disabled"
      [loading]="loading"
      (click)="yes()"
    >
      {{ yesLabel }}
    </button>
  </ng-container>
</lefty-dialog>
