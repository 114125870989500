import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  HostListener,
  Input,
  Output,
  viewChild,
} from '@angular/core';
import { Placement } from '@popperjs/core';
import { Observable, Subject } from 'rxjs';
import { LeftyListComponent } from '../lefty-list/lefty-list.component';
import { LeftyComponent } from '../utils';
import { LeftyPopupComponent } from '../lefty-popup/lefty-popup.component';
import { LeftyIconComponent } from '../icon/icon.component';
import { NgClass } from '@angular/common';

@Component({
  selector: 'lefty-menu-icon',
  templateUrl: 'lefty-menu-icon.component.html',
  styleUrls: ['lefty-menu-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgClass,
    LeftyIconComponent,
    LeftyPopupComponent,
    LeftyListComponent,
  ],
})
export class LeftyMenuIconComponent extends LeftyComponent {
  constructor() {
    super();

    this.disposer.addSubject(this._visibleChange$);
  }

  readonly listEl = viewChild(LeftyListComponent);

  @Input()
  popupPlacement: Placement = 'bottom-start';

  private readonly _visibleChange$ = new Subject<boolean>();

  @Output()
  get visibleChange(): Observable<boolean> {
    return this._visibleChange$;
  }

  private _visible = false;

  get visible(): boolean {
    return this._visible;
  }

  @Input()
  set visible(val: boolean) {
    if (this._visible === val) {
      return;
    }

    this._visible = val;
    this._visibleChange$.next(val);
  }

  @Input()
  icon = 'more_vert';

  private _buttonClass = 'ghost';

  @Input()
  set buttonClass(val: string) {
    this._buttonClass = val;
  }

  get buttonClass(): string {
    return `icon ${this._buttonClass}`;
  }

  /// close menu
  close(): void {
    this.visible = false;
  }

  /// show popup
  open(): void {
    this.visible = true;
  }

  @HostBinding('class.display-none')
  get hiddenMenu(): boolean {
    return this.listEl()?.hasChildren !== true;
  }

  _hiddenMenu = false;

  @HostListener('click', ['$event'])
  handleClick(event: Event): void {
    event.preventDefault();
    event.stopPropagation();
  }
}
