<div class="container">
  <span
    class="label"
    (click)="dropdownSelectComponent.open()"
  >
    {{ Messages.sortBy }}
    &nbsp;:
  </span>
  <lefty-form-select
    [selection]="selection"
    (selectionChange)="handleSelectionChange($event)"
    [options]="options"
    [popupMatchInputWidth]="false"
    [popupPlacement]="popupPlacement"
    [itemRenderer]="itemRenderer"
    buttonClass="outline no-border"
  >
  </lefty-form-select>
</div>
