export const CUSTOMER_ID_KEY = 'customer_id';
export const TEAM_ID_KEY = 'team_id';
export const REPORT_ID_KEY = 'report_id';
export const SET_ID_KEY = 'set_id';
export const RANGE_START_KEY = 'range.start';
export const RANGE_END_KEY = 'range.end';

export const CUSTOMER_PATH = 'customers';
export const TEAMS_PATH = 'teams';
export const TEAM_PATH = 'team';
export const MEMBERS = 'members';

export const CW_REPORTS_PATH = 'cw/reports';
export const CW_BRANDS_PATH = 'cw/brands';
export const CW_SETS_PATH = 'cw/sets';
export const CW_CREATE_SET_PATH = 'cw/create_set';
export const CW_SETTINGS_PATH = 'cw/settings';

export const TOOLS_DEBUG_PATH = 'debug';
export const TOOLS_JOBS_PATH = 'jobs';
export const TOOLS_JOBS_OVERALL_PATH = 'overall';
export const TOOLS_JOBS_INFLUENCER_PATH = 'influencer';
export const TOOLS_UPLOAD_STORY_PATH = 'upload_story';
export const TOOLS_MERGE_PATH = 'merge';
export const TOOLS_MERGE_REQUEST_PATH = 'merge_requests';
