import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Messages, isNil } from '@frontend2/core';
import { LeftyComponent } from '../utils';
import { LeftyFiltersGridComponent } from './lefty-filters-grid.component';
import { NgIf } from '@angular/common';
import { LeftyIconComponent } from '../icon/icon.component';

@Component({
  selector: 'lefty-filters-button',
  templateUrl: 'lefty-filters-button.component.html',
  standalone: true,
  imports: [LeftyIconComponent, NgIf],
})
export class LeftyFiltersButtonComponent
  extends LeftyComponent
  implements OnChanges
{
  @Input()
  filtersGrid?: LeftyFiltersGridComponent;

  @Input()
  label = Messages.filters;

  @Input()
  filtersCount = 0;

  get filtersVisible(): boolean {
    return this.filtersGrid?.filtersVisible === true;
  }

  toggleFilters(): void {
    // toggle grid visibility
    this.filtersGrid?.toggleFilters();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['filtersGrid'] || changes['filtersCount']) {
      if (isNil(this.filtersGrid)) {
        return;
      }

      // propagate count to filters grid
      this.filtersGrid.filtersCount = this.filtersCount;
    }
  }
}
