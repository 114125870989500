import {
  ChangeDetectionStrategy,
  Component,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { LeftyComponent } from '../utils';
import { LeftyFormSelectComponent } from './lefty-form-select.component';
import { Placement } from '@popperjs/core';
import { ItemRenderer, defaultItemRenderer } from './utils';
import { Subject } from 'rxjs';

@Component({
  selector: 'lefty-sort-select',
  templateUrl: 'lefty-sort-select.component.html',
  styleUrls: ['lefty-sort-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [LeftyFormSelectComponent],
})
export class LeftySortSelectComponent<T> extends LeftyComponent {
  constructor() {
    super();

    this.disposer.add(this.selectionChange);
  }

  @ViewChild(LeftyFormSelectComponent)
  dropdownSelectComponent!: LeftyFormSelectComponent<T>;

  @Input()
  selection: unknown;

  // see `LeftyFormSelectComponent.selectionChange` for why we use `ANY`
  @Output()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  readonly selectionChange = new Subject<any>();

  @Input()
  options: T[] = [];

  @Input()
  popupPlacement: Placement = 'bottom-start';

  /// Function to convert an option object to string.
  @Input()
  itemRenderer?: ItemRenderer<T> = defaultItemRenderer;

  handleSelectionChange(value: unknown): void {
    if (value === this.selection) {
      return;
    }

    this.selectionChange.next(value);
  }
}
