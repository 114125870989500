/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Attribute,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Host,
  HostBinding,
  HostListener,
  Input,
  Optional,
  Output,
  Self,
} from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { isNotNil } from '@frontend2/core';
import { Subject } from 'rxjs';
import { Focusable } from '../focus.directive';
import { HasDisabled, LeftyComponent } from '../utils';
import { LeftyRadioGroupService } from './lefty-radio-group.service';

@Component({
  selector: 'lefty-radio',
  providers: [
    {
      provide: HasDisabled,
      useExisting: LeftyRadioComponent,
    },
  ],
  templateUrl: 'lefty-radio.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class LeftyRadioComponent<T>
  extends LeftyComponent
  implements ControlValueAccessor, HasDisabled, Focusable
{
  @HostBinding('class')
  readonly hostClass = 'lefty-radio';

  @HostBinding('attr.role')
  readonly role: string;

  // eslint-disable-next-line @typescript-eslint/ban-types
  private onTouched?: Function;

  constructor(
    private _root: ElementRef<HTMLElement>,
    @Self() @Optional() private cd?: NgControl,
    @Attribute('role') role?: string,
    @Host() @Optional() private _group?: LeftyRadioGroupService<T>,
  ) {
    super();

    this.role = role ?? 'radio';

    // When NgControl is present on the host element, the component
    // participates in the Forms API.
    if (cd) {
      cd.valueAccessor = this;
    }

    this.disposer.add(this.checkedChange);
  }

  private _checked = false;
  private _focused = false;

  @Input()
  @HostBinding('class.checked')
  @HostBinding('attr.aria-checked')
  set checked(val: boolean) {
    if (this._checked === val) {
      return;
    }

    this.setState(() => (this._checked = val));

    if (isNotNil(this._group)) {
      if (this.checked) {
        this._group.select(this);
      } else {
        this._group.deselect(this);
      }
    }

    this.checkedChange.next(val);
    this.changeDetection.markForCheck();
  }

  get checked(): boolean {
    return this._checked;
  }

  /// Fired when checkbox is checked or unchecked, but not when set
  /// indeterminate. Sends the state of [checked].
  @Output()
  readonly checkedChange = new Subject<boolean>();

  private _disabled = false;

  @Input()
  @HostBinding('class.disabled')
  @HostBinding('attr.aria-disabled')
  set disabled(val: boolean) {
    this._disabled = val;
  }

  get disabled(): boolean {
    return this._disabled;
  }

  /// Value this radio represents, used in selection model with radio-group.
  @Input()
  value?: T;

  focus(): void {
    this._root.nativeElement.focus();
  }

  writeValue(obj: unknown): void {
    if (typeof obj === 'boolean') {
      this.checked = obj;
    } else {
      this.checked = false;
    }
  }

  registerOnChange(fn: any): void {
    this.checkedChange.subscribe(fn);
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  get icon(): string {
    return this.checked ? 'radio_button_checked' : 'radio_button_unchecked';
  }

  /// Whether focus should be drawn.
  get showFocus(): boolean {
    return this._focused;
  }

  // Triggered on focus.
  @HostListener('focus')
  handleFocus(): void {
    this._focused = true;
  }

  // Triggered on blur.
  @HostListener('blur')
  handleBlur(): void {
    this._focused = false;
    if (this.onTouched) {
      this.onTouched();
    }
  }

  select(): void {
    if (!this.disabled) {
      this.checked = true;
    }
  }

  @HostListener('click')
  handleClick(): void {
    this.select();
  }
}
