import { Injectable } from '@angular/core';
import { CacheBloc } from '../bloc';

export interface AuxiliaryCountry {
  readonly id: number;
  readonly name: string;
}

export function createAuxiliaryCountry(
  partial: Partial<AuxiliaryCountry>,
): AuxiliaryCountry {
  const country: AuxiliaryCountry = {
    id: partial.id ?? 0,
    name: partial.name ?? '',
  };
  return country;
}

@Injectable({ providedIn: 'root' })
export class AuxiliaryCountriesCache extends CacheBloc<AuxiliaryCountry[]> {
  constructor() {
    super([]);
  }

  static getCountryById(
    values: AuxiliaryCountry[],
    id: string,
  ): AuxiliaryCountry | null {
    return values.find((val) => val.id === parseInt(id)) || null;
  }

  async fetch(): Promise<AuxiliaryCountry[]> {
    const assets: AuxiliaryCountry[] = (
      await import('packages/lefty-assets/src/lib/db/auxiliary-countries.json')
    ).default;
    return assets;
  }

  getName(id: number): string | null {
    if (this.isLoaded()) {
      const category = this.cachedData().find((c) => c.id === id);
      return category ? category.name : null;
    }
    return null;
  }
}
