import { ContentChildren, Directive, OnDestroy } from '@angular/core';
import { Disposer, isNotNil } from '@frontend2/core';
import { LeftyExpansionPanelComponent } from './lefty-expansion-panel.component';

@Directive({
  selector: 'lefty-accordion',
  standalone: true,
})
export class LeftyAccordionDirective implements OnDestroy {
  private _panelDisposer = new Disposer();
  private _openPanel?: LeftyExpansionPanelComponent;
  private _panels: LeftyExpansionPanelComponent[] = [];

  @ContentChildren(LeftyExpansionPanelComponent, { descendants: false })
  set panels(panels: LeftyExpansionPanelComponent[]) {
    this._panels = panels;
    this._onPanelsChange();
  }

  private _onPanelsChange(): void {
    this._panelDisposer.dispose();
    this._openPanel = undefined;

    for (const panel of this._panels) {
      if (panel.expanded) {
        if (isNotNil(this._openPanel)) {
          throw new Error('Should only have one panel open at a time');
        }
        this._openPanel = panel;
      }

      this._panelDisposer.add(
        panel.expanded$.subscribe((isExpanded) => {
          if (isExpanded) {
            this._setOpenPanel(panel);
          }
        }),
      );
    }
  }

  private _setOpenPanel(panel: LeftyExpansionPanelComponent): void {
    if (this._openPanel === panel) {
      return;
    }
    this._openPanel = panel;
    this._openPanel.expanded = true;

    for (const panel of this._panels) {
      if (panel !== this._openPanel) {
        panel.expanded = false;
      }
    }
  }

  ngOnDestroy(): void {
    this._panelDisposer.dispose();
  }
}
