import { Injectable, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { JsonObject } from '@bufbuild/protobuf';
import {
  injectLibrarianCampaignsClient,
  injectLibrarianEntityLabelsClient,
} from '@frontend2/api';
import { isNil } from '@frontend2/core';
import {
  BaseEntityType,
  BaseEntityTypeReq,
} from '@frontend2/proto/librarian/proto/common_pb';
import {
  EntityLabel,
  EntityLabels,
} from '@frontend2/proto/librarian/proto/entity_labels_pb';
import { filter } from 'rxjs';
import { IframeSyncedCacheBloc } from '../bloc';
import { injectLeftyEventsBus } from '../events/events.service';
import { createGhostLabel, isFakeLabel, isValidLabel } from './labels.helpers';

export abstract class LabelsCache extends IframeSyncedCacheBloc<
  Map<bigint, EntityLabel>
> {
  constructor(readonly type: BaseEntityType) {
    super(new Map());

    this.leftyEvents
      .on('create_label')
      .pipe(
        takeUntilDestroyed(),
        filter((label) => label.entityType === this.type),
      )
      .subscribe((label) => this._handleLabelCreation(label));

    this.leftyEvents
      .on('edit_label')
      .pipe(
        takeUntilDestroyed(),
        filter((label) => label.entityType === this.type),
      )
      .subscribe((label) => this._handleLabelEdit(label));
  }

  readonly leftyEvents = injectLeftyEventsBus();
  readonly labelsService = injectLibrarianEntityLabelsClient();

  abstract override get syncName(): string;

  convertToJson(obj: Map<bigint, EntityLabel>): string {
    const jsonMap: { [key: string]: EntityLabel } = {};

    // manually convert big int to string, before convert to json
    obj.forEach((val, key) => {
      jsonMap[key.toString()] = val;
    });

    return JSON.stringify(jsonMap);
  }

  convertFromJson(jsonString: string): Map<bigint, EntityLabel> {
    const jsonMap = JSON.parse(jsonString) as JsonObject;

    const labelsMap = new Map<bigint, EntityLabel>();
    for (const jsonVal of Object.values(jsonMap)) {
      const label = EntityLabel.fromJson(jsonVal);

      const id = label.id;
      if (isNil(id)) {
        continue;
      }

      labelsMap.set(id, label);
    }

    return labelsMap;
  }

  private async _fetchLabels(): Promise<EntityLabels> {
    const response = await this.labelsService.getEntityLabelsAPI(
      new BaseEntityTypeReq({
        entityType: this.type,
      }),
    );

    return response;
  }

  override async fetch(): Promise<Map<bigint, EntityLabel>> {
    const response = await this._fetchLabels();

    const labels = response.entityLabels.filter(
      (label) => isValidLabel(label) && isFakeLabel(label) === false,
    );

    const labelsMap = new Map<bigint, EntityLabel>();
    for (const label of labels) {
      const id = label.id;
      if (isNil(id)) {
        continue;
      }

      labelsMap.set(id, label);
    }

    return labelsMap;
  }

  private _addOrUpdateLabelInCache(label: EntityLabel): void {
    const newMap = new Map(this.cachedData());

    const id = label.id;
    if (isNil(id)) {
      return;
    }

    newMap.set(id, label);

    this.updateCache(newMap);
  }

  protected _handleLabelCreation(label: EntityLabel): void {
    this._addOrUpdateLabelInCache(label);
  }

  private _handleLabelEdit(label: EntityLabel): void {
    this._addOrUpdateLabelInCache(label);
  }

  getLabelById(id: bigint): EntityLabel {
    return this.cachedData().get(id) ?? createGhostLabel();
  }
}

@Injectable({ providedIn: 'root' })
export class CampaignLabelsCache extends LabelsCache {
  readonly campaignsClient = injectLibrarianCampaignsClient();

  constructor() {
    super(BaseEntityType.CAMPAIGN);
  }

  readonly syncName = 'campaign_labels';
}

export function injectCampaignLabelsCache(): CampaignLabelsCache {
  return inject(CampaignLabelsCache);
}

@Injectable({ providedIn: 'root' })
export class InfluencerLabelsCache extends LabelsCache {
  constructor() {
    super(BaseEntityType.CREATOR);
  }

  readonly syncName = 'influencer_labels';
}

export function injectInfluencerLabelsCache(): InfluencerLabelsCache {
  return inject(InfluencerLabelsCache);
}

@Injectable({ providedIn: 'root' })
export class PostLabelsCache extends LabelsCache {
  constructor() {
    super(BaseEntityType.VISUAL);
  }

  readonly syncName = 'post_labels';
}

export function injectPostLabelsCache(): PostLabelsCache {
  return inject(PostLabelsCache);
}
