<form
  class="grid"
  [formGroup]="formModel"
>
  <div class="col-12">
    <lefty-chips-editor
      delimiters=" ,;|"
      label="Usernames"
      placelholder="username"
      formControlName="usernames"
      prefix="@"
    >
    </lefty-chips-editor>
  </div>
</form>
