<div
  class="sort-item"
  [class.disabled]="disabled"
  [class.active]="active"
>
  <help-icon
    *ngIf="hasHelp"
    [leftyTooltip]="help"
  >
  </help-icon>
  @if (labelFactory) {
    <ng-template #dynamicContent></ng-template>
  } @else {
    <div class="label">{{ label }}</div>
  }

  <ng-container *ngIf="!disabled">
    <lefty-icon
      icon="sort_inactive"
      class="arrow"
      [class.hidden]="active"
    ></lefty-icon>

    <lefty-icon
      icon="sort_active"
      class="arrow active"
      [class.rotate]="!ascDir"
      [class.hidden]="!active"
    ></lefty-icon>
  </ng-container>
</div>
