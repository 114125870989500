@if (!useraccountIsGhost(customer)) {
  <div class="customer-item animated fadeIn flex vCenter">
    <div class="flex column fill-space name-col">
      <h4 class="name">{{ email }}</h4>
      <p class="secondary-text body-1">{{ name }}</p>
    </div>
    @if (!hideDetails) {
      <div class="infos created">
        <span class="value">
          {{ created }}
        </span>
        <span class="label">Created</span>
      </div>
      <div class="infos company">
        <span
          class="value"
          title="{{ company }}"
        >
          {{ company }}
        </span>
        <span class="label">Company</span>
      </div>

      <div class="infos monitoring">
        <span class="value">
          {{ dashboardAccess ? 'Yes' : 'No' }}
        </span>
        <span class="label">Monitoring Dashboard</span>
      </div>
      <div class="infos sso">
        <span class="value">
          {{ ssoProviderName }}
        </span>
        <span class="label">SSO</span>
      </div>
    }

    <lefty-menu-icon>
      <ng-content select="[action]"></ng-content>
    </lefty-menu-icon>
  </div>
} @else {
  <div class="customer-item ghost animated fadeIn">
    <div class="flex column fill-space">
      <h4 class="name"></h4>
      <div class="secondary-text body-1"></div>
    </div>
  </div>
}
