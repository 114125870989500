import {
  UploadInfluencersResult,
  UploadInfluencersResult_UploadInfluencerResult,
} from '@frontend2/proto/librarian/proto/upload_influencer_pb';

export function getSuccessResultFromInfluencerUpload(
  result: UploadInfluencersResult,
): UploadInfluencersResult_UploadInfluencerResult[] {
  return result.results.filter((element) => element.statusCode === 0);
}

export function getFailedResultFromInfluencerUpload(
  result: UploadInfluencersResult,
): UploadInfluencersResult_UploadInfluencerResult[] {
  return result.results.filter((element) => element.statusCode !== 0);
}
