import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import {
  LibrarianCWAdminClient,
  LibrarianCWAdminClientProvider,
} from '@frontend2/api';
import {
  CWEditSetRequest,
  CWGetSetResponse,
} from '@frontend2/proto/librarian/proto/admin_cw_pb';
import {
  DialogBase,
  LeftyDialogComponent,
  LeftyFormInputComponent,
  LeftyFormTextareaComponent,
  LeftySpinnerComponent,
  ToastManager,
} from '@frontend2/ui';
import { injectFlandersEventsBus } from '../../events/events';

@Component({
  selector: 'edit-set-dialog',
  templateUrl: 'edit-set-dialog.component.html',
  styleUrls: ['edit-set-dialog.component.scss'],
  providers: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    LeftyDialogComponent,
    FormsModule,
    ReactiveFormsModule,
    LeftyFormInputComponent,
    LeftyFormTextareaComponent,
    NgIf,
    LeftySpinnerComponent,
  ],
})
export class EditSetDialogComponent extends DialogBase {
  constructor(
    private toastManager: ToastManager,
    @Inject(LibrarianCWAdminClientProvider)
    private librarian: LibrarianCWAdminClient,
  ) {
    super();
  }

  readonly flandersEvents = injectFlandersEventsBus();

  loading = false;

  set = new CWGetSetResponse();

  formModel: FormGroup = new FormGroup({
    name: new FormControl<string>('', Validators.required),
    description: new FormControl<string>('', Validators.required),
  });

  get title(): string {
    return `Edit "${this.set.name}" brand`;
  }

  openWith(set: CWGetSetResponse): void {
    this.set = set;
    this.formModel.reset({
      name: set.name,
      description: set.description,
    });
    this.open();
  }

  async submit(): Promise<void> {
    if (this.formModel.invalid) {
      return;
    }
    this.setState(() => (this.loading = true));
    const newName = this.formModel.get('name')?.value.trim();
    const newDesc = this.formModel.get('description')?.value.trim();
    try {
      const request = new CWEditSetRequest({
        setId: this.set.setId,
        name: newName,
        description: newDesc,
      });
      const newSet = await this.librarian.cWEditSet(request);
      this.toastManager.showSuccess('Set edited with success.');
      this.flandersEvents.emit('cw_set_updated', newSet);
      this.close();
    } catch (e: unknown) {
      this.toastManager.showError('Set could not be edited.');
    } finally {
      this.setState(() => (this.loading = false));
    }
  }
}
