<lefty-dialog
  [autoDismissable]="true"
  (dismiss$)="close()"
  size="small"
  [(visible)]="visible"
>
  <h3 title>
    {{ title }}
  </h3>
  <form
    (ngSubmit)="submit()"
    class="grid"
    #teamForm="ngForm"
  >
    <div class="col-12">
      <lefty-form-input
        name="name"
        [required]="true"
        label="Workspace name"
        [(ngModel)]="workspaceName"
      >
      </lefty-form-input>
    </div>
    <div class="col-12">
      <lefty-form-autocomplete
        (inputTextChange)="searchValueChange($event)"
        [options]="ownerOptions"
        [inputText]="searchValue"
        [(ngModel)]="ownerToAdd"
        name="owner"
        label="Owner email"
        [noFiltering]="true"
        [itemRenderer]="customerRenderer"
      ></lefty-form-autocomplete>
    </div>
    <div class="col-12">
      <lefty-form-select
        ngControl="calculator"
        class="half-width"
        name="calculator"
        label="EMV calculator"
        [buttonText]="formattedMethodCalculator"
        [(ngModel)]="mediaValCalculator"
        [options]="emvCalculators"
        [itemRenderer]="calculatorRenderer"
      >
      </lefty-form-select>
    </div>
    <div class="col-12">
      <lefty-checkbox
        name="canary"
        label="Canary access"
        [(ngModel)]="isCanary"
      >
      </lefty-checkbox>
    </div>
    <div
      class="col-12"
      *ngIf="!edit"
    >
      <div class="form-label">Member email</div>
      <div
        *ngFor="let member of membersToAdd; trackBy: trackBy; let i = index"
        class="flex row vCenter member-item"
      >
        <lefty-form-autocomplete
          class="fill-space"
          (inputTextChange)="memberChanged($event)"
          (selectionChange)="selectMember(i, $event)"
          [options]="membersOptions"
          [itemRenderer]="customerRenderer"
          [noFiltering]="false"
          [selection]="membersToAdd[i]"
          [inputText]="customerRenderer(membersToAdd[i])"
        ></lefty-form-autocomplete>

        <lefty-icon
          icon="delete"
          size="medium"
          (click)="closeField(i)"
        ></lefty-icon>
      </div>
      <div
        class="button outline"
        (click)="addField()"
      >
        Add member
      </div>
    </div>
  </form>
  <div footer>
    <div class="flex row">
      <div class="fill-space"></div>
      <div class="flex row buttons-row action">
        <button
          class="left-icon"
          *ngIf="edit"
          (click)="close()"
        >
          Cancel
        </button>
        <button
          type="button"
          class="primary"
          (click)="submit()"
          [disabled]="creationLoading"
        >
          <lefty-spinner *ngIf="creationLoading"></lefty-spinner>save
        </button>
      </div>
    </div>
  </div>
</lefty-dialog>
