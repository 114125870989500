<lefty-page [loading]="routeLoading()">
  <div class="header-section">
    <lefty-section>
      @if (routeLoading()) {
        <lefty-spinner></lefty-spinner>
      }

      <lefty-section-header>
        <h1>{{ customer().email }}</h1>
        <span class="secondaryText">{{ secondaryText() }}</span>
        <button
          class="left-icon"
          (click)="impersonate()"
          action
          [disabled]="!canImpersonate()"
          leftyTooltip="You don't have the permission to impersonate."
          [showTooltipIf]="!canImpersonate()"
        >
          <lefty-icon icon="open"></lefty-icon>&nbsp;Impersonate
        </button>
        <button
          class="secondary"
          (click)="createOrEditUserDialog.openWith(customer())"
          action
        >
          Edit user
        </button>
      </lefty-section-header>
      <lefty-section-header>
        <div class="flex">
          <lefty-card
            [withPadding]="true"
            [isCompact]="true"
            class="infos-row"
          >
            <span class="field">
              <span class="value"> {{ created() }} </span>
              <span class="label">Created</span>
            </span>

            <span class="field">
              <span class="value"> {{ company() }} </span>
              <span class="label">Company</span>
            </span>

            <span class="field">
              <span class="value">
                {{ dashboardAccess() ? 'Yes' : 'No' }}
              </span>
              <span class="label">Monitoring Dashboard</span>
            </span>

            <span class="field">
              <span class="value"> {{ ssoProviderName() }} </span>
              <span class="label">SSO</span>
            </span>
          </lefty-card>
        </div>
      </lefty-section-header>
    </lefty-section>
  </div>
  <lefty-section>
    @if (!routeLoading()) {
      <workspaces-list
        [user]="customer()"
        (refreshNeeded$)="refreshView()"
        (workspaceToCreateOrEdit$)="
          createOrEditWorkspaceDialog.openWith($event)
        "
        (subscriptionToEdit$)="editSubscriptionDialog.openWith($event)"
      >
      </workspaces-list>
    }
  </lefty-section>
</lefty-page>

<create-or-edit-user-dialog
  #createOrEditUserDialog
  (success$)="refreshView()"
></create-or-edit-user-dialog>

<create-or-edit-workspace-dialog
  #createOrEditWorkspaceDialog
  (success$)="refreshView()"
></create-or-edit-workspace-dialog>

<edit-subscription-dialog
  #editSubscriptionDialog
  (success$)="refreshView()"
></edit-subscription-dialog>
