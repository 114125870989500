<div class="menu-group">
  @for (nav of navigations; track $index) {
    @if (nav.isHidden !== true) {
      <a
        *ngIf="nav.children.length === 0"
        class="menu-item"
        [leftyRouterLink]="nav.link"
        leftyRouterLinkActive="active"
        [class.active]="nav.isActive"
      >
        {{ nav.label }}
      </a>

      <navigation-submenu-group
        *ngIf="nav.children.length !== 0"
        [label]="nav.label"
        [navigations]="nav.children"
      >
      </navigation-submenu-group>
    }
  }
</div>
